import React from "react";

import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const MultiSelect = ({ options, setFieldValue }) => {
  const handleChange = (value) => {
    setFieldValue(value);
  };

  return (
    <Select
      closeMenuOnSelect={false}
      components={animatedComponents}
      isMulti
      options={options}
      onChange={handleChange}
      placeholder='Seleccione uno o mas valores'
    />
  );
};

export default MultiSelect;
