import { clienteAxiosForm } from "src/config/axios";

/**
 *
 * @param {object} product
 *
 * @returns {import("src/utils/types").ResponseCreateEstablishment}
 */
export const createEstablishment = async (product) => {
  try {
    const response = await clienteAxiosForm.post(
      "/v1/carbon-footprint",
      product
    );

    return response?.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};
