import { STORAGE_TAX_ID_KEY } from "src/commons/constants";
import { getSessionStorage } from "src/utils/index";
import { SAVE_TOKEN, DEL_TOKEN } from "../types";

const initialState = {
  token: getSessionStorage("token"),
  loading: false,
  error: false,
  taxId: getSessionStorage(STORAGE_TAX_ID_KEY),
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case "START_LOGIN":
      return {
        ...state,
        loading: action.payload,
      };
    case SAVE_TOKEN:
      return {
        ...state,
        token: action.payload.token,
      };
    case DEL_TOKEN:
      return {
        ...state,
        token: null,
      };
    case "LOGIN_SUCESSFULL":
      return {
        ...state,
        loading: false,
        token: action.payload.token,
        taxId: action.payload.taxId,
      };
    case "LOGIN_FAILURE":
      return {
        ...state,
        ...action.payload,
      };
    case "START_LOGOUT":
      return {
        ...state,
        loading: false,
        token: null,
        taxId: null,
      };

    default:
      return {
        ...state,
        loading: false,
        error: false,
      };
  }
}
