import {
    SHOW_ALERT,
    HIDDEN_ALERT
} from '../types';


export function showAlert(alerta) {
    return (dispatch) => {
        dispatch( crearAlerta(alerta) )
    }
}

const crearAlerta = alerta => ({
    type: SHOW_ALERT,
    payload: alerta
})

export function hiddenAlert() {
    return (dispatch) => {
        dispatch( ocultarAlerta() )
    }
}

const ocultarAlerta = () => ({
    type: HIDDEN_ALERT
})