import { useSelector } from "react-redux";
import {
  coProductEntity,
  cropEntity,
  energyEntity,
  irrigationEntity,
  landManagementsEntity,
  machinaryEntity,
  residueManagementEntity,
  storageEntity,
  subProcessEntity,
  transportsEntity,
  treeBiomassEntity,
  directEnergyType,
  machinaryTypeIdData,
  processingTypeData,
  landEntity,
} from "../commons/constants";
import { parseDataLand } from "src/modules/land/utils/parseDataLand";

export const useParseData = () => {
  /**
   * @type {import('utils/types.js').SelectSteps}
   * @const
   */
  const selectsState = useSelector((state) => {
    return state.selectSteps;
  });

  const cftUnits = useSelector((state) => {
    return state.selectSteps.cftUnits;
  });

  /**
   *
   * @param {Object} data
   * @param {string} data.entity
   */
  const parserDataToShow = (data) => {
    const { entity } = data;

    const dataToShow = [];

    for (const [key, value] of Object.entries(data)) {
      const dataToCell = parseDataToShowInCell(key, value, entity);

      if (key === "entity") {
        break;
      }

      dataToShow.push({
        key,
        value: dataToCell ? dataToCell.value : value,
      });
    }

    return dataToShow;
  };

  const parseDataToShowInCell = (key, value, entity) => {
    switch (entity) {
      case cropEntity:
        return parseDataCrop(key, value);
      case energyEntity:
        return parseDataEnergy(key, value);
      case residueManagementEntity:
        return parseDataResidueManagement(key, value);
      case landManagementsEntity:
        return parseDataLandManagement(key, value);
      case landEntity:
        return parseDataLand(key, value, selectsState);
      case irrigationEntity:
        return parseDataIrrigation(key, value);
      case transportsEntity:
        return parseDataTransport(key, value);
      case treeBiomassEntity:
        return parseDataTreeBiomas(key, value);
      case machinaryEntity:
        return parseDataMachinary(key, value);
      case subProcessEntity:
        return parseDataSubProcess(key, value);
      case coProductEntity:
        return parseDataCoProcess(key, value);
      case storageEntity:
        return parseDataStorage(key, value);
      default:
        break;
    }
  };

  const parseDataCrop = (key, value) => {
    if (key === "soilMoistureMatterId")
      return selectsState.floor.soilOrganicMatters.find(
        (entity) => Number(entity.id) === value
      );
    if (key === "soilPhId")
      return selectsState.floor.phs.find(
        (entity) => Number(entity.id) === value
      );
    if (key === "weatherId")
      return selectsState.floor.weathertypes.find(
        (entity) => Number(entity.id) === value
      );

    if (key === "soilDrainage")
      return selectsState.floor.soilDrainages.find(
        (entity) => Number(entity.id) === value
      );
    if (key === "soilTexture")
      return selectsState.floor.soilTextures.find(
        (entity) => Number(entity.id) === value
      );
    if (key === "soilCharacteristic")
      return selectsState.floor.soilCharacteristics.find(
        (entity) => Number(entity.id) === value
      );
  };

  const parseDataEnergy = (key, value) => {
    if (key === "category")
      return selectsState.energy.energyCategories.find(
        (entity) => Number(entity.id) === value
      );
    if (key === "source")
      return selectsState.energy.energySources.find(
        (entity) => Number(entity.id) === value
      );
    if (key === "usageValueUnit")
      return selectsState.cftUnits.find(
        (entity) => Number(entity.id) === value
      );
  };

  const parseDataResidueManagement = (key, value) => {
    if (key.search("residueValue") === 0)
      return { key, value: isNaN(value) ? "" : value };

    if (key === "residueManagementId")
      return selectsState.land.residueManagements.find(
        (entity) => Number(entity.id) === value
      );

    if (key === "residueUnit") {
      const unit = cftUnits.find(
        (entity) => String(entity.id) === String(value)
      );

      if (!unit) {
        return {
          key,
          value: "",
        };
      }

      return unit;
    }
  };

  const parseDataLandManagement = (key, value) => {
    if (key === "forestTypes") {
      if (value) {
        const [forestType] = value;

        return {
          key,
          value: selectsState.land.forestTypes.find(
            (entity) => Number(entity.id) === Number(forestType.forestTypeId)
          ).value,
        };
      }

      return { key, value: "-" };
    }

    if (key.search("allocation") === 0) return { key, value: `${value} %` };

    if (key === "changed") {
      return selectsState.land.landManagements.find(
        (entity) => Number(entity.id) === value
      );
    }
  };

  const parseDataIrrigation = (key, value) => {
    if (key.search("allocation") === 0) return { key, value: `${value} %` };
    if (key === "pumpingDepth") return { key, value: `${value} Metros` };
    if (key === "horizontalDistance") return { key, value: `${value} Metros` };
    if (key === "waterAdded") return { key, value: `${value} Litros` };
    if (key === "irrigationMethod")
      return selectsState.irrigation.irrigationMethods.find(
        (entity) => String(entity.id) === String(value)
      );
    if (key === "waterSource")
      return selectsState.irrigation.irrigationWaterSources.find(
        (entity) => String(entity.id) === String(value)
      );
    if (key === "powerSourceUsed")
      return selectsState.irrigation.irrigationPowerSources.find(
        (entity) => String(entity.id) === String(value)
      );
  };

  const parseDataTransport = (key, value) => {
    if (key === "distance") return { key, value };

    if (key === "distanceUnit") {
      const distanceUnit = selectsState.cftUnits.find(
        (entity) => entity.key === value
      );

      return {
        key: distanceUnit.key,
        value: distanceUnit.value,
      };
    }

    if (key === "weight") return { key, value };

    if (key === "weightUnit") {
      const weightUnit = selectsState.cftUnits.find(
        (entity) => entity.key === value
      );

      return {
        key: weightUnit.key,
        value: weightUnit.value,
      };
    }

    if (key === "mode") {
      const modeValue = selectsState.transport.transportTypes.find(
        (entity) => String(entity.id) === String(value)
      );
      return {
        key: modeValue.key,
        value: modeValue.value,
      };
    }
  };

  const parseDataTreeBiomas = (key, value) => {
    if (key === "treeType")
      return selectsState.trees.treeTypes.find(
        (entity) => String(entity.id) === String(value)
      );

    if (key === "diameterUnit")
      return cftUnits.find((entity) => String(entity.id) === String(value));

    if (key === "densityUnit")
      return cftUnits.find((entity) => String(entity.id) === String(value));
  };

  const parseDataMachinary = (key, value) => {
    if (key === "machinaryTypeId")
      return machinaryTypeIdData.find(
        (entity) => String(entity.id) === String(value)
      );
    if (key === "fuelTypeId")
      return directEnergyType.find(
        (entity) => String(entity.id) === String(value)
      );
  };

  function parseDataSubProcess(key, value) {
    if (key.search("allocation") === 0) return { key, value: `${value} %` };
    if (key === "type")
      return processingTypeData.find(
        (entity) => String(entity.id) === String(value)
      );
    if (key === "powerSource")
      return directEnergyType.find(
        (entity) => String(entity.id) === String(value)
      );
  }

  function parseDataStorage(key, value) {
    if (key === "temperature") return { key, value: `${value} °C` };
    if (key === "time")
      return { key, value: `${value} ${value === 1 ? "Día" : "Dias"}` };
    if (key.search("storedAllocation") === 0)
      return { key, value: `${value} %` };
    if (
      key === "storagePowerSource" ||
      key === "unloadingPowerSource" ||
      key === "storeLoadingPowerSource"
    )
      return directEnergyType.find(
        (entity) => Number(entity.id) === Number(value)
      );
  }

  function parseDataCoProcess(key, value) {
    if (key === "percentageMainValue") return { key, value: `${value} %` };
  }

  return {
    selectsState,
    parserDataToShow,
    parseDataToShowInCell,
    parseDataCrop,
    parseDataEnergy,
    parseDataResidueManagement,
    parseDataLandManagement,
    parseDataIrrigation,
    parseDataTransport,
    parseDataTreeBiomas,
    parseDataMachinary,
    parseDataSubProcess,
    parseDataCoProcess,
    parseDataStorage,
  };
};
