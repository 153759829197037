import { useTranslation } from "react-i18next";

export const useTranslate = () => {
  const { t, i18n } = useTranslation();

  const translate = (path, options = {}) => {
    return t(path, options, { returnObjects: true });
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return {
    t: translate,
    i18n,
    changeLanguage,
  };
};
