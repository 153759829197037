export const parseDataLand = (key, value, { land }) => {
  const { forestTypes } = land;
  if (key === "forestType" && value) {
    const forestTypeFind = forestTypes.find(
      (forestType) => Number(forestType.id) === Number(value)
    );
    return { key, value: forestTypeFind?.value };
  }
  if (key === "percentageAffectedArea") {
    return { key, value: `${value} %` };
  }
  if (typeof value === "object" && value !== null) {
    return { key, value: value?.description };
  }
  return { key, value: value };
};
