import { ADD_ITEM_TABLE, DEL_ITEM_TABLE, CLEAR_ITEM_TABLE } from "../types";

import { v4 as uuidv4 } from "uuid";

export function addNewEntity(entity) {
  return async (dispatch) => {
    entity.id = uuidv4();
    dispatch(newEntity(entity));
  };
}

const newEntity = (entity) => ({
  type: ADD_ITEM_TABLE,
  payload: entity,
});

export function deleteEntity(id, entity) {
  return async (dispatch) => {
    const data = {
      entity,
      id,
    };
    dispatch(delEntity(data));
  };
}

const delEntity = (payload) => ({
  type: DEL_ITEM_TABLE,
  payload: payload,
});

export function clearEntityAction() {
  return async (dispatch) => {
    dispatch(clearEntity());
  };
}

const clearEntity = () => ({
  type: CLEAR_ITEM_TABLE,
  payload: true,
});
