import { ADD_LANDMANAGEMENT, DEL_LANDMANAGEMENT } from "../types";

const initialState = {
 landManagements: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_LANDMANAGEMENT:
      return {
        ...state,
        landManagements: [...state.landManagements, action.payload],
      };
    case DEL_LANDMANAGEMENT:
      return {
        ...state,
        landManagements: state.landManagements.filter(
          (energy) => energy.id !== action.payload
        ),
        //productoeliminar: null
      };
    default:
      return state;
  }
}
