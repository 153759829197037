import React from "react";
import Swal from "sweetalert2";

// Redux
import { useDispatch } from "react-redux";
import { borrarEnergyAction, deleteEntity } from "../../store/actions";
import { useParseSumaryData } from "src/hooks/useParseSumaryData";
import { useTranslate } from "src/hooks/useTranslate/index";

const ItemSummary = (item) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { parserDataToSummary } = useParseSumaryData();

  const { data, entity, action } = item;
  const { id } = data;

  const functioDispatchTest = (id, entity) => {
    dispatch(deleteEntity(id, entity));
  };

  /*eslint-disable */
  const deleteItem = (id) => {
    Swal.fire({
      title: t("VALIDATIONS.ARE_YOU_SURE"),
      text: t("VALIDATIONS.A_RECORD_THAT_IS_DELETED_CANNOT_BE_RECOVERED"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("VALIDATIONS.YES_DELETE"),
      cancelButtonText: t("VALIDATIONS.CANCEL"),
    }).then((result) => {
      if (result.value) {
        switch (entity) {
          case "energy":
            dispatch(borrarEnergyAction(id));
          default:
            dispatch(deleteEntity(id));
        }
      }
    });
  };

  /*eslint-disable */
  const deleteItemTable = (id, entity) => {
    Swal.fire({
      title: t("VALIDATIONS.ARE_YOU_SURE"),
      text: t("VALIDATIONS.A_RECORD_THAT_IS_DELETED_CANNOT_BE_RECOVERED"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("VALIDATIONS.YES_DELETE"),
      cancelButtonText: t("VALIDATIONS.CANCEL"),
    }).then((result) => {
      if (result.value) {
        switch (entity) {
          case "energy":
            dispatch(borrarEnergyAction(id));
          default:
            functioDispatchTest(id, entity);
        }
      }
    });
  };

  switch (entity) {
    case "managamenent":
      return (
        <tr>
          <td style={{ textAlign: "center" }}>{data.changedLandManagement} </td>
          <td>
            <span className="font-weight-bold" style={{ textAlign: "center" }}>
              {" "}
              {data.yearsAgo}{" "}
            </span>
          </td>
          <td>
            <span className="font-weight-bold" style={{ textAlign: "center" }}>
              {" "}
              {data.usageValue} %{" "}
            </span>
          </td>
          <td className="acciones">
            <button
              type="button"
              className="btn btn-danger1"
              onClick={() => deleteItem(id)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-trash-fill"
                viewBox="0 0 16 16"
              >
                <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
              </svg>
            </button>
          </td>
        </tr>
      );

    // never forget the default case, otherwise VS code would be mad!
    default:
      const dataToShow = parserDataToSummary(data, entity);

      return (
        <tr>
          {dataToShow.map((data, index) => {
            return (
              <td
                key={`ITEM_SUMARY_DATA_${index}`}
                style={{ textAlign: "center" }}
              >
                {data.value}{" "}
              </td>
            );
          })}

          {action ? (
            <td className="acciones">
              <button
                type="button"
                className="btn btn-danger1"
                onClick={() => deleteItemTable(id, entity)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-trash-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                </svg>
              </button>
            </td>
          ) : null}
        </tr>
      );
  }
};

export default ItemSummary;
