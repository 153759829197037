export const energyListHead = [
  "ENERGIES_FORM.ENERGY_USE_CATEGORY",
  "ENERGIES_FORM.ENERGY_SOURCE_CATEGORY",
  "ENERGIES_FORM.FUEL_USED",
  "ENERGIES_FORM.FUEL_UNIT",
];

export const coProductListHead = [
  "Nombre del sub producto obtenido",
  "Porcentaje de sub producto obtenido",
];

export const managementListHead = [
  "LAND_MANAGEMENT_FORM.CATEGORY_OF_LAND_MANAGEMENT_CHANGE",
  "LAND_MANAGEMENT_FORM.YEARS_SINCE_CHANGE",
  "LAND_MANAGEMENT_FORM.PERCENTAGE_LAND_AFFECTED_BY_CHANGE",
  "LAND_MANAGEMENT_FORM.FOREST_TYPE",
];

export const IrrigarionListHead = [
  "Método de riego ",
  "Fuente de agua ",
  "Porcentaje de superficie ",
  "Profundidad de bombeo ",
  "Distancia horizontal ",
  "Cantidad de agua ",
  "Fuente de energia ",
];

export const machinaryListHead = [
  "Tipo de maquinaria de riego utilizada",
  "Fuente de energia de la maquinaria",
  "Numero de operaciones realizadas",
];

export const machinarySummaryListHead = [
  "Numero de operaciones realizadas",
  "Tipo de maquinaria de riego utilizada",
  "Fuente de energia de la maquinaria",
];

export const subProcessListHead = [
  "Tipo de sub procesos realizados",
  "Fuente de energia utilizada en el proceso",
  "Porcentaje de producto asignado al proceso",
];

export const irrigationMethodData = [
  { id: 4, value: "Goteo" },
  { id: 3, value: "Inundación" },
  { id: 1, value: "Pivote" },
  { id: 2, value: "Pistola de lluvia" },
];

export const irrigationWaterSourceData = [
  { id: 3, value: "Agua de lluvia" },
  { id: 1, value: "Pozo" },
  { id: 2, value: "Tuberia" },
];

export const irrigationPowerSourcedData = [
  { id: 2, value: "Diesel" },
  { id: 1, value: "Electricidad" },
  { id: 3, value: "Gravedad" },
];

export const processingTypeData = [
  { id: 1, value: "Calificación" },
  { id: 0, value: "Lavado" },
];

export const machinaryTypeIdData = [
  { id: 21, value: "Arado de barrido" },
  { id: 2, value: "Arado de cincel" },
  { id: 12, value: "Arado de vertedera" },
  { id: 17, value: "Azada rotativa/cultivador de bancada" },
  { id: 4, value: "Banda de disco" },
  { id: 29, value: "Cosechadora de maíz" },
  { id: 33, value: "Cosechadora de forraje" },
  { id: 37, value: "Cosechadora de patatas" },
  { id: 40, value: "Cosechadora de tomates" },
  { id: 7, value: "Cultivador de campo / aporcador" },
  { id: 18, value: "Cultivador de cultivos en hileras" },
  { id: 27, value: "Cosechadora de remolacha" },
  { id: 28, value: "Combinar" },
  { id: 3, value: "Cuchilla" },
  { id: 50, value: "Deshuesador de patatas" },
  { id: 47, value: "Desgarrador de discos" },
  { id: 14, value: "Desyerbador de varillas" },
  { id: 16, value: "Empacadora de rodillos" },
  { id: 1, value: "Empacadora (promedio)" },
  { id: 26, value: "Esparcimiento de fertilizantes" },
  { id: 24, value: "Fumigador de herbicidas" },
  { id: 25, value: "Fumigador de fertilizantes" },
  { id: 15, value: "Grada de rodillos" },
  { id: 22, value: "Grada de dientes/transporte de manipulación de semillas" },
  { id: 43, value: "Grada rotativa" },
  { id: 48, value: "Grada de rodillos" },
  { id: 38, value: "Hileradora de patatas" },
  { id: 41, value: "Hileradora/segmentadora" },
  { id: 45, value: "Inyecciones de bosta de animal" },
  { id: 6, value: "Lecho de disco" },
  { id: 42, value: "Montando" },
  { id: 31, value: "Pelacables de algodón/cobertura de patata" },
  { id: 34, value: "Propagador de bosta de animal" },
  { id: 44, value: "Pulverización de biocidas" },
  { id: 11, value: "Plano de tierra/eliminador de piedras" },
  { id: 49, value: "Plantación de patatas" },
  { id: 30, value: "Recogedor de algodón" },
  { id: 23, value: "Rastra de dientes" },
  { id: 5, value: "Rastra de discos" },
  { id: 39, value: "Rastrillo" },
  { id: 35, value: "Segadora/niveladora" },
  { id: 36, value: "Segadora-acondicionadora" },
  { id: 9, value: "Sembradora de granos" },
  { id: 19, value: "Sembradora de cultivos en hileras" },
  { id: 32, value: "Soplador/lavador de forraje" },
  { id: 20, value: "Subsolador" },
  { id: 8, value: "Taladro de grano" },
  { id: 10, value: "Taladro de azada" },
  { id: 13, value: "Taladro neumático" },
];

export const directEnergyType = [
  { id: 114, value: "Aceite (por volumen)" },
  { id: 105, value: "Biodiésel" },
  { id: 104, value: "Bioetanol" },
  { id: 127, value: "Biodiésel (bajo)" },
  { id: 128, value: "Bioetanol (bajo)" },
  { id: 110, value: "Biomasa de alta densidad" },
  { id: 116, value: "Carbón (por energía)" },
  { id: 112, value: "Carbón (por peso)" },
  { id: 343, value: "CHP in situ (gas natural)" },
  { id: 344, value: "CHP in situ (biogás)" },
  { id: 102, value: "Diesel" },
  { id: 346, value: "Exportación CHP - calor (gas natural)" },
  { id: 345, value: "Exportación CHP - electricidad (gas natural)" },
  { id: 347, value: "Exportación CHP - (biogás)" },
  { id: 106, value: "Electricidad (red eléctrica)" },
  { id: 107, value: "Electricidad (hidroeléctrica)" },
  { id: 108, value: "Electricidad (viento)" },
  { id: 109, value: "Electricidad (fotovoltaica)" },
  { id: 113, value: "Gas (en peso)" },
  { id: 117, value: "Gas (por energía)" },
  { id: 115, value: "Gas (por volumen)" },
  { id: 103, value: "Gasolina" },
  { id: 348, value: "Importación CHP - electricidad (gas natural)" },
  { id: 349, value: "Importación CHP - calor (gas natural)" },
  { id: 350, value: "Importación de cogeneración (biogás)" },
  { id: 111, value: "Leña" },
  { id: 118, value: "Petróleo (por energía)" },
  { id: 342, value: "Propano" },
];

export const machinaryEnergyType = [
  { id: 102, value: "Diesel" },
  { id: 103, value: "Gasolina" },
];

export const landManagementsTropicalData = [
  { id: 19, value: "Arable » bosque, clima tropical, suelo húmedo" },
  { id: 20, value: "Arable » bosque, clima tropical, suelo seco" },
  { id: 23, value: "Arable » hierba, clima tropical, suelo húmedo" },
  { id: 24, value: "Arable » hierba, clima tropical, suelo seco" },
  { id: 3, value: "Bosque » hierba, clima tropical, suelo húmedo" },
  { id: 4, value: "Bosque » hierba, clima tropical, suelo seco" },
  { id: 7, value: "Bosque » cultivable, clima tropical, suelo húmedo" },
  {
    id: 47,
    value: "Caja convencional » caja reducida, clima tropical, suelo húmedo",
  },
  {
    id: 48,
    value: "Caja convencional » caja reducida, clima tropical, suelo seco",
  },
  {
    id: 39,
    value: "Caja reducida » caja convencional, clima tropical, suelo húmedo",
  },
  {
    id: 40,
    value: "Caja reducida » caja convencional, clima tropical, suelo seco",
  },

  {
    id: 55,
    value:
      "Cultivo de cobertura » sin cultivo de cobertura, clima tropical, suelo húmedo",
  },
  {
    id: 56,
    value:
      "Cultivo de cobertura » sin cultivo de cobertura, clima tropical, suelo seco",
  },

  { id: 11, value: "Hierba » bosque, clima tropical, suelo húmedo" },
  { id: 12, value: "Hierba » bosque, clima tropical, suelo seco" },

  { id: 15, value: "Hierba » arable, clima tropical, suelo húmedo" },

  { id: 16, value: "Hierba » arable, clima tropical, suelo seco" },

  {
    id: 43,
    value: "Labranza convencional » no labranza, clima tropical, suelo húmedo",
  },
  {
    id: 44,
    value: "Labranza convencional » no labranza, clima tropical, suelo seco",
  },
  { id: 35, value: "Reducido hasta » no hasta, clima tropical, suelo húmedo" },
  { id: 36, value: "Reducido hasta » no hasta, clima tropical, suelo seco" },
  {
    id: 51,
    value:
      "Sin cultivo de cobertura » cultivo de cobertura, clima tropical, suelo húmedo",
  },
  {
    id: 27,
    value: "Sin labranza » labranza reducida, clima tropical, suelo húmedo",
  },
  {
    id: 28,
    value: "Sin labranza » labranza reducida, clima tropical, suelo seco",
  },
  {
    id: 31,
    value: "Sin labranza » labranza convencional, clima tropical, suelo húmedo",
  },
  {
    id: 32,
    value: "Sin labranza » labranza convencional, clima tropical, suelo seco",
  },
  {
    id: 52,
    value:
      "Sin cultivo de cobertura » cultivo de cobertura, clima tropical, suelo seco",
  },
];

//Humedo
export const landManagementsData = [
  { id: 17, value: "Arable » bosque, suelo húmedo" },
  { id: 18, value: "Arable » bosque, suelo seco" },
  { id: 21, value: "Arable » hierba, suelo húmedo" },
  { id: 22, value: "Arable » hierba, suelo seco" },
  { id: 1, value: "Bosque » hierba, suelo húmedo" },
  { id: 2, value: "Bosque » hierba, suelo seco" },
  { id: 5, value: "Bosque » cultivable, suelo húmedo" },
  { id: 6, value: "Bosque » cultivable, suelo seco" },
  {
    id: 45,
    value: "Caja convencional » caja reducida, suelo húmedo",
  },
  {
    id: 46,
    value: "Caja convencional » caja reducida, suelo seco",
  },
  {
    id: 37,
    value: "Caja reducida » caja convencional, suelo húmedo",
  },
  {
    id: 38,
    value: "Caja reducida » caja convencional, suelo seco",
  },
  {
    id: 53,
    value: "Cultivo de cobertura » sin cultivo de cobertura, suelo húmedo",
  },
  {
    id: 54,
    value: "Cultivo de cobertura » sin cultivo de cobertura, suelo seco",
  },
  { id: 13, value: "Hierba » arable, suelo húmedo" },
  { id: 14, value: "Hierba » arable, suelo seco" },

  { id: 9, value: "Hierba » bosque, suelo húmedo" },
  { id: 10, value: "Hierba » bosque, suelo seco" },
  {
    id: 41,
    value: "Labranza convencional » no labranza, suelo húmedo",
  },
  {
    id: 42,
    value: "Labranza convencional » no labranza, suelo seco",
  },
  { id: 33, value: "Reducido hasta » no hasta, suelo húmedo" },
  { id: 34, value: "Reducido hasta » no hasta, suelo seco" },
  {
    id: 49,
    value: "Sin cultivo de cobertura » cultivo de cobertura, suelo húmedo",
  },
  {
    id: 25,
    value: "Sin labranza » labranza reducida, suelo húmedo",
  },
  {
    id: 26,
    value: "Sin labranza » labranza reducida, suelo seco",
  },
  {
    id: 29,
    value: "Sin labranza » labranza convencional, suelo húmedo",
  },
  {
    id: 30,
    value: "Sin labranza » labranza convencional, suelo seco",
  },
  {
    id: 50,
    value: "Sin cultivo de cobertura » cultivo de cobertura, suelo seco",
  },
  { id: 57, value: "Sin incorporación » incorporación" },

  { id: 58, value: "Incorporación » no incorporación" },
];

//humedo
export const landManagementsDampData = [
  { id: 17, value: "Arable » bosque, suelo húmedo" },
  { id: 21, value: "Arable » hierba, suelo húmedo" },
  { id: 1, value: "Bosque » hierba, suelo húmedo" },
  { id: 5, value: "Bosque » cultivable, suelo húmedo" },
  {
    id: 45,
    value: "Caja convencional » caja reducida, suelo húmedo",
  },
  {
    id: 37,
    value: "Caja reducida » caja convencional, suelo húmedo",
  },
  {
    id: 53,
    value: "Cultivo de cobertura » sin cultivo de cobertura, suelo húmedo",
  },
  { id: 13, value: "Hierba » arable, suelo húmedo" },
  { id: 9, value: "Hierba » bosque, suelo húmedo" },
  {
    id: 41,
    value: "Labranza convencional » no labranza, suelo húmedo",
  },
  { id: 33, value: "Reducido hasta » no hasta, suelo húmedo" },
  {
    id: 49,
    value: "Sin cultivo de cobertura » cultivo de cobertura, suelo húmedo",
  },
  {
    id: 25,
    value: "Sin labranza » labranza reducida, suelo húmedo",
  },
  {
    id: 29,
    value: "Sin labranza » labranza convencional, suelo húmedo",
  },
  { id: 57, value: "Sin incorporación » incorporación" },
  { id: 58, value: "Incorporación » no incorporación" },
];

//seco
export const landManagementsDriedData = [
  { id: 18, value: "Arable » bosque, suelo seco" },
  { id: 22, value: "Arable » hierba, suelo seco" },
  { id: 2, value: "Bosque » hierba, suelo seco" },
  { id: 6, value: "Bosque » cultivable, suelo seco" },
  {
    id: 46,
    value: "Caja convencional » caja reducida, suelo seco",
  },
  {
    id: 38,
    value: "Caja reducida » caja convencional, suelo seco",
  },
  {
    id: 54,
    value: "Cultivo de cobertura » sin cultivo de cobertura, suelo seco",
  },
  { id: 14, value: "Hierba » arable, suelo seco" },
  { id: 10, value: "Hierba » bosque, suelo seco" },
  {
    id: 42,
    value: "Labranza convencional » no labranza, suelo seco",
  },
  { id: 34, value: "Reducido hasta » no hasta, suelo seco" },
  {
    id: 26,
    value: "Sin labranza » labranza reducida, suelo seco",
  },
  {
    id: 30,
    value: "Sin labranza » labranza convencional, suelo seco",
  },
  {
    id: 50,
    value: "Sin cultivo de cobertura » cultivo de cobertura, suelo seco",
  },
  { id: 57, value: "Sin incorporación » incorporación" },
  { id: 58, value: "Incorporación » no incorporación" },
];

export const treeBiomasListHead = [
  "TREE_FORM.PREDOMINANT_TREE_CLASSIFICATION",
  "TREE_FORM.DENSITY",
  "TREE_FORM.DIAMETER_PREVIOUS_YEAR",
  "TREE_FORM.DIAMETER_CURRENT_YEAR",
  "TREE_FORM.UNIT_DIAMETER",
  "TREE_FORM.QTY_PLANTED_LOST_TREES",
  "TREE_FORM.UNIT_DENSITY",
];

export const transportsListHead = [
  "TRANSPORT_FORM.TYPE_VEHICLE_USED",
  "TRANSPORT_FORM.DISTANCE_TRAVELED_IN_TRANSPORT",
  "TRANSPORT_FORM.DISTANCE_UNIT",
  "TRANSPORT_FORM.WEIGHT_TRANSPORT_LOAD",
  "TRANSPORT_FORM.WEIGHT_UNIT",
];

export const soilTextureResponseData = [
  {
    value: "Arcillosa",
    id: 1,
  },
  {
    value: "Arcillo arenosa",
    id: 1,
  },
  {
    value: "Franco arenosa",
    id: 3,
  },
  {
    value: "Areno franca",
    id: 3,
  },
  {
    value: "Areno-gravillosa",
    id: 3,
  },
  {
    value: "Arenoso gruesa",
    id: 3,
  },
  {
    value: "Arenosa",
    id: 3,
  },
  {
    value: "Franca",
    id: 2,
  },
  {
    value: "Arcillo limosa",
    id: 1,
  },
  {
    value: "Franco arcillo areno",
    id: 2,
  },
  {
    value: "Franco-limo-gravillo",
    id: 2,
  },
  {
    value: "Franco arcillo limos",
    id: 2,
  },
  {
    value: "Franco arcillosa",
    id: 1,
  },
  {
    value: "Franco limosa",
    id: 2,
  },
  {
    value: "Areno-franca",
    id: 3,
  },
  {
    value: "Franco arenosa grues",
    id: 3,
  },
  {
    value: "Franca limosa",
    id: 2,
  },
];

export const soilDrainageResponseData = [
  {
    value: "Imperfecto",
    id: 2,
  },
  {
    value: "Excesivo",
    id: 1,
  },
  {
    value: "Bien drenado",
    id: 1,
  },
  {
    value: "Algo Excesivo",
    id: 1,
  },
  {
    value: "Pobre",
    id: 2,
  },
  {
    value: "Moderado",
    id: 1,
  },
  {
    value: "Muy pobre",
    id: 2,
  },
  {
    value: "Imperfectamente dren",
    id: 2,
  },
  {
    value: "Algo excesivamente d",
    id: 1,
  },
  {
    value: "Pobre a imperfectame",
    id: 2,
  },
  {
    value: "Moderadamente bien d",
    id: 1,
  },
  {
    value: "Mal o muy pobremente",
    id: 2,
  },
  {
    value: "Imperfecto a moderad",
    id: 2,
  },
  {
    value: "Pobremente drenado",
    id: 2,
  },
];

export const soilTextureNoData = [
  {
    value: "No determinada",
  },
  {
    value: "-",
  },
];

export const soilDrainageNoData = [
  {
    value: "No determinada",
  },
  {
    value: "-",
  },
];

export const storageListHead = [
  "Energia utilizada en almacenamiento",
  "Energia en descarga de almacenamiento",
  "CIPC DOSE",
  "Porcentaje de producto almacenado",
  "Temperatura promedio",
  "Tiempo promedio",
  "Energia en carga de almacenamiento",
  "Aplicaciones de CIPC DOSE",
];

export const storageSummaryListHead = [
  "Aplicaciones de CIPC DOSE",
  "CIPC DOSE",
  "Energia utilizada en almacenamiento",
  "Energia en descarga de almacenamiento",
  "Porcentaje de producto almacenado",
  "Temperatura promedio",
  "Tiempo promedio",
  "Energia en carga de almacenamiento",
];

export const cropListHead = [
  "CROP_FORM.SOIL_ORGANIC_MATTER",
  "CROP_FORM.SOIL_CHARACTERISTIC",
  "CROP_FORM.SOIL_PH",
  "CROP_FORM.CLIMATE",
  "CROP_FORM.DRAINAGE",
  "CROP_FORM.TEXTURE",
];

export const residueManagementListHead = [
  "LAND_MANAGEMENT_FORM.CROP_RESIDUE_MANAGEMENT",
  "LAND_MANAGEMENT_FORM.AMOUNT_OF_WASTE",
  "LAND_MANAGEMENT_FORM.UNIT",
];

export const coProductEntity = "coProduct";
export const energyEntity = "energy";
export const irrigationEntity = "irrigation";
export const machinaryEntity = "machinary";
export const subProcessEntity = "subProcess";
export const transportsEntity = "transports";
export const treeBiomassEntity = "treeBiomass";
export const cropEntity = "crop";
export const residueManagementEntity = "residueManagement";
export const establishmentEntity = "establishment";
export const storageEntity = "storages";
export const landManagementsEntity = "landManagements";
export const landEntity = "land";

export const regexEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const formType = {
  floor: "land_use",
  tillage: "tillage",
  coverage: "cover",
  waste: "waste",
};

export const moistureTyoe = {
  1: "moist",
  2: "dry",
};

export const climateType = {
  1: "temperate",
  2: "tropical",
};

export const LandManagementIdTypes = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 17, 18, 19, 20,
];

export const STORAGE_TAX_ID_KEY = "taxId";
