import {
  ADD_ESTABLISHMENT,
  ADD_ESTABLISHMENT_SUCESSFULL,
  ADD_ESTABLISHMENT_ERROR,
  START_DOWNLOAD_ESTABLISHMENT,
  DOWNLOAD_ESTABLISHMENT_SUCESSFULL,
  DOWNLOAD_ESTABLISHMENT_ERROR,
  GET_ESTABLISHMENT_DELETE,
  ESTABLISHMENT_DELETE_SUCESSFULL,
  ESTABLISHMENT_DELETE_ERROR,
  START_DOWNLOAD_CROP,
  DOWNLOAD_CROP_SUCESSFULL,
  DOWNLOAD_CROP_ERROR,
  START_DOWNLOAD_DETAIL_ESTABLISHMENT,
  DOWNLOAD_DETAIL_ESTABLISHMENT_SUCESSFULL,
  DOWNLOAD_DETAIL_ESTABLISHMENT_ERROR,
  START_DOWNLOAD_CROP_TYPES,
  DOWNLOAD_CROP_TYPES_SUCESSFULL,
  DOWNLOAD_CROP_TYPES_ERROR,
  ADD_UNIT_MEASURE,
} from "../types";

import {
  clienteAxiosCFT,
  clienteAxiosForm,
  clienteAxiosAPICORE,
} from "../../config/axios";
import Swal from "sweetalert2";

export function saveEstablishment(producto) {
  return async (dispatch) => {
    dispatch(addEstablishment());

    try {
      const response = await clienteAxiosForm.post("/establishment", producto);

      const { data } = response;

      if (data) {
        const { id } = data;
        dispatch(addEstablishmentSucessfull(producto));

        Swal.fire({
          title: "Correcto",
          text: "El establecimiento se agregó correctamente, desea descargar evidencia",
          icon: "success",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, descargar!!",
          cancelButtonText: "no",
        }).then((result) => {
          if (result.value) {
            clienteAxiosForm(`/calculation/${id}/pdf`, {
              method: "GET",
              responseType: "blob", //Force to receive data in a Blob Format
            })
              .then((response) => {
                //Create a Blob from the PDF Stream
                const file = new Blob([response.data], {
                  type: "application/pdf",
                });
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(fileURL);
              })
              .catch((error) => {
                Swal.fire("Eliminado", "Error al generar el PDF", "error");
                console.error(error);
              });
          }
        });
      }

      // Si todo sale bien, actualizar el state
    } catch (error) {
      // si hay un error cambiar el state
      dispatch(addEstablishmentError(true));

      Swal.fire({
        icon: "error",
        title: "Hubo un error",
        text: "Hubo un error, intenta de nuevo",
      });
    }
  };
}

const addEstablishment = () => ({
  type: ADD_ESTABLISHMENT,
});

const addEstablishmentSucessfull = (producto) => ({
  type: ADD_ESTABLISHMENT_SUCESSFULL,
  payload: producto,
});

const addEstablishmentError = (estado) => ({
  type: ADD_ESTABLISHMENT_ERROR,
  payload: estado,
});

export function getEstablishmentAction(urlParams) {
  return async (dispatch) => {
    dispatch(donwloadEstablishments());

    try {
      const url = `/v1/carbon-footprint${urlParams ? `?${urlParams}` : ""}`;
      const establishments = await clienteAxiosForm.get(url);
      dispatch(donwloadEstablishmentsSucessfull(establishments.data));
    } catch (error) {
      dispatch(donwloadEstablishmentsError());
    }
  };
}

const donwloadEstablishments = () => ({
  type: START_DOWNLOAD_ESTABLISHMENT,
  payload: true,
});

const donwloadEstablishmentsSucessfull = (establishments) => ({
  type: DOWNLOAD_ESTABLISHMENT_SUCESSFULL,
  payload: establishments,
});
const donwloadEstablishmentsError = () => ({
  type: DOWNLOAD_ESTABLISHMENT_ERROR,
  payload: true,
});

export function deleteEstablishmentAction(id) {
  return async (dispatch) => {
    dispatch(obtenerProductoEliminar(id));
    try {
      await clienteAxiosCFT.delete(`/establishments/${id}`);

      dispatch(deleteEstablishmentSucessfull());

      Swal.fire(
        "Eliminado",
        "El establecimiento se eliminó correctamente",
        "success"
      );
    } catch (error) {
      dispatch(deleteEstablishmentError());
    }
  };
}

const obtenerProductoEliminar = (id) => ({
  type: GET_ESTABLISHMENT_DELETE,
  payload: id,
});
const deleteEstablishmentSucessfull = () => ({
  type: ESTABLISHMENT_DELETE_SUCESSFULL,
});
const deleteEstablishmentError = () => ({
  type: ESTABLISHMENT_DELETE_ERROR,
  payload: true,
});

export function getCropAction(id, cropType, errorMessage) {
  return async (dispatch) => {
    dispatch(donwloadCrops());

    try {
      let url = `/v2/crops?identifier=${id}`;

      if (cropType) {
        url = `${url}&cropType=${cropType}`;
      }

      const crops = await clienteAxiosForm.get(url);

      if (!crops.data.length) {
        Swal.fire({
          icon: "warning",
          title: errorMessage,
        });
      }
      dispatch(donwloadCropsSucessfull(crops.data));
    } catch (error) {
      dispatch(donwloadCropsError());
    }
  };
}

const donwloadCrops = () => ({
  type: START_DOWNLOAD_CROP,
  payload: true,
});

const donwloadCropsSucessfull = (crops) => ({
  type: DOWNLOAD_CROP_SUCESSFULL,
  payload: crops,
});
const donwloadCropsError = () => ({
  type: DOWNLOAD_CROP_ERROR,
  payload: true,
});

export function getDetailEstablishmentAction(id) {
  return async (dispatch) => {
    dispatch(donwloadDetailEstablishments());

    try {
      const establishments = await clienteAxiosCFT.get(`establishments/${id}`);
      dispatch(donwloadDetailEstablishmentsSucessfull(establishments.data));
    } catch (error) {
      dispatch(donwloadDetailEstablishmentsError());
    }
  };
}

const donwloadDetailEstablishments = () => ({
  type: START_DOWNLOAD_DETAIL_ESTABLISHMENT,
  payload: true,
});

const donwloadDetailEstablishmentsSucessfull = (establishments) => ({
  type: DOWNLOAD_DETAIL_ESTABLISHMENT_SUCESSFULL,
  payload: establishments,
});
const donwloadDetailEstablishmentsError = () => ({
  type: DOWNLOAD_DETAIL_ESTABLISHMENT_ERROR,
  payload: true,
});

export function donwloadPdfAction(establishment) {
  return async () => {
    const { id } = establishment;

    clienteAxiosForm(`/calculation/${id}/pdf`, {
      method: "GET",
      responseType: "blob", //Force to receive data in a Blob Format
    })
      .then((response) => {
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], {
          type: "application/pdf",
        });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
      })
      .catch((error) => {
        Swal.fire(
          "Faltan datos",
          "Faltan datos en el establecimiento para realizar calculo de emision de carbono",
          "error"
        );
      });
  };
}

export function getCropTypesAction({ language }) {
  return async (dispatch) => {
    dispatch(donwloadCropTypess());

    try {
      const config = {
        headers: {
          "Accept-Language": language,
        },
      };

      const cropTypes = await clienteAxiosAPICORE.get(
        `/v1/commons/croptypes`,
        config
      );
      dispatch(donwloadCropTypessSucessfull(cropTypes.data));
    } catch (error) {
      dispatch(donwloadCropTypessError());
    }
  };
}

const donwloadCropTypess = () => ({
  type: START_DOWNLOAD_CROP_TYPES,
  payload: true,
});

const donwloadCropTypessSucessfull = (cropTypes) => ({
  type: DOWNLOAD_CROP_TYPES_SUCESSFULL,
  payload: cropTypes,
});

const donwloadCropTypessError = () => ({
  type: DOWNLOAD_CROP_TYPES_ERROR,
  payload: true,
});

export function setUnitMeasureAction(payload) {
  return async (dispatch) => {
    dispatch(addUnits(payload));
  };
}

const addUnits = (payload) => ({
  type: ADD_UNIT_MEASURE,
  payload,
});
