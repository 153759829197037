import { useSelector } from "react-redux";
import FormInput from "../form/formInput";
import TooltipsForm from "../form/tooltipsForm";

import List from "../list/list";
import { useTranslate } from "src/hooks/useTranslate/index";
const FormBody = (data) => {
  const { t } = useTranslate();

  const {
    titleForm,
    titleTable,
    InputOption,
    functionSubmit,
    dataToList,
    entity,
    toolTipOption,
    disabledAdd = false,
  } = data.option;
  const alerta = useSelector((state) => state.alerta.alerta);
  const cargando = useSelector((state) => state.establishment.loading);
  const error = useSelector((state) => state.establishment.error);

  return (
    <div>
      <h4 className="mb-10 font-weight-bold">
        {titleForm}
        &nbsp;
        {toolTipOption?.msg ? (
          <TooltipsForm
            msg={toolTipOption.msg}
            height={toolTipOption.height}
            width={toolTipOption.width}
          ></TooltipsForm>
        ) : null}
      </h4>
      <br></br>
      <br></br>
      {(alerta && !alerta?.entity) ||
      (alerta?.entity && alerta.entity === entity) ? (
        <p className={alerta.classes}> {alerta.msg} </p>
      ) : null}
      <form onSubmit={functionSubmit}>
        <div className="containter">
          <div className="row">
            {InputOption.map((option, index) => {
              return (
                <div className={option.style} key={`input_${index}`}>
                  <FormInput option={option}></FormInput>
                </div>
              );
            })}
          </div>
        </div>
        <br></br>
        <br></br>
        <br></br>
        <button
          type="submit"
          className="btn btn-primary font-weight-bold btn-next"
          disabled={disabledAdd}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-plus"
            viewBox="0 0 16 16"
          >
            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
          </svg>{" "}
          {t("COMMON.ADD")}
        </button>
        <br></br>
        <br></br>
        {dataToList && (
          <List
            title={titleTable ?? titleForm}
            items={dataToList.items}
            heads={dataToList.heads}
            entity={entity}
            action={true}
            callbackAfterDelete={dataToList.callbackAfterDelete}
          ></List>
        )}
      </form>
      {cargando ? <p>{t("COMMON.LOADING")}...</p> : null}

      {error ? (
        <p className="alert alert-danger p2 mt-4 text-center">
          {t("COMMON.GENERAL_ERROR")}
        </p>
      ) : null}
    </div>
  );
};

export default FormBody;
