/**
 * @typedef {object} SelectsFromApi
 * @property {object} energy
 * @property {object[]} energy.energyCategories
 * @property {string} energy.energyCategories._id
 * @property {number} energy.energyCategories.id
 * @property {string} energy.energyCategories.name
 * @property {string} energy.energyCategories.description
 * @property {boolean} energy.energyCategories.disabled
 * @property {number} energy.energyCategories.__v
 * @property {object[]} energy.energySources
 * @property {string} energy.energySources._id
 * @property {number} energy.energySources.id
 * @property {string} energy.energySources.name
 * @property {boolean} energy.energySources.disabled
 * @property {number} energy.energySources.__v
 * @property {object} land
 * @property {object[]} land.residueManagements
 * @property {string} land.residueManagements._id
 * @property {number} land.residueManagements.id
 * @property {string} land.residueManagements.name
 * @property {boolean} land.residueManagements.disabled
 * @property {number} land.residueManagements.__v
 * @property {object[]} land.landManagements
 * @property {string} land.landManagements._id
 * @property {number} land.landManagements.id
 * @property {string} land.landManagements.category
 * @property {string} land.landManagements.fromTo
 * @property {string} land.landManagements.climate
 * @property {string} land.landManagements.moisture
 * @property {boolean} land.landManagements.disabled
 * @property {number} land.landManagements.__v
 * @property {object} irrigation
 * @property {object[]} irrigation.irrigationMethods
 * @property {string} irrigation.irrigationMethods._id
 * @property {number} irrigation.irrigationMethods.id
 * @property {string} irrigation.irrigationMethods.name
 * @property {string} irrigation.irrigationMethods.description
 * @property {boolean} irrigation.irrigationMethods.disabled
 * @property {number} irrigation.irrigationMethods.__v
 * @property {object[]} irrigation.irrigationPowerSources
 * @property {string} irrigation.irrigationPowerSources._id
 * @property {number} irrigation.irrigationPowerSources.id
 * @property {string} irrigation.irrigationPowerSources.name
 * @property {string} irrigation.irrigationPowerSources.description
 * @property {boolean} irrigation.irrigationPowerSources.disabled
 * @property {number} irrigation.irrigationPowerSources.__v
 * @property {object[]} irrigation.irrigationWaterSources
 * @property {string} irrigation.irrigationWaterSources._id
 * @property {number} irrigation.irrigationWaterSources.id
 * @property {string} irrigation.irrigationWaterSources.name
 * @property {string} irrigation.irrigationWaterSources.description
 * @property {boolean} irrigation.irrigationWaterSources.disabled
 * @property {number} irrigation.irrigationWaterSources.__v
 * @property {object} floor
 * @property {object[]} floor.climates
 * @property {string} floor.climates._id
 * @property {number} floor.climates.id
 * @property {string} floor.climates.name
 * @property {string} floor.climates.description
 * @property {number} floor.climates.avgTemp
 * @property {boolean} floor.climates.disabled
 * @property {number} floor.climates.__v
 * @property {object[]} floor.phs
 * @property {string} floor.phs._id
 * @property {number} floor.phs.id
 * @property {string} floor.phs.name
 * @property {string} floor.phs.description
 * @property {boolean} floor.phs.disabled
 * @property {number} floor.phs.__v
 * @property {object[]} floor.soilDrainages
 * @property {string} floor.soilDrainages._id
 * @property {number} floor.soilDrainages.id
 * @property {string} floor.soilDrainages.name
 * @property {string} floor.soilDrainages.description
 * @property {boolean} floor.soilDrainages.disabled
 * @property {number} floor.soilDrainages.__v
 * @property {object[]} floor.soilMoistures
 * @property {string} floor.soilMoistures._id
 * @property {number} floor.soilMoistures.id
 * @property {string} floor.soilMoistures.name
 * @property {string} floor.soilMoistures.description
 * @property {boolean} floor.soilMoistures.disabled
 * @property {number} floor.soilMoistures.__v
 * @property {object[]} floor.soilOrganicMatters
 * @property {string} floor.soilOrganicMatters._id
 * @property {number} floor.soilOrganicMatters.id
 * @property {string} floor.soilOrganicMatters.name
 * @property {string} floor.soilOrganicMatters.description
 * @property {boolean} floor.soilOrganicMatters.disabled
 * @property {number} floor.soilOrganicMatters.__v
 * @property {object[]} floor.soilTextures
 * @property {string} floor.soilTextures._id
 * @property {number} floor.soilTextures.id
 * @property {string} floor.soilTextures.name
 * @property {string} floor.soilTextures.description
 * @property {boolean} floor.soilTextures.disabled
 * @property {number} floor.soilTextures.__v
 * @property {object} transport
 * @property {object[]} transport.transportTypes
 * @property {string} transport.transportTypes._id
 * @property {number} transport.transportTypes.id
 * @property {string} transport.transportTypes.name
 * @property {boolean} transport.transportTypes.disabled
 * @property {number} transport.transportTypes.__v
 * @property {object} trees
 * @property {object[]} trees.treeTypes
 * @property {string} trees.treeTypes._id
 * @property {number} trees.treeTypes.id
 * @property {string} trees.treeTypes.name
 * @property {boolean} trees.treeTypes.disabled
 * @property {number} trees.treeTypes.__v
 */

/**
 * @namespace
 * @typedef  {Object} OptionItem
 * @property {string|number} id
 * @property {string} value
 */

/**
 * @typedef FloorType
 * @property {any[]} climates
 * @property {any[]} phs
 * @property {any[]} soilDrainages
 * @property {any[]} soilMoistures
 * @property {any[]} soilOrganicMatters
 * @property {any[]} soilTextures
 */

/**
 * @typedef SelectSteps
 * @property {FloorType} floor
 * @property {EnergyType} energy
 * @property {LandType} land
 * @property {IrrigationType} irrigation
 * @property {TransportType} transport
 * @property {TreesType} trees
 */

/**
 * @typedef EnergyType
 * @property {any[]} energyCategories
 * @property {any[]} energySources
 */

/**
 * @typedef LandType
 * @property {any[]} residueManagements
 * @property {any[]} landManagements
 */

/**
 * @typedef IrrigationType
 * @property {any[]} irrigationMethods
 * @property {any[]} irrigationPowerSources
 * @property {any[]} irrigationWaterSources
 */

/**
 * @typedef TransportType
 * @property {any[]} transportTypes
 */

/**
 * @typedef TreesType
 * @property {any[]} treeTypes
 */

/**
 * @typedef ResponseCreateEstablishment
 * @property {object} dataFromCFT
 * @property {object} establishmentCreated
 * @property {string} establishmentCreated.id
 * @property {string} establishmentCreated.name
 * @property {string} establishmentCreated.identifier
 */

export const Types = {};

/**
 * @typedef SoilType
 * @property {number} soilMoistureMatterId
 * @property {string} soilMoistureMatterDescription
 * @property {string} soilMoistureDescription
 * @property {number} soilPhId
 * @property {string} soilPhDescription
 * @property {number} soilTextureId
 * @property {string} soilTextureDescription
 * @property {number} soilDrainageId
 * @property {string} soilDrainageDescription
 * @property {number} weatherId
 * @property {string} weatherDescription
 */

/**
 * @typedef treeBiomas
 * @property {number} treeType
 * @property {number} densityLastYear
 * @property {number} sizeLastYear
 * @property {number} sizeThisYear
 * @property {number} numTreesDelta
 * @property {string} treeTypeDescription
 */

/**
 * @typedef PreloadDataResponse
 * @property {SoilType} soil
 * @property {treeBiomas[]} treeBiomas
 */

/**
 * @typedef preloadDataToFormMapperReturn
 * @property {object} dataToSendSoil
 * @property {object[]} dataToSendTreeBiomas
 */
