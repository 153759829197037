import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import validation from "../../commons/validation";

// Actions de Redux
import { showAlert, hiddenAlert, addNewEntity } from "../../store/actions";

import FormBody from "../form/formBody";
import FootButtonForm from "../form/footButtonForm";

import {
  residueManagementEntity,
  residueManagementListHead,
  formType,
} from "../../commons/constants";
import { EUnitMeasureSystem } from "src/utils/unitMeasureSystemEnum";
import { useTranslate } from "src/hooks/useTranslate/index";

const unitsEquivalences = {
  [EUnitMeasureSystem.IMPERIAL]: {
    landUnits: {
      options: ["8", "29", "30"],
      defaultValue: "8",
    },
  },
  [EUnitMeasureSystem.METRIC]: {
    landUnits: {
      options: ["12", "24"],
      defaultValue: "24",
    },
  },
};

const ResidueDataForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const residueManagementToList = useSelector((state) => {
    return state.table.residueManagements;
  });

  const selectsLandState = useSelector((state) => {
    return state.selectSteps.land;
  });

  const unitMeasureSystem = useSelector((state) => {
    return state.establishment.unitMeasureSystem;
  });

  const cftUnits = useSelector((state) => {
    return state.selectSteps.cftUnits;
  });

  const { residueManagements: residueManagementData } = selectsLandState;

  const [residueManagementId, saveResidueManagementId] = useState(0);
  const [residueValue, saveResidueValue] = useState("");
  const [alert, setAlert] = useState("");
  const [residueUnit, setResidueUnit] = useState("");

  const residueManagementClearData = () => {
    saveResidueManagementId(0);
    saveResidueValue("");
  };

  const residueManagementSubmit = (e) => {
    e.preventDefault();

    const dataToSend = {
      residueManagementId: parseInt(residueManagementId),
      residueValue: parseFloat(residueValue),
      residueUnit: parseInt(residueUnit),
      entity: residueManagementEntity,
    };

    const validationResponse = validation(dataToSend, t);

    setAlert(null);

    if (validationResponse) {
      const alerta = {
        msg: validationResponse.msg,
        classes: "alert alert-danger text-center text-uppercase p3",
        entity: residueManagementEntity,
      };
      dispatch(showAlert(alerta));

      return;
    }

    dispatch(addNewEntity(dataToSend));

    dispatch(hiddenAlert());
    residueManagementClearData();
  };

  const optionsUnitLand = cftUnits.filter((unit) =>
    unitsEquivalences[unitMeasureSystem].landUnits?.options?.includes(unit.id)
  );

  const residueManagementFormOption = {
    titleForm: t("LAND_MANAGEMENT_FORM.WASTE_DATA"),
    functionSubmit: residueManagementSubmit,
    entity: residueManagementEntity,
    disabledAdd: residueManagementToList?.length,
    dataToList: {
      items: residueManagementToList,
      heads: residueManagementListHead,
    },
    titleTable: t("LAND_MANAGEMENT_FORM.WASTE_DATA"),
    InputOption: [
      {
        style: "col-md-12",
        titleForm: `${t("LAND_MANAGEMENT_FORM.CROP_RESIDUE_MANAGEMENT")} *`,
        inputType: "select",
        value: residueManagementId,
        valueChanged: saveResidueManagementId,
        dataToSelect: residueManagementData,
        disabled: residueManagementToList?.length,
      },
      {
        style: "col-md-6",
        titleForm: `${t("LAND_MANAGEMENT_FORM.AMOUNT_OF_WASTE")} *`,
        inputType: "number",
        value: residueValue,
        valueChanged: saveResidueValue,
        disabled: residueManagementToList?.length,
      },
      {
        style: "col-md-6",
        titleForm: `${t("LAND_MANAGEMENT_FORM.UNIT")} *`,
        inputType: "select",
        value: residueUnit,
        valueChanged: setResidueUnit,
        dataToSelect: optionsUnitLand,
        disabled: residueManagementToList?.length,
      },
    ],
  };

  const onValidationPrevSubmit = () => {
    if (!Boolean(residueManagementToList?.length)) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      const alerta = {
        msg: t("LAND_MANAGEMENT_FORM.ALERTS.ENTER_WASTE_DATA"),
        classes: "alert alert-danger text-center text-uppercase p3",
        entity: residueManagementEntity,
      };
      dispatch(showAlert(alerta));
      return;
    }
    return true;
  };

  const renderAlert = (formType) => {
    return Boolean(alert && alert.formType === formType) ? (
      <p className={alert.classes}>{alert.msg}</p>
    ) : null;
  };

  return (
    <div className="row justify-content-center">
      <div className="col-md-12">
        <div className="stepwizard">
          <div className="stepwizard-row">
            <div className="stepwizard-step">
              <button
                type="button"
                className="btn btn-default btn-circle"
                disabled="disabled"
              >
                2
              </button>
              <p>{t("CROP_FORM.SOIL")}</p>
            </div>
            <div className="stepwizard-step">
              <button
                type="button"
                className="btn btn-primary1 btn-circle"
                disabled="disabled"
              >
                3
              </button>
              <p>{t("FORM_STEPS.WASTE")}</p>
            </div>

            <div className="stepwizard-step">
              <button
                type="button"
                className="btn btn-default btn-circle"
                disabled="disabled"
              >
                4
              </button>
              <p>{t("FORM_STEPS.ENERGY")}</p>
            </div>
            <div className="stepwizard-step">
              <button
                type="button"
                className="btn btn-default btn-circle"
                disabled="disabled"
              >
                5
              </button>
              <p>{t("LAND_MANAGEMENT_FORM.LAND")}</p>
            </div>
            <div className="stepwizard-step">
              <button
                type="button"
                className="btn btn-default btn-circle"
                disabled="disabled"
              >
                6
              </button>
              <p>{t("FORM_STEPS.TRANSPORT")}</p>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
        {renderAlert(formType.waste)}
        <FormBody option={residueManagementFormOption} />
        <br></br>
      </div>
      <br></br>
      <div className="container">
        <br></br>
        <br></br>
        <br></br>
        <FootButtonForm
          prev={true}
          next={true}
          onValidationPrevSubmit={onValidationPrevSubmit}
        />
        <br></br>
      </div>
      <br></br>
      <br></br>
      <div className="container">
        <br></br>
        <br></br>
      </div>
      <br></br>
    </div>
  );
};

export default ResidueDataForm;
