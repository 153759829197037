import moment from "moment";
import { numberHasTwoDecimals } from "src/utils/numberHasTwoDecimals";
const AGE_MINUS = 20;
const MAX_PERCENTAGE_AFFECTED_AREA = 100;
const schemaValidator = {
  yearOfChange: (value, t, yearOfChange) => {
    const currentYear = moment().year();
    const minAge = currentYear - AGE_MINUS;
    if (!value) {
      return {
        error: true,
        msg: t("LAND_FORM.ERRORS.AGE_OF_CHANGE"),
      };
    }

    if (value < minAge) {
      return {
        error: true,
        msg: t("LAND_FORM.ERRORS.MIN_AGE"),
      };
    }

    if (value >= yearOfChange) {
      return {
        error: true,
        msg: t("LAND_FORM.ERRORS.MAX_AGE"),
      };
    }
  },
  percentageAffectedArea: (value, t) => {
    if (!value) {
      return {
        error: true,
        msg: t("LAND_FORM.ERRORS.PERCENTAGE_AFFECTED_AREA"),
      };
    }
    if (value > MAX_PERCENTAGE_AFFECTED_AREA) {
      return {
        error: true,
        msg: t("LAND_FORM.ERRORS.ERROR_PERCENTAGE"),
      };
    }
    if (!isNaN(value)) {
      const isValidResidueValue = numberHasTwoDecimals(value);
  
      if (!isValidResidueValue) {
        return {
          error: true,
          msg: t("LAND_FORM.ERRORS.ONLY_TWO_DECIMALS"),
        };
      }
    }
  },
  landUse: (value, t) => {
    if (!value) {
      return {
        error: true,
        msg: t("LAND_FORM.ERRORS.SELECT_LAND_USE"),
      };
    }
  },
  tillage: (value, t) => {
    if (!value) {
      return {
        error: true,
        msg: t("LAND_FORM.ERRORS.SELECT_TILLAGE"),
      };
    }
  },
  carbonInput: (value, t) => {
    if (!value) {
      return {
        error: true,
        msg: t("LAND_FORM.ERRORS.SELECT_CARBON_INPUTS"),
      };
    }
  },
};

export const validateLandForm = (data, t, lastLand) => {
  const { yearOfChange } = lastLand ?? {};
  const keys = Object.keys(schemaValidator);
  for (let key of keys) {
    const error = schemaValidator[key](data[key], t, yearOfChange);
    if (error) {
      return error;
    }
  }
};
