import { clienteAxiosForm } from "../../config/axios";
import { SAVE_TOKEN } from "../types";
import Swal from "sweetalert2";
import { STORAGE_TAX_ID_KEY } from "src/commons/constants";
import {
  getSessionStorage,
  removeItemSessionStorage,
  setSessionStorage,
} from "src/utils/index";

/**
 *
 * @param {object} data
 * @param {string} data.email
 * @param {string} data.password
 * @param {string|undefined} data.taxId
 * @returns
 */
export function loginAction({ data, t }) {
  return async (dispatch) => {
    try {
      const taxId = getSessionStorage("taxId");

      data.taxId = data.taxId || taxId;

      dispatch(login());

      removeItemSessionStorage(STORAGE_TAX_ID_KEY);

      const response = await clienteAxiosForm.post("/auth/login", data);

      setSessionStorage("token", JSON.stringify(response.data.access_token));

      setSessionStorage(STORAGE_TAX_ID_KEY, data.taxId);

      const tokenString = getSessionStorage("token");

      const userToken = JSON.parse(tokenString);

      const payload = {
        token: userToken,
        taxId: data?.taxId,
      };

      dispatch(loginSucessfull(payload));

      Swal.fire(
        t("LOGIN.MODALS.WELCOME.TITLE"),
        t("LOGIN.MODALS.WELCOME.SUCCESSFULLY_AUTH"),
        "success"
      );
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: t("LOGIN.ALERTS.USER_IS_INVALID"),
        text: t("LOGIN.ALERTS.USER_OR_PASSWORD_IS_INVALID"),
      });

      dispatch(loginFailure());
    }
  };
}

const login = () => ({
  type: "START_LOGIN",
  payload: true,
});

const loginSucessfull = (token) => ({
  type: "LOGIN_SUCESSFULL",
  payload: token,
});

const loginFailure = () => ({
  type: "LOGIN_FAILURE",
  payload: {
    loading: false,
    error: true,
  },
});

export function isLoginAction() {
  return async (dispatch) => {
    const tokenString = getSessionStorage("token");
    const userToken = JSON.parse(tokenString);
    const payload = {
      token: userToken,
    };
    if (userToken) dispatch(saveToken(payload));
  };
}

export function logoutAction() {
  return async (dispatch) => {
    dispatch(logout());
    Swal.fire({
      title: "¿Estas seguro?",
      text: "Desea finalizar la sesión",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, cerrar sesión!!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        removeItemSessionStorage("token");
        removeItemSessionStorage(STORAGE_TAX_ID_KEY);
        dispatch(logout());
      }
    });
  };
}

export const logout = () => ({
  type: "START_LOGOUT",
  payload: true,
});

const saveToken = (token) => ({
  type: SAVE_TOKEN,
  payload: token,
});
