import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getCropAction,
  addNewEntity,
  sumStep,
  getCropTypesAction,
  setUnitMeasureAction,
  showAlert,
  hiddenAlert,
  clearEntityAction,
} from "../../store/actions/index";

import FormSelectCrop from "../form/formSelectCrop";
import { getSelects } from "src/services";
import { mapSelectApiToRedux } from "src/utils/mapSelectApiToRedux";
import { EUnitMeasureSystemAreaUnit } from "src/utils";
import { setLoadValues } from "src/store/slices/selectStepsSlice";
import { useQuery } from "src/hooks/useQuery";
import { clienteAxiosForm } from "src/config/axios";
import { useTranslate } from "src/hooks/useTranslate/index";
import { Spinner } from "react-bootstrap";

const EstablishmentForm = () => {
  const { t, i18n } = useTranslate();

  const query = useQuery();
  const dispatch = useDispatch();
  const user = useSelector((state) => {
    const { taxId } = state.user;

    return {
      taxId,
    };
  });

  useEffect(() => {
    dispatch(clearEntityAction());

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const cropsTypesActions = () =>
      dispatch(getCropTypesAction({ language: i18n?.language }));
    cropsTypesActions();

    // eslint-disable-next-line
  }, []);

  const cropsTypesActions = useSelector((state) => {
    return state.establishment.cropTypes;
  });

  const step = useSelector((state) => {
    return state.step.position;
  });

  const establishments = useSelector((state) => {
    return state.establishment;
  });

  const [isLoading, setIsloading] = useState(false);
  const [identifier, saveIdentifier] = useState("");
  const [crop, saveCrop] = useState(0);
  const [establishment, saveEstablishment] = useState("");
  const [cropType, saveCropType] = useState("");
  const [allCrops, setAllCrops] = useState([]);
  const [disabledEstablishmentInputs, setDisabledEstablishmentInputs] =
    useState(false);
  const [noHarvestInCrop, setNoHarvestInCrop] = useState(false);
  const crops = allCrops.length ? allCrops : establishments.crops;
  const cropSelect = !crop
    ? []
    : crops.find((cropToSelect) => cropToSelect._id === crop);
  const establishmentsList = !cropSelect ? [] : cropSelect.lots || allCrops;
  const cropsTypeList = cropsTypesActions.map((cropType) => {
    return {
      _id: cropType._id,
      name: cropType.keyLabel,
    };
  });

  const alerta = useSelector((state) => state.alerta.alerta);
  const cargando = useSelector((state) => state.establishment.loading);
  const error = useSelector((state) => state.establishment.error);

  useEffect(() => {
    if (user.taxId) {
      saveIdentifier(user.taxId);
    }

    const cropId = query.get("cropId");
    const farmId = query.get("farmId");
    const taxId = query.get("taxId");
    const cropType = query.get("cropType");

    if (taxId && taxId !== user.taxId) {
      return null;
    }

    if (user.taxId && cropId && farmId) {
      getCropAndFarm(cropId, farmId, user.taxId, cropType);
    }
    // eslint-disable-next-line
  }, [user.taxId]);

  useEffect(() => {
    if (establishment) {
      const establishmentSelect = establishmentsList.find(
        (establishmentToSelect) => establishmentToSelect._id === establishment
      );

      const unitMeasure = {
        unitMeasureSystem: cropSelect.company.unitMeasureSystem,
        areaUnit:
          EUnitMeasureSystemAreaUnit[cropSelect.company.unitMeasureSystem],
      };

      dispatch(setUnitMeasureAction(unitMeasure));

      (async () => {
        const response = await getSelects({ language: i18n?.language });

        const cropTypeKey = getCropTypeKey();

        const selects = mapSelectApiToRedux(
          response,
          unitMeasure.unitMeasureSystem,
          cropTypeKey
        );

        dispatch(setLoadValues(selects));
      })();

      dispatch(
        addNewEntity({
          ucropitEstablishmentId: establishmentSelect?.farm.hasOwnProperty(
            "_id"
          )
            ? establishmentSelect.farm._id
            : establishmentSelect.farm,
          ucropitCropId: cropSelect._id,
          name: establishmentSelect.tag,
          identifier: cropSelect.identifier,

          entity: "establishment",
        })
      );
    }
    // eslint-disable-next-line
  }, [establishment, cropType]);

  const getCropAndFarm = async (cropId, farmId, identifier, cropType) => {
    try {
      setIsloading(true);
      setDisabledEstablishmentInputs(true);

      let url = `/v2/crops?identifier=${identifier}`;

      if (cropType) {
        url = `${url}&cropType=${cropType}`;
      }

      const crops = await clienteAxiosForm.get(url);

      const cropSelected = crops.data.find(
        (crop) => crop._id?.toString() === cropId
      );

      const farmSelected = cropSelected?.lots.find(
        (lot) => lot?.farm?._id?.toString() === farmId
      );

      if (cropSelected && farmSelected) {
        saveCropType(cropSelected.cropType._id);
        setAllCrops(crops.data);
        saveCrop(cropSelected._id);
        saveEstablishment(farmSelected._id);
      } else {
        setNoHarvestInCrop(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsloading(false);
    }
  };

  const serchDataForIdentifier = (e) => {
    e?.preventDefault();
    if (identifier === "") {
      const alerta = {
        msg: t("SELECT_FARM.ERRORS.IDENTIFIER_REQUIRED"),
        classes: "alert alert-danger text-center p3",
      };

      dispatch(showAlert(alerta));

      return;
    }

    dispatch(hiddenAlert());
    dispatch(
      getCropAction(identifier, cropType, t("SELECT_FARM.ERRORS.NOT_FOUND"))
    );
  };

  const nextStep = (e) => {
    e.preventDefault();
    let position = step + 1;
    dispatch(sumStep(position));
  };

  const getCropTypeKey = () => {
    const cropTypeEstablishment = establishments?.crops[0]?.cropType?.key;

    const cropTypeSelected = establishments?.cropTypes?.find(
      (cropTypeOriginal) => cropTypeOriginal._id === cropType
    );

    return cropTypeEstablishment ?? cropTypeSelected?.key;
  };

  return (
    <div className="row justify-content-center">
      <div className="col-md-12">
        {error ? (
          <p className="alert alert-danger p2 mt-4 text-center">
            {t("COMMON.GENERAL_ERROR")}
          </p>
        ) : null}

        {alerta ? <p className={alerta.classes}> {alerta.msg} </p> : null}

        <div className="stepwizard">
          <div className="stepwizard-row">
            <div className="stepwizard-step">
              <button type="button" className="btn btn-primary1 btn-circle">
                1
              </button>
              <p>{t("FORM_STEPS.START")}</p>
            </div>
            <div className="stepwizard-step">
              <button
                type="button"
                className="btn btn-default btn-circle"
                disabled="disabled"
              >
                2
              </button>
              <p>{t("FORM_STEPS.SOIL")}</p>
            </div>
            <div className="stepwizard-step">
              <button
                type="button"
                className="btn btn-default btn-circle"
                disabled="disabled"
              >
                3
              </button>
              <p>{t("FORM_STEPS.WASTE")}</p>
            </div>
            <div className="stepwizard-step">
              <button
                type="button"
                className="btn btn-default btn-circle"
                disabled="disabled"
              >
                4
              </button>
              <p>{t("FORM_STEPS.ENERGY")}</p>
            </div>
            <div className="stepwizard-step">
              <button
                type="button"
                className="btn btn-default btn-circle"
                disabled="disabled"
              >
                5
              </button>
              <p>{t("FORM_STEPS.LAND")}</p>
            </div>
          </div>
        </div>

        <br></br>
        <br></br>
        <br></br>
        <h4 className="mb-4 font-weight-bold">{t("SELECT_FARM.TITLE")}</h4>
        <br></br>

        <form>
          <div></div>
          <br></br>
          <div className="containter">
            <div className="row">
              <div className="col-md-5 form-group">
                <label>{t("SELECT_FARM.FIELDS.IDENTIFIER.LABEL")}</label>
                <input
                  disabled={Boolean(user.taxId)}
                  type="text"
                  className="form-control"
                  placeholder={t("SELECT_FARM.TAX_ID")}
                  name="identifier"
                  value={identifier ?? ""}
                  onChange={(e) => saveIdentifier(e.target.value)}
                />
              </div>
              <div className="col-md-3 form-group">
                <FormSelectCrop
                  inputType="crop"
                  title={t("SELECT_FARM.FIELDS.CROP_TYPE.LABEL")}
                  value={cropType}
                  valueChanged={saveCropType}
                  placeholder=""
                  dataToSelect={cropsTypeList}
                  disabled={disabledEstablishmentInputs}
                ></FormSelectCrop>
              </div>

              {!disabledEstablishmentInputs && (
                <div className="col-md-4">
                  <button
                    onClick={serchDataForIdentifier}
                    className="btn btn-primary font-weight-bold establishmenButton btn-block"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-search"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0
                     0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
                      />
                    </svg>{" "}
                    {t("SELECT_FARM.SEARCH_BUTTON")}
                  </button>
                </div>
              )}
            </div>
          </div>

          {noHarvestInCrop ? (
            <div className="row">
              <div className="col-md-12">
                <label style={{ color: "red" }}>
                  {t("SELECT_FARM.ERRORS.ACTIVITIES_REQUIRED")}
                </label>
              </div>
            </div>
          ) : null}

          <br></br>
          {crops?.length ? (
            <FormSelectCrop
              inputType="crop"
              title={t("SELECT_FARM.FIELDS.CROP.LABEL")}
              value={crop}
              valueChanged={saveCrop}
              placeholder=""
              dataToSelect={crops}
              disabled={disabledEstablishmentInputs}
            ></FormSelectCrop>
          ) : null}
          <br></br>
          {establishmentsList.length ? (
            <FormSelectCrop
              inputType="establishment"
              title={t("SELECT_FARM.FIELDS.FARM.LABEL")}
              value={establishment}
              valueChanged={saveEstablishment}
              placeholder=""
              dataToSelect={establishmentsList}
              disabled={disabledEstablishmentInputs}
            ></FormSelectCrop>
          ) : null}
          <br></br>
          <br></br>
          {establishment ? (
            <button
              onClick={nextStep}
              className="btn btn-primary font-weight-bold btn-next"
            >
              {t("SELECT_FARM.NEXT_BUTTON")}

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-chevron-right"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </button>
          ) : null}
        </form>

        {cargando || isLoading ? (
          <div className="row justify-content-center">
            <Spinner className="flex" animation="border" variant="dark" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default EstablishmentForm;
