import { useTranslate } from "src/hooks/useTranslate/index";

const FormSelectCrop = ({
  title,
  value,
  valueChanged,
  placeholder,
  dataToSelect,
  inputType,
  disabled = false,
}) => {
  const { t } = useTranslate();

  switch (inputType) {
    case "crop":
      return (
        <div className="form-group">
          <label>{title}</label>
          <select
            type="number"
            className="form-control"
            placeholder={placeholder}
            name="data"
            value={value}
            onChange={(e) => valueChanged(e.target.value)}
            disabled={disabled}
          >
            <option value="">{t("COMMON.FIELDS.DEFAULT_SELECT_LABEL")}</option>
            {dataToSelect.map((data, i) => {
              return (
                <option key={`${title}-${i}`} value={data._id}>
                  {data.name}
                </option>
              );
            })}
          </select>
        </div>
      );
    case "establishment":
      return (
        <div className="form-group">
          <label>{title}</label>
          <select
            type="number"
            className="form-control"
            placeholder={placeholder}
            name="data"
            value={value}
            onChange={(e) => valueChanged(e.target.value)}
            disabled={disabled}
          >
            <option value="">{t("COMMON.FIELDS.DEFAULT_SELECT_LABEL")}</option>
            {dataToSelect.map((data, i) => {
              return (
                <option key={`${title}-${i}`} value={data._id}>
                  {data.tag}
                </option>
              );
            })}
          </select>
        </div>
      );
    default:
      break;
  }
};

export default FormSelectCrop;
