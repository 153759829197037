import axios from "axios";

export const clienteAxiosForm = axios.create({
  baseURL: process.env.REACT_APP_CFT_INTEGRATION_API,
});

export const clienteAxiosCFT = axios.create({
  baseURL: process.env.REACT_APP_CFT_FORM_API,
});

export const clienteAxiosAPICORE = axios.create({
  baseURL: process.env.REACT_APP_API_CORE,
});

export const clienteAxiosTypeLand = axios.create({
  baseURL: process.env.REACT_APP_TYPE_LAND_URL,
});
