import { clienteAxiosForm } from "../config/axios";

/**
 *
 * @param {string} ucropitEstablishmentId
 * @param {string} identifier
 *
 * @returns {Promise<import("src/utils/types").PreloadDataResponse>}
 */
export const getPreloadedSoilData = async (ucropitEstablishmentId) => {
  try {
    const response = await clienteAxiosForm.get(
      `v1/carbon-footprint/${ucropitEstablishmentId}/preload-last-data`
    );

    /** @type {import("src/utils/types").PreloadDataResponse} */
    const preloadData = response.data;

    return preloadData;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};
