import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import ItemSummary from "./itemSummary";

const ListSummary = (data) => {
  const { title, items, heads, entity, action } = data;
  const cargando = useSelector((state) => state.establishment.loading);

  return (
    <>
      <h4 className="mb-4 font-weight-bold">{title}</h4>

      {cargando ? <p className="text-center">Cargando....</p> : null}

      <table className="table table-striped">
        <thead className="">
          <tr>
            {heads.map((head, index) => {
              return (
                <th
                  key={`HEAD_SUMARY_${index}`}
                  scope="col"
                  style={{ textAlign: "center" }}
                >
                  {head}
                </th>
              );
            })}

            {action ? (
              <th className="acciones" scope="col">
                {" "}
                Acciones
              </th>
            ) : null}
          </tr>
        </thead>
        <tbody>
          {items.length ? (
            items.map((item, index) => (
              <ItemSummary
                key={`ITEM_SUMARY_${index}`}
                data={item}
                entity={entity}
                action={action}
              ></ItemSummary>
            ))
          ) : (
            <tr>
              <td colSpan={heads.length + 1} className="text-center">
                No hay registros
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default ListSummary;
