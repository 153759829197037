export const es = {
  translation: {
    COMMON: {
      FIELDS: {
        DEFAULT_SELECT_LABEL: "Sin seleccionar",
      },
      ACTIONS: "Acciones",
      UNSELECTED: "Sin seleccionar",
      GENERAL_ERROR: "Hubo un error",
      GENERAL_ERROR_TRY_AGAIN: "Hubo un error, intenta de nuevo",
      BACK: "Atrás",
      CORRECT: "Correcto",
      ADVISE: "Aviso",
      LOADING: "Cargando...",
      ADD: "Agregar",
      NEXT: "Siguiente",
      LOG_OUT: "Cerrar sesión",
      NO_RECORDS: "No hay registros",
      GENERATE_NEW_REPORT: "Generar nuevo informe",
    },
    MESSAGES: {
      STABLISHMENT_CREATED:
        "El establecimiento se agregó correctamente, desea descargar evidencia.",
    },
    FORM_STEPS: {
      SOIL: "Suelo",
      ENERGY: "Energía",
      LAND: "Tierra",
      TRANSPORT: "Transporte",
      TREES: "Árboles",
      WASTE: "Residuo",
      START: "Inicio",
      SUMMARY: "Resumen",
      STORAGE: "Almacenamiento",
      MACHINERY: "Maquinarias",
      SUB_PROCESS: "Sub procesos",
      SUB_PRODUCTS: "Sub productos",
    },
    LOGIN: {
      TITLE: "Login",
      FIELDS: {
        EMAIL: {
          LABEL: "Email",
          HELP_TEXT: "El email es requerido",
        },
        PASSWORD: {
          LABEL: "Contraseña",
          HELP_TEXT: "Contraseña es requerida",
        },
      },
      BUTTON: "Login",
      ALERTS: {
        EMAIL_IS_REQUIRED: "Por favor ingresa tu email",
        EMAIL_IS_INVALID: "Ingresa un email válido",
        PASSWORD_INVALID_LENGTH:
          "Su contraseña debe contener entre 6 y 8 caracteres",
        USER_IS_INVALID: "Usuario inválido",
        USER_OR_PASSWORD_IS_INVALID: "Usuario o contraseña inválidos",
      },
      MODALS: {
        WELCOME: {
          TITLE: "Bienvenido",
          SUCCESSFULLY_AUTH: "Ha iniciado sesión satisfactoriamente",
          BUTTON: "OK",
        },
      },
    },
    SELECT_FARM: {
      TITLE: "Generar nueva evidencia",
      FIELDS: {
        IDENTIFIER: {
          LABEL: "CUIT o identificación del productor",
        },
        CROP_TYPE: {
          LABEL: "Especificar tipo de cultivo",
        },
        CROP: {
          LABEL: "Cultivo del productor",
        },
        FARM: {
          LABEL: "Campo del cultivo",
        },
      },
      ERRORS: {
        ACTIVITIES_REQUIRED:
          "El cultivo debe tener una cosecha validada para calcular los gases de efecto invernadero (GEI) con la metodología CFT.",
        IDENTIFIER_REQUIRED:
          "Debe ingresar un cuit, nit o identificador de productor",
        NOT_FOUND: "No se encontraron resultados en la búsqueda",
      },
      SEARCH_BUTTON: "Buscar",
      NEXT_BUTTON: "Siguiente",
      LOADING: "Cargando...",
      TAX_ID: "Cuit",
    },
    CROP_FORM: {
      SOIL: "Suelo",
      ADD_SOIL_AND_WEATHER_DATA: "Datos de suelo y clima",
      SOIL_ORGANIC_MATTER: "Materia orgánica del suelo",
      SOIL_WATER_CHARACTERIZATION: "Caracterización hídrica del suelo",
      SOIL_WATER_CHARACTERIZATION_TOOLTIP:
        "Los suelos húmedos, ya sea por precipitaciones o riego, son los que no presentan restricciones de agua. Los suelos secos, son los que presentan restricciones de agua.",
      SOIL_PH: "PH del suelo",
      CLIMATE: "Clima",
      SOIL_DRAINAGE: "Drenaje del suelo",
      SOIL_TEXTURE: "Textura del suelo",
      SOIL_MOISTURE: "Humedad del suelo",
      SOIL_CHARACTERISTIC: "Características del suelo",
      DRAINAGE: "Drenaje",
      TEXTURE: "Textura",
      SELECT_TYPE_ORGANIC_MATTER: "Seleccione un tipo de materia orgánica",
      SELECT_TYPE_SOIL_WATTER_CHARACTERIZATION:
        "Seleccione un tipo de caracterización hídrica del suelo",
      SELECT_CLIMATE_TYPE: "Seleccione tipo de clima",
      SELECT_SOIL_DRAINAGE_TYPE: "Seleccione un tipo de drenaje del suelo",
      SELECT_SOIL_TEXTURE_TYPE: "Seleccione un tipo de textura del suelo",
      SELECT_SOIL_PH_TYPE: "Seleccione un tipo de ph del suelo",
      CALCULATED_DATA: "Dato calculado",
      ALERTS: {
        SENDING_MORE_THAN_ONE_CROP:
          "No se puede enviar más de un cultivo en el proceso.",
        DATA_REQUIRED: "Debe ingresar datos de cultivos antes de continuar.",
        SELECT_A_SOIL_FEATURE_TYPE:
          "Seleccione un tipo de característica del suelo",
      },
    },
    ENERGIES_FORM: {
      ENERGY: "Energía",
      ENERGY_DATA: "Datos de energía",
      ENERGY_USE_CATEGORY: "Categoría del uso de la energía",
      ENERGY_SOURCE_CATEGORY: "Categoría de la fuente de energía",
      FUEL_USED: "Combustible utilizado",
      FUEL_UNIT: "Unidad del combustible ",
      TOOLTIPS: {
        ENERGY_DATA: `
          Indique la cantidad de energía utilizada por hectárea/acre por año. En caso de que la explotación agrícola abarque toda la superficie, introduzca la energía total utilizada en la explotación durante un año. 

          Las posibles fuentes de energía incluyen electricidad, gas y combustibles. Esto abarca la energía empleada en el sitio para actividades como riego, iluminación, ventilación y similares, y también debe incluir la energía utilizada por los contratistas.
          
          Es importante señalar que se debe excluir la energía utilizada para el transporte fuera de la explotación, ya que esta se tratará en la siguiente sección. 

          Si resulta complicado determinar la cantidad de energía utilizada por el producto individual bajo evaluación (en relación con otros productos cultivados), puede utilizar la calculadora de emisiones de maquinaria, consultar a un asesor especializado o estimar el uso de energía de este producto en relación con otros, teniendo en cuenta el área de tierra, el volumen o el valor económico.
        `,
      },
      ALERTS: {
        SELECT_ENERGY_USE_CATEGORY:
          "Seleccione una categoría del uso de energía",
        SELECT_ENERGY_SOURCE_CATEGORY:
          "Seleccione una categoría de la fuente de energía",
        ENTER_ENERGY_USE_AMOUNT: "Indique en número el combustible utilizado",
        SELECT_FUEL_UNIT: "Seleccione una unidad de combustible",
      },
    },
    LAND_MANAGEMENT_FORM: {
      LAND: "Tierra",
      LAND_MANAGEMENT_DATA: "Datos de manejo de tierra",
      CHANGE_IN_LAND_USE: "Cambio en el uso de suelo",
      LAND_USE_CHANGE_RATE: "Tipo de cambio en el uso de suelo",
      NUMBER_OF_YEARS_ELAPSED: "Cantidad de años transcurridos",
      PERCENTAGE_OF_AFFECTED_AREA: "Porcentaje de superficie afectada",
      FOREST_TYPE: "Tipo de bosque",
      CHANGE_OF_TILLAGE_SYSTEM: "Cambio del sistema de labranzas",
      TILLAGE_SYSTEM_EXCHANGE_RATE: "Tipo de cambio del sistema de labranzas",
      USE_OF_COVER_CROPS: "Uso de cultivos de cobertura",
      CHANGE_RATE_IN_COVER_CROPS:
        "Tipo de cambio en el uso de cultivos de cobertura",
      WASTE_DATA: "Datos de residuos",
      CROP_RESIDUE_MANAGEMENT: "Manejo de residuo del cultivo",
      AMOUNT_OF_WASTE: "Cantidad de residuos",
      UNIT: "Unidad",
      CATEGORY_OF_LAND_MANAGEMENT_CHANGE:
        "Categoría del cambio del manejo de tierra",
      YEARS_SINCE_CHANGE: "Años transcurridos desde el cambio",
      PERCENTAGE_LAND_AFFECTED_BY_CHANGE:
        "Porcentaje de tierra afectada por el cambio",
      TOOLTIPS: {
        LAND_MANAGEMENT_DATA: `
        Indique si se han realizado modificaciones en las prácticas de labranza, cultivos de cobertura o uso de la tierra en los últimos 20 años, excluyendo las fluctuaciones anuales. 
        Omita esta sección en caso de que no se hayan realizado cambios en sus prácticas de gestión en dicho período.
        `,
      },
      ALERTS: {
        SELECT_WASTE_MANAGEMENT_TYPE: "Seleccione un tipo de manejo de residuo",
        ENTER_WASTE_PERCENTAGE:
          "Ingrese en número la cantidad de residuo generado",
        SELECT_LAND_USE_CHANGE: "Ingrese el cambio en el uso del suelo",
        INDICATE_YEARS_METHODOLOGY_IMPLEMENTED:
          "Indique en número la cantidad de años que tiene la metodología implementada",
        INDICATE_PERCENTAGE_AREA_AFFECTED:
          "Indique el porcentaje de superficie afectada",
        SELECT_FOREST_TYPE: "Seleccione un tipo de bosque",
        SELECT_TILLAGE_CHANGE: "Seleccione un cambio de labranza",
        SELECT_CHANGE_IN_COVER_CROPS:
          "Seleccione un cambio en el uso de cultivos de cobertura",
        ENTER_WASTE_DATA: "DEBE INGRESAR DATOS DE RESIDUOS ANTES DE CONTINUAR",
        UNIT_REQUIRED: "La unidad debe ser seleccionada",
      },
    },
    LAND_FORM: {
      TITLE: "Cambios en la practica de gestión actual",
      TOOLTIP_MESSAGE: `Indique si realizó cambios en la labranza, cultivos de cobertura o manejo de insumos orgánicos en los últimos 20 años.
        No indique las fluctuaciones anuales. Si no ha cambiado sus prácticas de gestión en los últimos 20 años, puede omitir esta sección`,
      LAND: "Tierra",
      YEAR_OF_CHANGE: "Año de cambio",
      PERCENTAGE_AFFECTED_AREA: "Porcentaje de superficie afectada",
      LAND_USE: "Uso de suelo",
      TILLAGE: "Labranza",
      CARBON_INPUTS: "Entrada de carbono",
      FOREST_TYPE: "Tipo de bosque",
      ERRORS: {
        SELECT_LAND_USE: "Seleccione un uso de suelo",
        SELECT_TILLAGE: "Seleccione un tipo de labranza",
        SELECT_FOREST_TYPE: "Seleccione un tipo de bosque",
        SELECT_CARBON_INPUTS: "Seleccione una entrada de carbono",
        AGE_OF_CHANGE: "Ingrese en número la cantidad de años del cambio",
        MIN_AGE:
          "El valor de año del cambio no puede ser inferior a 20 años desde el año actual",
        PERCENTAGE_AFFECTED_AREA:
          "Ingrese el porcentaje de superficie afectada",
        MAX_AGE:
          "El valor de año del cambio no puede ser superior al cambio anterior",
        ERROR_PERCENTAGE: "El valor del porcentaje no puede ser mayor a 100",
        ONLY_TWO_DECIMALS: "El valor permite ingresar hasta 2 decimales",
      },
      TOOLTIP: `Este es un resumen simplificado de las categorías de entrada de carbono, para obtener una descripción más precisa.

      Aportes bajos en carbono: residuos eliminados o quemados, suelo desnudo durante > 6 meses en los últimos 3 años, cultivos de bajo rendimiento, sin fertilización.
      
      Aportes medios de carbono: cereales con residuos devueltos o materia orgánica suplementaria, cultivos de cobertura durante > 6 meses en los últimos 3 años o residuos dejados en el campo (pero no ambos).
      
      Altos aportes de carbono (sin abono): Altos aportes de residuos con cultivos de cobertura durante > 6 meses en los últimos 3 años, sistema de ley dentro de los tres años, etc., pero sin abono.
      
      Altos aportes de carbono (con abono): adición regular de abono animal para aumentar el aporte de carbono al menos una vez cada tres años, además de cultivos de cobertura durante > 6 meses en los últimos 3 años y residuos dejados en el campo.
      
      Nota: El purín es "alto sin abono", pero si el usuario aplica suficiente purín y hay una entrada significativa de carbono, entonces puede cambiarlo a "alto con abono".`,
    },
    TREE_FORM: {
      TITLE: "Datos de masa arbórea",
      TOOLTIP_MESSAGE:
        "Indique la densidad de árboles por hectárea/acres en el área del cultivo, con el diámetro medido a la altura del pecho.",
      PREDOMINANT_TREE_CLASSIFICATION: "Clasificación Arbórea Predominante",
      DIAMETER_PREVIOUS_YEAR: "Diámetro a la altura del pecho del año anterior",
      DIAMETER_CURRENT_YEAR: "Diámetro a la altura del pecho del año en curso",
      UNIT_DENSITY: "Unidad de densidad",
      UNIT_DIAMETER: "Unidad de diámetro",
      TREES_PLANTED: "Árboles plantados",
      TREES_LOST: "Árboles perdidos",
      DENSITY: "Densidad",
      QTY_PLANTED_LOST_TREES:
        "Cantidad de árboles plantados (+) / perdidos (-)",
      ERRORS: {
        TREE_TYPE: "Seleccione el tipo de árboles de la masa arbórea",
        DENSITY_PREVIOUS_YEAR:
          "Indique en número el diámetro de los árboles del año anterior",
        DENSITY_CURRENT_YEAR:
          "Indique en número el diámetro de los árboles del año en curso",
        TREES_PLANTED: "Indique en número la cantidad de árboles plantados",
        TREES_LOST: "Indique en número la cantidad de árboles perdidos",
        DENSITY: "Indique en número la densidad",
        UNIT_DENSITY: "Seleccione la unidad de densidad",
        UNIT_DIAMETER: "Seleccione la unidad de diámetro",
      },
    },
    TRANSPORT_FORM: {
      TRANSPORT: "Transporte",
      TRANSPORT_DATA: "Datos de transporte",
      TYPE_VEHICLE_USED: "Tipo de vehículo utilizado",
      WEIGHT_TRANSPORT_LOAD: "Peso de la carga transportada",
      DISTANCE_TRAVELED_IN_TRANSPORT: "Distancia recorrida en el transporte ",
      UNIT: "Unidad",
      DISTANCE_UNIT: "Unidad de distancia",
      WEIGHT_UNIT: "Unidad de peso",
      TOOLTIPS: {
        TRANSPORT_DATA: `
          Indique la cantidad de energía utilizada por el transporte desde la explotación agrícola o el traslado de productos terminados hacia un punto de venta. El transporte incluye la entrega o eliminación de materiales, incluso si no se utilizan vehículos propios para el transporte de los productos.
        `,
      },
      ALERTS: {
        SELECT_TYPE_VEHICLE_USED:
          "SELECCIONE EL TIPO DE VEHÍCULO UTILIZADO EN EL TRANSPORTE",
        ENTER_WEIGHT_IN_TONS: "Indique en número el peso transportado",
        ENTER_DISTANCE_IN_KILOMETERS:
          "Indique en número la distancia recorrida",
        ENTER_WEIGHT_UNIT: "INDIQUE LA UNIDAD DE PESO QUE SERA TRANSPORTADA",
        ENTER_DISTANCE_UNIT: "INDIQUE LA UNIDAD DE LA DISTANCIA RECORRIDA",
      },
    },
    RESIDUE_FORM: {
      ALERTS: {
        ONLY_TWO_DECIMALS: "El valor permite ingresar hasta 2 decimales",
        NUMBER_WASTE_GENERATED:
          "Ingrese en número la cantidad de residuo generado",
      },
    },
    SUMMARY: {
      GENERATE_REPORT: "Generar informe",
      GENERATING_REPORT: "Generando informe...",
      SUMMARY: "Resumen",
      SOIL: "Suelo",
      ENERGY: "Energía",
      WASTE_MANAGEMENT: "Manejo de residuos",
      LAND_MANAGEMENT: "Manejo de tierra",
      TRANSPORTATION: "Transporte",
      TREE_STAN: "Masa arbórea",
      MACHINERY: "Maquinarias",
      SUB_PROCESS: "Sub procesos",
      SUB_PRODUCTS: "Sub productos",
      STORAGE: "Almacenamiento",
    },
    VALIDATIONS: {
      ARE_YOU_SURE: "¿Estas seguro?",
      A_RECORD_THAT_IS_DELETED_CANNOT_BE_RECOVERED:
        "Un registro que se elimina no se puede recuperar",
      YES_DELETE: "Si, eliminar",
      CANCEL: "Cancelar",
    },
  },
};
