import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import validation from "../../commons/validation";

// Actions de Redux
import { showAlert, hiddenAlert, addNewEntity } from "../../store/actions";
import FormBody from "../form/formBody";
import FootButtonForm from "../form/footButtonForm";
import { treeBiomassEntity } from "../../commons/constants";
import { EUnitMeasureSystem } from "src/utils/unitMeasureSystemEnum";
import { useEffect } from "react";
import { useTranslate } from "src/hooks/useTranslate/index";
import { getTreeBiomassLstTHead } from "src/utils/getTreeBiomassListTHead";

const entity = treeBiomassEntity;

const unitsEquivalences = {
  [EUnitMeasureSystem.IMPERIAL]: {
    diameterUnits: {
      options: ["49", "50"],
      defaultValue: "49",
    },
    cantTrees: {
      options: ["55"],
      defaultValue: "55",
    },
  },
  [EUnitMeasureSystem.METRIC]: {
    diameterUnits: {
      options: ["3"],
      defaultValue: "3",
    },
    cantTrees: {
      options: ["54"],
      defaultValue: "54",
    },
  },
};

const TreeBiomassForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const headsToList = getTreeBiomassLstTHead(t);
  const entitiesToList = useSelector((state) => {
    return state.table.treeBiomass;
  });

  const selectsTreesState = useSelector((state) => {
    return state.selectSteps.trees;
  });

  const { unitMeasureSystem } = useSelector((state) => {
    return state.establishment;
  });

  const cftUnits = useSelector((state) => {
    return state.selectSteps.cftUnits;
  });

  const { treeTypes: treeTypeData } = selectsTreesState;

  let [treeType, saveTreeType] = useState(0);
  let [densityLastYear, saveDensityLastYear] = useState("");
  let [sizeLastYear, saveSizeLastYear] = useState("");
  let [sizeThisYear, saveSizeThisYear] = useState("");
  let [numTreesDeltaPlanted, setNumTreesDeltaPlanted] = useState(0);
  let [numTreesDeltaLost, setNumTreesDeltaLost] = useState(0);
  const [diameterUnit, setDiameterUnit] = useState("");
  const [densityUnit, setDensityUnit] = useState("");

  useEffect(() => {
    if (unitsEquivalences[unitMeasureSystem]) {
      setDiameterUnit(
        unitsEquivalences[unitMeasureSystem].diameterUnits.defaultValue
      );
      setDensityUnit(
        unitsEquivalences[unitMeasureSystem].cantTrees.defaultValue
      );
    }
  }, [unitMeasureSystem]);

  const optionsDiameter = cftUnits.filter((unit) =>
    unitsEquivalences[unitMeasureSystem].diameterUnits.options.includes(unit.id)
  );
  const optionsDensityUnit = cftUnits.filter((unit) =>
    unitsEquivalences[unitMeasureSystem].cantTrees.options.includes(unit.id)
  );

  const clearData = () => {
    saveTreeType(0);
    saveDensityLastYear("");
    saveSizeLastYear("");
    saveSizeThisYear("");
    setNumTreesDeltaPlanted(0);
    setNumTreesDeltaLost(0);
  };

  const submitNewData = (e) => {
    e.preventDefault();

    const dataToSend = {
      treeType: parseInt(treeType),
      densityLastYear: parseInt(densityLastYear),
      sizeLastYear: parseInt(sizeLastYear),
      sizeThisYear: parseInt(sizeThisYear),
      diameterUnit,
      numTreesDelta: numTreesDeltaPlanted - numTreesDeltaLost,
      densityUnit,
      entity,
      numTreesDeltaPlanted,
      numTreesDeltaLost,
    };

    const validationResponse = validation(dataToSend, t);
    if (validationResponse) {
      const alerta = {
        msg: validationResponse.msg,
        classes: "alert alert-danger text-center text-uppercase p3",
      };
      dispatch(showAlert(alerta));
      return;
    }

    dispatch(addNewEntity(dataToSend));

    dispatch(hiddenAlert());
    clearData();

    //ingresa la seccion anterior al historial
  };

  const formOption = {
    titleForm: t("TREE_FORM.TITLE"),
    functionSubmit: submitNewData,
    entity,
    toolTipOption: {
      msg: t("TREE_FORM.TOOLTIP_MESSAGE"),
      height: 150,
      width: 300,
    },
    dataToList: {
      items: entitiesToList,
      heads: headsToList,
    },
    InputOption: [
      {
        style: "col-md-12",
        titleForm: `${t("TREE_FORM.PREDOMINANT_TREE_CLASSIFICATION")} *`,
        inputType: "select",
        value: treeType,
        valueChanged: saveTreeType,
        dataToSelect: treeTypeData,
      },
      {
        style: "col-md-4",
        titleForm: `${t("TREE_FORM.DIAMETER_PREVIOUS_YEAR")} *`,
        inputType: "number",
        value: sizeLastYear,
        valueChanged: saveSizeLastYear,
        min: "0",
        max: "100000000000",
      },
      {
        style: "col-md-4",
        titleForm: `${t("TREE_FORM.DIAMETER_CURRENT_YEAR")} *`,
        inputType: "number",
        value: sizeThisYear,
        valueChanged: saveSizeThisYear,
        min: "0",
        max: "100000000000",
      },
      {
        style: "col-md-4",
        titleForm: `${t("TREE_FORM.UNIT_DIAMETER")} *`,
        lineBreak: true,
        inputType: "select",
        value: diameterUnit,
        valueChanged: setDiameterUnit,
        dataToSelect: optionsDiameter,
      },
      {
        style: "col-md-3",
        titleForm: `${t("TREE_FORM.TREES_PLANTED")} *`,
        inputType: "number",
        value: numTreesDeltaPlanted,
        valueChanged: setNumTreesDeltaPlanted,
        min: "0",
        max: "100000000000",
      },
      {
        style: "col-md-3",
        titleForm: `${t("TREE_FORM.TREES_LOST")} *`,
        inputType: "number",
        value: numTreesDeltaLost,
        valueChanged: setNumTreesDeltaLost,
        min: "0",
        max: "100000000000",
      },
      {
        style: "col-md-3",
        titleForm: `${t("TREE_FORM.DENSITY")} *`,
        inputType: "number",
        value: densityLastYear,
        valueChanged: saveDensityLastYear,
        min: "0",
        max: "100000000000",
      },
      {
        style: "col-md-3",
        titleForm: `${t("TREE_FORM.UNIT_DENSITY")} *`,
        inputType: "select",
        value: densityUnit,
        valueChanged: setDensityUnit,
        dataToSelect: optionsDensityUnit,
      },
    ],
  };

  return (
    <div className="row justify-content-center">
      <div className="col-md-12">
        <div className="stepwizard">
          <div className="stepwizard-row">
            <div className="stepwizard-step">
              <button
                type="button"
                className="btn btn-default btn-circle"
                disabled="disabled"
              >
                5
              </button>
              <p>{t("FORM_STEPS.LAND")}</p>
            </div>
            <div className="stepwizard-step">
              <button
                type="button"
                className="btn btn-default btn-circle"
                disabled="disabled"
              >
                6
              </button>
              <p>{t("FORM_STEPS.TRANSPORT")}</p>
            </div>
            <div className="stepwizard-step">
              <button
                type="button"
                className="btn btn-primary1 btn-circle"
                disabled="disabled"
              >
                7
              </button>
              <p>{t("FORM_STEPS.TREES")}</p>
            </div>
            <div className="stepwizard-step">
              <button
                type="button"
                className="btn btn-default btn-circle"
                disabled="disabled"
              >
                8
              </button>
              <p>{t("FORM_STEPS.SUMMARY")}</p>
            </div>
          </div>
        </div>

        <br></br>
        <br></br>
        <br></br>
        <FormBody option={formOption} />
      </div>
      <br></br>
      <div className="container">
        <br></br>
        <br></br>
        <br></br>
        <FootButtonForm prev={true} next={true} />
        <br></br>
      </div>
      <br></br>
      <br></br>
      <div className="container">
        <br></br>
        <br></br>
      </div>
      <br></br>
    </div>
  );
};

export default TreeBiomassForm;
