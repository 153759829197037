import { combineReducers } from "redux";
import alertaReducer from "./alertaReducer";
import establishmentReducer from "./establishmentReducer";
import stepReducer from "./stepReducer";
import landManagementReducer from "./landManagementReducer";
import tableReducer from "./tableReducer";
import loginReducer from "./loginReducer";
import { selectStepsReducer } from "../slices";

export default combineReducers({
  user: loginReducer,
  establishment: establishmentReducer,
  alerta: alertaReducer,
  step: stepReducer,
  landManagements: landManagementReducer,
  table: tableReducer,
  selectSteps: selectStepsReducer,
});
