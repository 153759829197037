import React from "react";

import { CTF_CARBON_BALANCE_URL } from "src/config/urls";

const Producto = ({ producto }) => {
  const { name, identifier } = producto;

  const showDetailEstablishment = (establishment) => {
    window.open(`${CTF_CARBON_BALANCE_URL}/${establishment.id}`);
  };

  return (
    <tr>
      <td style={{ textAlign: "center", width: "200px" }}>{name}</td>
      <td style={{ textAlign: "center", width: "200px" }}>
        <span className="font-weight-bold"> {identifier} </span>
      </td>
      <td className="acciones" style={{ Align: "center", width: "200px" }}>
        <div style={{ display: "inline-flex" }}>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => showDetailEstablishment(producto)}
            title="Ver informe"
          >
            <i className="fas fa-eye"></i>
          </button>
        </div>
      </td>
    </tr>
  );
};

export default Producto;
