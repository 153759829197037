import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import validation from "../../commons/validation";

// Actions de Redux
import { showAlert, hiddenAlert, addNewEntity } from "../../store/actions";
import FormBody from "../form/formBody";
import FootButtonForm from "../form/footButtonForm";
import { getPreloadedSoilData } from "src/services/getPreloadSoilData";
import { preloadDataToFormMapper } from "src/utils/preloadDataToFormMapper";

import {
  transportsListHead as headsToList,
  transportsEntity as entity,
} from "../../commons/constants";
import { EUnitMeasureSystem } from "src/utils/unitMeasureSystemEnum";
import { useTranslate } from "src/hooks/useTranslate/index";

const distanceAndWeightUnitsByUnitMeasureSystem = {
  [EUnitMeasureSystem.IMPERIAL]: {
    weightUnits: {
      options: ["27", "25"],
      defaultUnit: "lb",
    },
    distanceUnits: {
      options: ["4"],
      defaultUnit: "mi",
    },
  },
  [EUnitMeasureSystem.METRIC]: {
    weightUnits: {
      options: ["9", "7"],
      defaultUnit: "tonne",
    },
    distanceUnits: {
      options: ["2"],
      defaultUnit: "km",
    },
  },
};

const SubProcessForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const { unitMeasureSystem } = useSelector((state) => {
    return state.establishment;
  });
  const entitiesToList = useSelector((state) => {
    return state.table.transports;
  });

  const { transport: selectsFloorState, cftUnits } = useSelector((state) => {
    return state.selectSteps;
  });

  const { ucropitCropId, ucropitEstablishmentId, identifier } = useSelector(
    (state) => {
      return state.table;
    }
  );

  const { transportTypes: transportTypeData } = selectsFloorState;

  let [mode, saveMode] = useState(0);
  let [distance, saveDistance] = useState("");
  let [distanceUnit, saveDistanceUnit] = useState("");
  let [distanceUnitOptions, setDistanceUnitOptions] = useState([]);
  let [weight, saveWeight] = useState("");
  let [weightUnit, saveWeightUnit] = useState("");
  let [weightUnitOptions, setWeightUnitOptions] = useState([]);

  const fetchPreloadData = useCallback(async () => {
    try {
      const response = await getPreloadedSoilData(ucropitEstablishmentId);

      const { dataToSendTransport } = preloadDataToFormMapper(
        response,
        ucropitCropId
      );

      return dataToSendTransport;
    } catch (err) {
      console.log(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ucropitEstablishmentId, identifier, ucropitCropId]);

  const fetchInitData = useCallback(async () => {
    if (entitiesToList.length) {
      return;
    }

    const transportData = await fetchPreloadData();

    if (!transportData) {
      return;
    }

    saveMode(transportData.modeId);
    saveDistance(transportData.distance);
  }, [entitiesToList, fetchPreloadData]);

  useEffect(() => {
    fetchInitData();
  }, [fetchInitData]);

  useEffect(() => {
    const distanceAndWeightUnits =
      distanceAndWeightUnitsByUnitMeasureSystem[unitMeasureSystem];
    const { weightUnits, distanceUnits } = distanceAndWeightUnits;

    const weightUnitOptions = cftUnits.filter(({ id }) =>
      weightUnits.options.includes(id)
    );

    const distanceUnitOptions = cftUnits.filter(({ id }) =>
      distanceUnits.options.includes(id)
    );

    setDistanceUnitOptions(distanceUnitOptions);
    saveDistanceUnit(distanceUnits.defaultUnit);
    setWeightUnitOptions(weightUnitOptions);
    saveWeightUnit(weightUnits.defaultUnit);
  }, [unitMeasureSystem, cftUnits]);

  const clearData = () => {
    saveMode(0);
    saveDistance("");
    saveWeight("");
  };

  const submitNewData = (e) => {
    e.preventDefault();

    const dataToSend = {
      mode: parseInt(mode),
      distance: parseInt(distance),
      distanceUnit,
      weight: parseInt(weight),
      weightUnit,
      entity,
      type: 0,
    };

    const validationResponse = validation(dataToSend, t);
    if (validationResponse) {
      const alerta = {
        msg: validationResponse.msg,
        classes: "alert alert-danger text-center text-uppercase p3",
      };
      dispatch(showAlert(alerta));
      return;
    }

    dispatch(addNewEntity(dataToSend));

    dispatch(hiddenAlert());
    clearData();
  };

  const formOption = {
    titleForm: t("TRANSPORT_FORM.TRANSPORT_DATA"),
    functionSubmit: submitNewData,
    entity,
    toolTipOption: {
      msg: t("TRANSPORT_FORM.TOOLTIPS.TRANSPORT_DATA"),
      height: 350,
      width: 700,
    },
    dataToList: {
      items: entitiesToList,
      heads: headsToList,
    },
    InputOption: [
      {
        style: "col-md-12",
        titleForm: `${t("TRANSPORT_FORM.TYPE_VEHICLE_USED")} *`,
        inputType: "select",
        value: mode,
        valueChanged: saveMode,
        dataToSelect: transportTypeData,
      },
      {
        style: "col-md-6",
        titleForm: `${t("TRANSPORT_FORM.WEIGHT_TRANSPORT_LOAD")} *`,
        inputType: "number",
        value: weight,
        valueChanged: saveWeight,
        min: "0",
        max: "10000000",
      },
      {
        style: "col-md-6",
        titleForm: `${t("TRANSPORT_FORM.WEIGHT_UNIT")} *`,
        inputType: "select",
        value: weightUnit,
        valueChanged: saveWeightUnit,
        dataToSelect: weightUnitOptions.map((item) => ({
          ...item,
          id: item.key,
        })),
      },
      {
        style: "col-md-6",
        titleForm: `${t("TRANSPORT_FORM.DISTANCE_TRAVELED_IN_TRANSPORT")} *`,
        inputType: "number",
        value: distance,
        valueChanged: saveDistance,
        min: "0",
        max: "100000000000",
      },
      {
        style: "col-md-6",
        titleForm: `${t("TRANSPORT_FORM.DISTANCE_UNIT")} *`,
        inputType: "select",
        value: distanceUnit,
        valueChanged: saveDistanceUnit,
        dataToSelect: distanceUnitOptions.map((item) => ({
          ...item,
          id: item.key,
        })),
      },
    ],
  };

  return (
    <div className="row justify-content-center">
      <div className="col-md-12">
        <div className="stepwizard">
          <div className="stepwizard-row">
            <div className="stepwizard-step">
              <button
                type="button"
                className="btn btn-default btn-circle"
                disabled="disabled"
              >
                5
              </button>
              <p>{t("FORM_STEPS.LAND")}</p>
            </div>
            <div className="stepwizard-step">
              <button
                type="button"
                className="btn btn-primary1 btn-circle"
                disabled="disabled"
              >
                6
              </button>
              <p>{t("FORM_STEPS.TRANSPORT")}</p>
            </div>
            <div className="stepwizard-step">
              <button
                type="button"
                className="btn btn-default btn-circle"
                disabled="disabled"
              >
                7
              </button>
              <p>{t("FORM_STEPS.TREES")}</p>
            </div>
            <div className="stepwizard-step">
              <button
                type="button"
                className="btn btn-default btn-circle"
                disabled="disabled"
              >
                8
              </button>
              <p>{t("FORM_STEPS.SUMMARY")}</p>
            </div>
          </div>
        </div>

        <br></br>
        <br></br>
        <br></br>
        <FormBody option={formOption} />
      </div>
      <br></br>
      <div className="container">
        <br></br>
        <br></br>
        <br></br>
        <FootButtonForm prev={true} next={true} />
        <br></br>
      </div>
      <br></br>
      <br></br>
      <div className="container">
        <br></br>
        <br></br>
      </div>
      <br></br>
    </div>
  );
};

export default SubProcessForm;
