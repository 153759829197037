import { useTranslate } from "src/hooks/useTranslate/index";
import TooltipsForm from "./tooltipsForm";
import { formOptions } from "src/modules/common/constants/formOptions";

const FormImput = (data) => {
  const { t } = useTranslate();
  let {
    inputType,
    titleForm,
    value,
    valueChanged,
    placeholder,
    dataToSelect,
    min,
    max,
    toolTipOption,
    disabled,
    ref,
    returnIndex = false,
    lineBreak = false,
    step = "any",
    accepNegative = false,
    name,
    propRenderSelect,
  } = data.option;
  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  const renderInput = () => {
    switch (inputType) {
      case formOptions.SELECT:
        return (
          <select
            type="number"
            className="form-control"
            placeholder={placeholder}
            name="data"
            value={value}
            onChange={(e) => valueChanged(e.target.value, name)}
            disabled={disabled}
            ref={ref}
          >
            <option value="">{t("COMMON.UNSELECTED")}</option>
            {dataToSelect?.map((data, key) => {
              return (
                <option
                  key={`option_${key}`}
                  value={returnIndex ? key : data.id}
                >
                  {data.value}
                </option>
              );
            })}
          </select>
        );
      case formOptions.NUMBER:
        return (
          <input
            type="number"
            min={min}
            max={max}
            step={step}
            // onKeyDown="return event.keyCode !== 8"
            className="form-control"
            placeholder={placeholder}
            name="Numero"
            onKeyDown={blockInvalidChar}
            value={value}
            onChange={({ target: { value } }) => {
              let valueToSet = value;
              if (!accepNegative && valueToSet < 0) valueToSet = 0;
              valueChanged(valueToSet, name);
            }}
            autoComplete="off"
            disabled={disabled}
            ref={ref}
          />
        );
      case formOptions.TEXT:
        return (
          <input
            type="text"
            className="form-control"
            placeholder={placeholder}
            name="Name"
            value={value}
            onChange={(e) => valueChanged(e.target.value, name)}
            disabled={disabled}
            ref={ref}
          />
        );
      case formOptions.DISABLED:
        return (
          <input
            type="text"
            className="form-control"
            placeholder={placeholder}
            name="Name"
            disabled="disabled"
            ref={ref}
          />
        );
      case formOptions.SELECT_WITH_FULL_DATA:
        return propRenderSelect ? (
          <select
            type="number"
            className="form-control"
            placeholder={placeholder}
            name="data"
            // value={value}
            onChange={(e) =>
              valueChanged(
                e.target.value,
                name,
                formOptions.SELECT_WITH_FULL_DATA
              )
            }
            disabled={disabled}
            ref={ref}
          >
            <option value="">{t("COMMON.UNSELECTED")}</option>
            {dataToSelect?.map((data, key) => {
              return (
                <option key={`option_${key}`} value={key}>
                  {data[propRenderSelect]}
                </option>
              );
            })}
          </select>
        ) : null;
      default:
        break;
    }
  };

  return (
    <div className="form-group">
      <label>
        {titleForm}
        {lineBreak ? <br /> : null}
        &nbsp;
        {toolTipOption?.msg ? (
          <TooltipsForm
            msg={toolTipOption.msg}
            height={toolTipOption.height}
            width={toolTipOption.width}
            sizeIcon={toolTipOption.sizeIcon}
            bsPrefix={toolTipOption?.bsPrefix}
            placement={toolTipOption?.placement}
          ></TooltipsForm>
        ) : null}
      </label>
      {renderInput()}
    </div>
  );
};

export default FormImput;
