import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { clienteAxiosCFT } from "src/config/axios";
import { getEstablishmentAction } from "src/store/actions/productoActions";

const useIdentifierAvailable = () => {
  const dispatch = useDispatch();
  const establishments = useSelector((state) => {
    return state.establishment.establishment;
  });
  const user = useSelector((state) => {
    return state.user;
  });
  const [identifierAvailable, setIdentifierAvailable] = useState([]);
  const [identifiersSelected, setIdentifiersSelected] = useState([]);
  const [canViewSelectIdentifier, setCanViewSelectIdentifier] = useState(false);

  const fetchEstablishments = (identifiers) => {
    if (identifiers) {
      const urlParams = queryString.stringify({ identifiers });
      dispatch(getEstablishmentAction(urlParams));
    } else {
      dispatch(getEstablishmentAction());
    }
  };

  useEffect(() => {
    const getIdentifierAvailable = async () => {
      const establishments = await clienteAxiosCFT.get("/establishments");
      const identifiers = getIdentifiers(establishments.data);
      setIdentifierAvailable(identifiers);
    };

    if (establishments.length) {
      getIdentifierAvailable();
    }
  }, [establishments]);

  useEffect(() => {
    const { taxId } = user;
    if (!taxId) {
      setCanViewSelectIdentifier(true);
    }
    fetchEstablishments(taxId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getIdentifiers = (data) => {
    const result = data.reduce((acc, currentValue) => {
      const existingIdentifier = acc.findIndex(
        (item) => item.value === currentValue.identifier
      );
      if (existingIdentifier === -1) {
        acc.push({
          label: currentValue.identifier,
          value: currentValue.identifier,
        });
      }
      return acc;
    }, []);
    return result;
  };

  const findEstablishmentByIdentifier = () => {
    const identifiers = identifiersSelected.map(
      (optionIdentifier) => optionIdentifier.value
    );
    fetchEstablishments(identifiers);
  };

  return {
    identifierAvailable,
    identifiersSelected,
    setIdentifiersSelected,
    findEstablishmentByIdentifier,
    canViewSelectIdentifier,
  };
};

export default useIdentifierAvailable;
