import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { isLoginAction } from "./store/actions";

import Header from "./components/Header";
import Establishment from "./components/establishment";
import InitForm from "./components/form/initForm";
import Summary from "./components/summary";
import LoginPage from "./components/login";
import "./translate";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

const App = () => {
  const dispatch = useDispatch();
  dispatch(isLoginAction());

  const token = useSelector((state) => {
    return state.user.token;
  });

  // A wrapper for <Route> that redirects to the login
  // screen if you're not yet authenticated.
  function PrivateRoute({ children, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          token ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  }

  return (
    <Router>
      <Header>
        <link
          rel="icon"
          type="image/x-icon"
          href="https://ucropit-work-orders.s3.amazonaws.com/images/favicon.ico"
        ></link>
      </Header>

      <div className="container mt-5">
        <Switch>
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/form" component={InitForm} />
          <PrivateRoute exact path="/">
            <Establishment />
          </PrivateRoute>
          <PrivateRoute exact path="/establishment/">
            <InitForm />
          </PrivateRoute>
          <PrivateRoute exact path="/establishment/:id">
            <Summary />
          </PrivateRoute>
        </Switch>
      </div>
    </Router>
  );
};

export default App;
