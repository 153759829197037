import { EUnitMeasureSystem } from "./unitMeasureSystemEnum";

/**
 * @param {import('utils/types.js').SelectsFromApi} selects
 */
export const mapSelectApiToRedux = (
  selects,
  unitMeasureSystem = EUnitMeasureSystem.METRIC,
  cropType
) => {
  const floor = mapSelectData(selects.floor, "description");
  const energy = mapDataEnergy(selects.energy);
  const land = mapDataLand(selects, cropType);
  const irrigation = mapSelectData(selects.irrigation, "description");
  const transport = mapSelectData(
    selects.transport,
    "description",
    unitMeasureSystem
  );
  const trees = mapSelectData(selects.trees, "description");
  const cftUnits = mapDataCftUnits(selects.cftUnits);

  return {
    floor,
    energy,
    land,
    irrigation,
    transport,
    trees,
    cftUnits,
  };
};

/**
 *
 * @param {object[]} array
 */
const mapData = (array, name, unitMeasureSystem) => {
  let elements = array;

  if (unitMeasureSystem) {
    elements = elements.filter(
      (item) => item?.unitMeasureSystem === unitMeasureSystem
    );
  }

  return elements.map((item) => ({
    id: item?.id ?? item.cftId ?? item._id,
    value: item[name] || item.name?.es || item.name,
  }));
};

/**
 *
 * @param {object} data
 * @param {string} name
 * @returns
 */
const mapSelectData = (data, name, unitMeasureSystem) => {
  const response = {};

  Object.entries(data).forEach(([key, value]) => {
    response[key] = mapData(value, name, unitMeasureSystem);
  });

  return response;
};

/**
 *
 * @param {object} energy
 * @param {object[]} energy.energyCategories
 * @param {object[]} energy.energySources
 * @param {string} name
 * @returns
 */
const mapDataEnergy = (energy) => {
  return {
    energyCategories: mapData(energy.energyCategories, "description"),
    energySources: mapData(energy.energySources, "description"),
  };
};

/**
 *
 * @param {object} land
 * @param {object[]} land.residueManagements
 * @param {object[]} land.landManagements
 * @param {string} name
 * @returns
 */
const mapDataLand = ({ land, carbonInputs, tillages }, cropType) => {
  const { forestypes, landUses } = land;
  let landResidueManagements = land.residueManagements;

  if (cropType) {
    landResidueManagements = land.residueManagements.filter(
      (residue) =>
        residue.cropTypes.length === 0 ||
        residue.cropTypes.some(
          (crop) => crop.toLowerCase() === cropType.toLowerCase()
        )
    );
  }

  const residueManagements = mapData(landResidueManagements, "description");

  return {
    residueManagements,
    forestTypes: mapData(forestypes, "description"),
    landUses: landUses,
    carbonInputs: carbonInputs,
    tillages: tillages,
    landUsesData: landUses,
  };
};

/**
 *
 * @param {object} cftUnits
 * @param {string} id
 * @param {string} value
 * @returns
 */
const mapDataCftUnits = (cftUnits) => {
  return cftUnits.map((cftUnit) => ({
    id: String(cftUnit.id_cft),
    value: cftUnit.description || cftUnit.name?.es || cftUnit.name,
    key: cftUnit.key_cft,
  }));
};
