import React, { Fragment } from "react";
import Producto from "./Producto";

import { useSelector } from "react-redux";
import MultiSelect from "./form/multiSelect";
import useEstablishments from "src/hooks/useEstablishments";

const Establishment = () => {
  const {
    identifierAvailable,
    setIdentifiersSelected,
    findEstablishmentByIdentifier,
    canViewSelectIdentifier,
  } = useEstablishments();

  const establishments = useSelector((state) => {
    return state.establishment.establishment;
  });

  const error = useSelector((state) => state.establishment.error);
  const cargando = useSelector((state) => state.establishment.loading);

  return (
    <Fragment>
      <h2 className="text-center my-5">
        Listado de informes de huella de carbono
      </h2>

      {error ? (
        <p className="font-weight-bold alert alert-danger text-center mt-4">
          Hubo un error
        </p>
      ) : null}
      {cargando ? <p className="text-center">Cargando....</p> : null}
      {canViewSelectIdentifier && (
        <div>
          <div className="container">
            <div className="row">
              <div className="col-10">
                <MultiSelect
                  options={identifierAvailable}
                  setFieldValue={setIdentifiersSelected}
                />
              </div>
              <div className="col-2">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => findEstablishmentByIdentifier()}
                  title="Eliminar"
                >
                  <i className="fa fa-search" />
                </button>
              </div>
            </div>
          </div>
          <br />
        </div>
      )}
      <table className="table table-striped">
        <thead style={{ textAlign: "center" }}>
          <tr>
            <th scope="col">Campo</th>
            <th scope="col">CUIT</th>

            <th className="acciones" scope="">
              Acciones
            </th>
          </tr>
        </thead>
        <tbody>
          {establishments.length === 0 ? (
            <tr>
              <th scope="col">{process.env.API_CFT}</th>
            </tr>
          ) : (
            establishments.map((producto) => (
              <Producto key={producto.id} producto={producto} />
            ))
          )}
        </tbody>
      </table>
    </Fragment>
  );
};

export default Establishment;
