import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showAlert, hiddenAlert, addNewEntity } from "../../store/actions";
import { energyListHead, energyEntity } from "../../commons/constants";
import validation from "../../commons/validation";
import FootButtonForm from "../form/footButtonForm";
import FormBody from "../form/formBody";
import { EUnitMeasureSystem } from "src/utils/unitMeasureSystemEnum";
import { useTranslate } from "src/hooks/useTranslate/index";

const usageValueUnitsEquivalences = {
  [EUnitMeasureSystem.IMPERIAL]: [
    {
      powerSourceTypes: [
        "102",
        "103",
        "104",
        "105",
        "114",
        "115",
        "1220",
        "1221",
      ],
      usageValueUnits: ["40", "32", "15"],
      defaultUnit: "40",
    },
    {
      powerSourceTypes: ["106"],
      usageValueUnits: ["35", "39", "37", "36"],
      defaultUnit: "36",
    },
  ],
  [EUnitMeasureSystem.METRIC]: [
    {
      powerSourceTypes: [
        "102",
        "103",
        "104",
        "105",
        "114",
        "115",
        "1220",
        "1221",
      ],
      usageValueUnits: ["15", "32"],
      defaultUnit: "15",
    },
    {
      powerSourceTypes: ["106"],
      usageValueUnits: ["36", "35", "39"],
      defaultUnit: "36",
    },
  ],
};

const EnergiesForm = () => {
  const screen = window.screen;
  const { t } = useTranslate();
  const { energy: selectsEnergyState, cftUnits } = useSelector((state) => {
    return state.selectSteps;
  });

  const {
    energyCategories: categoryEnergies,
    energySources: sourceEnergyCategories,
  } = selectsEnergyState;

  const energies = useSelector((state) => {
    return state.table.energies;
  });

  const { unitMeasureSystem } = useSelector((state) => {
    return state.establishment;
  });

  let [category, saveCategory] = useState(0);
  let [source, saveSource] = useState(0);
  let [quantity, saveQuantity] = useState();
  let [usageValueUnit, setUsageValueUnit] = useState(0);

  let [usageValueUnitOptions, setUsageValueUnitOptions] = useState([]);

  const dispatch = useDispatch();

  const clearData = () => {
    saveCategory(0);
    saveSource(0);
    saveQuantity("");
    setUsageValueUnit(0);
  };

  const submitNewEnergies = (e) => {
    e.preventDefault();

    const energyToSend = {
      category: parseInt(category),
      source: parseInt(source),
      usageValue: parseInt(quantity),
      usageValueUnit: parseInt(usageValueUnit),
      entity: energyEntity,
    };

    const validationResponse = validation(energyToSend, t);

    if (validationResponse) {
      const alerta = {
        msg: validationResponse.msg,
        classes: "alert alert-danger text-center text-uppercase p3",
      };
      dispatch(showAlert(alerta));
      return;
    }

    dispatch(addNewEntity(energyToSend));

    dispatch(hiddenAlert());

    clearData();
  };

  useEffect(() => {
    buildUsageValueUnits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source]);

  const buildUsageValueUnits = () => {
    if (!source) {
      return;
    }

    const usageValueUnitEquivalence = usageValueUnitsEquivalences[
      unitMeasureSystem
    ].find(({ powerSourceTypes }) => powerSourceTypes.includes(source));

    const usageValueUnitOptions = cftUnits.filter(({ id }) =>
      usageValueUnitEquivalence.usageValueUnits.includes(id)
    );

    const defaultUnit = usageValueUnitOptions.find(
      ({ id }) => id === usageValueUnitEquivalence.defaultUnit
    ).id;

    setUsageValueUnitOptions(usageValueUnitOptions);

    setUsageValueUnit(defaultUnit);
  };

  const formOption = {
    titleForm: t("ENERGIES_FORM.ENERGY_DATA"),
    functionSubmit: submitNewEnergies,
    toolTipOption: {
      msg: t("ENERGIES_FORM.TOOLTIPS.ENERGY_DATA"),
      height: screen.width > 1360 && screen.width < 1900 ? "75%" : "8%",
      width: screen.width < 1280 ? "15%" : "50%",
    },
    entity: energyEntity,
    dataToList: {
      items: energies,
      heads: energyListHead,
    },
    InputOption: [
      {
        style: "col-md-6",
        titleForm: `${t("ENERGIES_FORM.ENERGY_USE_CATEGORY")} *`,
        inputType: "select",
        value: category,
        valueChanged: saveCategory,
        dataToSelect: categoryEnergies,
      },
      {
        style: "col-md-6",
        titleForm: `${t("ENERGIES_FORM.ENERGY_SOURCE_CATEGORY")} *`,
        inputType: "select",
        value: source,
        valueChanged: saveSource,
        dataToSelect: sourceEnergyCategories,
      },
      {
        style: "col-md-6",
        titleForm: `${t("ENERGIES_FORM.FUEL_USED")} *`,
        inputType: "number",
        value: quantity,
        valueChanged: saveQuantity,
        min: "0",
        max: "100000000000",
      },
      {
        style: "col-md-6",
        titleForm: `${t("ENERGIES_FORM.FUEL_UNIT")} *`,
        inputType: !source ? "disabled" : "select",
        value: usageValueUnit,
        valueChanged: setUsageValueUnit,
        dataToSelect: usageValueUnitOptions,
      },
    ],
  };

  return (
    <div className="row justify-content-center">
      <div className="col-md-12">
        <div className="stepwizard">
          <div className="stepwizard-row">
            <div className="stepwizard-step">
              <button
                type="button"
                className="btn btn-default btn-circle"
                disabled="disabled"
              >
                2
              </button>
              <p>{t("FORM_STEPS.SOIL")}</p>
            </div>
            <div className="stepwizard-step">
              <button
                type="button"
                className="btn btn-default btn-circle"
                disabled="disabled"
              >
                3
              </button>
              <p>{t("FORM_STEPS.WASTE")}</p>
            </div>
            <div className="stepwizard-step">
              <button
                type="button"
                className="btn btn-primary1 btn-circle"
                disabled="disabled"
              >
                4
              </button>
              <p>{t("FORM_STEPS.ENERGY")}</p>
            </div>

            <div className="stepwizard-step">
              <button
                type="button"
                className="btn btn-default btn-circle"
                disabled="disabled"
              >
                5
              </button>
              <p>{t("FORM_STEPS.LAND")}</p>
            </div>
            <div className="stepwizard-step">
              <button
                type="button"
                className="btn btn-default btn-circle"
                disabled="disabled"
              >
                6
              </button>
              <p>{t("FORM_STEPS.TRANSPORT")}</p>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
        <br></br>
        <FormBody option={formOption} />
      </div>
      <br></br>
      <div className="container">
        <br></br>
        <br></br>
        <br></br>
        <FootButtonForm prev={true} next={true} />
        <br></br>
      </div>
      <br></br>
      <br></br>
      <div className="container">
        <br></br>
        <br></br>
      </div>
      <br></br>
    </div>
  );
};

export default EnergiesForm;
