import {
    NEXT_STEP,
    BACK_STEP
} from '../types';

const initialState = {
    position: 0
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    switch(action.type) {
        case NEXT_STEP:
            return {
                ...state,
                position: action.payload
            }
        case BACK_STEP:
            return {
                ...state,
                position: null
            }
        default:
            return state;
    }
}