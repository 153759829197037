import { useDispatch, useSelector } from "react-redux";
import { hiddenAlert, sumStep } from "../../store/actions";
import { useTranslate } from "src/hooks/useTranslate/index";

const FootButtonForm = ({
  prev,
  next = true,
  onValidationPrevSubmit = null,
}) => {
  const { t } = useTranslate();

  const dispatch = useDispatch();
  const step = useSelector((state) => {
    return state.step.position;
  });

  const nextStep = (e) => {
    if (
      typeof onValidationPrevSubmit === "function" &&
      !onValidationPrevSubmit()
    ) {
      return;
    }
    e.preventDefault();
    let position = step + 1;
    dispatch(sumStep(position));
    dispatch(hiddenAlert());
  };

  const prevStep = (e) => {
    e.preventDefault();
    let position = step - 1;
    dispatch(sumStep(position));
    dispatch(hiddenAlert());
  };

  return (
    <div className="containter orientacion">
      <div className="row">
        <button
          onClick={prevStep}
          className="btn btn-primary font-weight-bold next"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-chevron-left"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>{" "}
          {t("COMMON.BACK")}
        </button>

        <button
          onClick={nextStep}
          className="btn btn-primary font-weight-bold next"
          disabled={!next}
        >
          {t("COMMON.NEXT")}{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-chevron-right"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default FootButtonForm;
