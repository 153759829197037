import React, { useState } from "react";
import { Link } from "react-router-dom";
import logoUcropitDark from "../images/logoUcropitDark.svg";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../store/actions";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "src/hooks/useQuery";
import { useTranslate } from "src/hooks/useTranslate/index";

const Header = () => {
  const { t } = useTranslate();

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const fromWebView = query.get("fromWebView");

  const [showHeader] = useState(!fromWebView);

  const userToken = useSelector((state) => state.user?.token);

  const submitLogout = () => {
    dispatch(logoutAction());

    history.push("/");
  };

  const imgUcropit = <img src={logoUcropitDark} alt="" />;

  if (!showHeader) {
    return null;
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-primary1">
      <div className="container">
        <h1>
          <Link to={"/"} className="text-light">
            {imgUcropit}
          </Link>
        </h1>
      </div>

      {userToken ? (
        <div>
          {location.pathname === "/" ? (
            <Link
              to={"/form"}
              className="btn btn-danger nuevo-post d-block d-md-inline-block"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                fill="currentColor"
                className="bi bi-plus"
                viewBox="0 0 16 16"
              >
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
              </svg>
              {t("COMMON.GENERATE_NEW_REPORT")}
            </Link>
          ) : null}

          <button
            type="button"
            onClick={submitLogout}
            className="btn btn-danger "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              fill="currentColor"
              className="bi bi-box-arrow-in-right"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"
              />
              <path
                fillRule="evenodd"
                d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
              />
            </svg>
            {t("COMMON.LOG_OUT")}
          </button>
        </div>
      ) : null}
    </nav>
  );
};

export default Header;
