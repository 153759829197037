import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import EstablishmentForm from "../establishment/establishmentForm";
import TransportForm from "../transport/transportForm";
import TreeBiomassForm from "../treeBiomas/treeBiomasForm";
import FinishForm from "./finishForm";
import ResidueDataForm from "../residueData/residueDataForm";
import EnergiesForm from "../energy/energiesForm";
import CropForm from "../crop/cropsForm";
import { useQuery } from "../../hooks";
import Loader from "../commons/loader";
import { loginAction, logout } from "src/store/actions/loginActions";
import { STORAGE_TAX_ID_KEY } from "src/commons/constants";
import { getSessionStorage, removeItemSessionStorage } from "src/utils/index";
import { useTranslate } from "src/hooks/useTranslate/index";
import LandForm from "src/modules/land/pages/LandForm/index";

const USER_AND_PASSWORK_LENGTH = 2;

const InitForm = () => {
  const { t } = useTranslate();

  const step = useSelector((state) => {
    return state.step.position;
  });
  const login = useSelector((state) => {
    const { loading, token, error, taxId } = state.user;
    return {
      loading,
      token,
      error,
      taxId,
    };
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();

  const formSteps = [
    <EstablishmentForm />,
    <CropForm />,
    <ResidueDataForm />,
    <EnergiesForm />,
    <LandForm />,
    <TransportForm />,
    <TreeBiomassForm />,
    <FinishForm />,
  ];

  const decodeFormTokenAndLogin = () => {
    const formToken = query.get("formToken");
    const taxId = query.get("taxId");

    if (!formToken) {
      history.push("/login");
    } else {
      const decoded = window.atob(formToken);
      const data = decoded.split("&").map((d) => d.split("="));

      if (data.length < USER_AND_PASSWORK_LENGTH) {
        removeItemSessionStorage("token");
        dispatch(logout());
        history.push("/");

        return;
      }

      const userName = decodeURIComponent(data[0][1]);
      const password = data[1][1];
      const dataToLogin = {
        email: userName,
        password,
        taxId,
      };

      dispatch(loginAction({ data: dataToLogin, t }));
    }
  };

  React.useEffect(() => {
    const taxId = query.get("taxId");

    if (
      !login.token ||
      (taxId && taxId !== getSessionStorage(STORAGE_TAX_ID_KEY))
    ) {
      decodeFormTokenAndLogin();
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const { error } = login;

    error && history.push("/login");
    // eslint-disable-next-line
  }, [login]);

  if (login.loading) {
    return <Loader />;
  } else {
    return formSteps[step];
  }
};

export default InitForm;
