import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  sumStep,
  hiddenAlert,
  showAlert,
  addNewEntity,
} from "../../store/actions";

import {
  cropListHead,
  cropEntity,
  soilTextureResponseData,
  soilTextureNoData,
  soilDrainageResponseData,
  soilDrainageNoData,
} from "../../commons/constants";
import validation from "../../commons/validation";
import FormBody from "../form/formBody";

import { clienteAxiosForm, clienteAxiosTypeLand } from "../../config/axios";
import { getPreloadedSoilData } from "src/services/getPreloadSoilData";
import { preloadDataToFormMapper } from "src/utils/preloadDataToFormMapper";
import { useTranslate } from "src/hooks/useTranslate/index";

const CropForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslate();

  const crops = useSelector((state) => {
    return state.table.crops;
  });

  const {
    ucropitCropId,
    ucropitEstablishmentId,
    identifier,
    name: farmTag,
  } = useSelector((state) => {
    return state.table;
  });

  const step = useSelector((state) => {
    return state.step.position;
  });

  const selectsFloorState = useSelector((state) => {
    return state.selectSteps.floor;
  });

  const {
    soilOrganicMatters: organicMatterSoilData,
    phs: phSoilData,
    weathertypes: weatherData,
    soilDrainages: drainageSoilData,
    soilTextures: textureSoilData,
    soilCharacteristics: soilCharacteristicsData,
  } = selectsFloorState;

  const [soilMoistureMatterId, saveSoilMoistureMatterId] = useState(0);
  const [usageValue, saveUsageValue] = useState(0);
  const [soilPhId, saveSoilPhId] = useState(0);
  const [weatherId, setWeatherId] = useState(0);
  const [seedAmountValue, saveSeedAmountValue] = useState(0);
  const [soilTexture, saveSoilTexture] = useState();
  const [soilTextureInputType, setSoilTextureInputType] = useState("disabled");
  const [soilCharacteristic, saveSoilCharacteristic] = useState();
  const [soilDrainage, saveSoilDrainage] = useState();
  const [soilDrainageInputType, setSoilDrainageInputType] =
    useState("disabled");

  const fetchFarmGeometryData = useCallback(async () => {
    let data;

    try {
      const response = await clienteAxiosForm.get(`/crops/${identifier}`);

      data = response.data;
    } catch (error) {
      console.error(error);

      return {
        error,
      };
    }

    const crop = data.find(({ _id }) => _id === ucropitCropId);

    const geometryData = crop.lots.find(
      ({ tag }) => tag === farmTag
    )?.geometryData;

    return {
      geometryData,
    };
  }, [ucropitCropId, identifier, farmTag]);

  const fetchLand = useCallback(async ({ geometryData }) => {
    let data;

    try {
      const requestConfig = {
        dataGeometry: geometryData,
      };

      const response = await clienteAxiosTypeLand.post(
        "lands/wkt",
        requestConfig
      );

      data = response.data;
    } catch (error) {
      console.error(error);

      return {
        error,
      };
    }

    const land = data.sort(
      ({ area: currentArea }, { area: nextArea }) => nextArea - currentArea
    )[0];

    return {
      land,
    };
  }, []);

  const fetchPreloadSoilData = useCallback(async () => {
    try {
      if (crops.length) {
        return;
      }

      const response = await getPreloadedSoilData(ucropitEstablishmentId);

      const { dataToSendSoil } = preloadDataToFormMapper(
        response,
        ucropitCropId,
        usageValue,
        seedAmountValue
      );
      dispatch(addNewEntity(dataToSendSoil));
    } catch (err) {
      console.log(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ucropitEstablishmentId, identifier, ucropitCropId, crops]);

  const fetchInitData = useCallback(async () => {
    fetchPreloadSoilData();

    const { geometryData } = await fetchFarmGeometryData();

    if (!geometryData) {
      return;
    }

    const { land } = await fetchLand({ geometryData });

    if (
      !land ||
      soilTextureNoData.find(({ value }) => value === land.text_sups1)
    ) {
      setSoilTextureInputType("select");
    } else {
      const soilTextureResponseId = soilTextureResponseData.find(
        ({ value }) => value === land.text_sups1
      )?.id;

      if (soilTextureResponseId) {
        const result = textureSoilData.findIndex(
          ({ id }) => Number(id) === Number(soilTextureResponseId)
        );

        saveSoilTexture(result);
      } else {
        setSoilTextureInputType("select");
      }
    }

    if (
      !land ||
      soilDrainageNoData.find(({ value }) => value === land.drenaje_s1)
    ) {
      setSoilDrainageInputType("select");
    } else {
      const soilDrainageResponseId = soilDrainageResponseData.find(
        ({ value }) => value === land.drenaje_s1
      )?.id;

      if (soilDrainageResponseId) {
        const result = drainageSoilData.findIndex(
          ({ id }) => Number(id) === Number(soilDrainageResponseId)
        );

        saveSoilDrainage(result);
      } else {
        setSoilDrainageInputType("select");
      }
    }
  }, [
    drainageSoilData,
    fetchFarmGeometryData,
    fetchLand,
    textureSoilData,
    fetchPreloadSoilData,
  ]);

  useEffect(() => {
    fetchInitData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearData = () => {
    saveSoilMoistureMatterId(0);
    saveUsageValue(0);
    saveSoilPhId(0);
    setWeatherId(0);
    saveSoilCharacteristic(0);
    saveSeedAmountValue(0);

    if (soilTextureInputType === "select") {
      saveSoilTexture("");
    }

    if (soilDrainageInputType === "select") {
      saveSoilDrainage("");
    }
  };

  const nextStep = (e) => {
    if (!crops.length) {
      const alerta = {
        msg: t("CROP_FORM.ALERTS.DATA_REQUIRED"),
        classes: "alert alert-danger text-center text-uppercase p3",
      };
      dispatch(showAlert(alerta));
      return;
    }

    e.preventDefault();
    let position = step + 1;
    dispatch(sumStep(position));
  };

  const prevStep = (e) => {
    e.preventDefault();
    let position = step - 1;
    dispatch(sumStep(position));
  };

  const submitNewData = (e) => {
    e.preventDefault();

    if (crops.length) {
      const alerta = {
        msg: t("CROP_FORM.ALERTS.SENDING_MORE_THAN_ONE_CROP"),
        classes: "alert alert-danger text-center text-uppercase p3",
      };
      dispatch(showAlert(alerta));
      return;
    }

    const dataToSend = {
      soilMoistureMatterId: parseInt(soilMoistureMatterId),
      soilCharacteristic: parseInt(
        soilCharacteristicsData[soilCharacteristic]?.id
      ),
      soilPhId: parseInt(soilPhId),
      weatherId: parseInt(weatherId),
      soilDrainage: parseInt(drainageSoilData[soilDrainage]?.id),
      soilTexture: parseInt(textureSoilData[soilTexture]?.id),
      entity: cropEntity,
      ucropitCropId,
      usageValue: parseInt(usageValue),
      seedAmountValue: parseInt(seedAmountValue),
    };

    const validationResponse = validation(dataToSend, t);

    if (validationResponse) {
      const alerta = {
        msg: validationResponse.msg,
        classes: "alert alert-danger text-center text-uppercase p3",
      };

      dispatch(showAlert(alerta));

      return;
    }

    dispatch(addNewEntity(dataToSend));

    dispatch(hiddenAlert());

    clearData();
  };

  const formOption = {
    titleForm: t("CROP_FORM.ADD_SOIL_AND_WEATHER_DATA"),
    functionSubmit: submitNewData,
    entity: cropEntity,
    dataToList: {
      items: crops,
      heads: cropListHead,
    },
    InputOption: [
      {
        style: "col-md-6",
        titleForm: `${t("CROP_FORM.SOIL_ORGANIC_MATTER")} *`,
        inputType: "select",
        value: soilMoistureMatterId,
        valueChanged: saveSoilMoistureMatterId,
        dataToSelect: organicMatterSoilData,
      },
      {
        style: "col-md-6",
        titleForm: `${t("CROP_FORM.SOIL_CHARACTERISTIC")} *`,
        inputType: "select",
        placeholder: "Dato calculado",
        value: soilCharacteristic,
        valueChanged: saveSoilCharacteristic,
        dataToSelect: soilCharacteristicsData,
        returnIndex: true,
      },
      {
        style: "col-md-6",
        titleForm: `${t("CROP_FORM.SOIL_PH")} *`,
        inputType: "select",
        value: soilPhId,
        valueChanged: saveSoilPhId,
        dataToSelect: phSoilData,
      },
      {
        style: "col-md-6",
        titleForm: `${t("CROP_FORM.CLIMATE")} *`,
        inputType: "select",
        value: weatherId,
        valueChanged: setWeatherId,
        dataToSelect: weatherData,
      },
      {
        style: "col-md-6",
        titleForm: `${t("CROP_FORM.SOIL_DRAINAGE")} *`,
        inputType: soilDrainageInputType,
        placeholder: drainageSoilData[soilDrainage]?.value || "Dato calculado",
        value: soilDrainage,
        valueChanged: saveSoilDrainage,
        dataToSelect: drainageSoilData,
        returnIndex: true,
      },
      {
        style: "col-md-6",
        titleForm: t("CROP_FORM.SOIL_TEXTURE"),
        inputType: soilTextureInputType,
        placeholder: textureSoilData[soilTexture]?.value || "Dato calculado",
        value: soilTexture,
        valueChanged: saveSoilTexture,
        dataToSelect: textureSoilData,
        returnIndex: true,
      },
    ],
  };

  return (
    <div className="row justify-content-center">
      <div className="col-md-12">
        <div className="stepwizard">
          <div className="stepwizard-row">
            <div className="stepwizard-step">
              <button
                type="button"
                className="btn btn-default btn-circle"
                disabled="disabled"
              >
                1
              </button>
              <p>{t("FORM_STEPS.START")}</p>
            </div>
            <div className="stepwizard-step">
              <button type="button" className="btn btn-primary1 btn-circle">
                2
              </button>
              <p>{t("CROP_FORM.SOIL")}</p>
            </div>
            <div className="stepwizard-step">
              <button
                type="button"
                className="btn btn-default btn-circle"
                disabled="disabled"
              >
                3
              </button>
              <p>{t("FORM_STEPS.WASTE")}</p>
            </div>
            <div className="stepwizard-step">
              <button
                type="button"
                className="btn btn-default btn-circle"
                disabled="disabled"
              >
                4
              </button>
              <p>{t("ENERGIES_FORM.ENERGY")}</p>
            </div>

            <div className="stepwizard-step">
              <button
                type="button"
                className="btn btn-default btn-circle"
                disabled="disabled"
              >
                5
              </button>
              <p>{t("FORM_STEPS.LAND")}</p>
            </div>
          </div>
        </div>

        <br></br>
        <br></br>
        <br></br>
        <FormBody option={formOption} />
      </div>
      <br></br>
      <div className="container">
        <br></br>
        <br></br>
        <br></br>
        <div className="containter orientacion">
          <div className="row">
            <button
              onClick={prevStep}
              className="btn btn-primary font-weight-bold next"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-chevron-left"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                />
              </svg>{" "}
              {t("COMMON.BACK")}
            </button>

            <button
              onClick={nextStep}
              className="btn btn-primary font-weight-bold next"
            >
              {t("COMMON.NEXT")}{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-chevron-right"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </button>
          </div>
        </div>
        <br></br>
      </div>
      <br></br>
      <br></br>
      <div className="container">
        <br></br>
        <br></br>
      </div>
      <br></br>
    </div>
  );
};

export default CropForm;
