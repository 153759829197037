import {
    ADD_ENERGY,
    DEL_ENERGY
} from '../types';

import { v4 as uuidv4 } from 'uuid'

export function addNewEnergy(energy) {
    return async (dispatch) => {
        energy.id = uuidv4()
        dispatch( newEnergy(energy) )
    }
}

const newEnergy = energy => ({
    type: ADD_ENERGY,
    payload: energy
})


export function borrarEnergyAction(id) {
    return async (dispatch) => {
        dispatch(delEnergy(id) );
    }
}


const delEnergy = id => ({
    type: DEL_ENERGY,
    payload: id
})