import i18n from "i18next";
import languageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { en, es, pt } from "./locales";

const resources = {
  en,
  es,
  pt,
};

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    order: [
      "querystring",
      "localStorage",
      "navigator",
      "customDetector",
      "cookie",
      "htmlTag",
    ],
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
