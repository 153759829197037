import { clienteAxiosForm } from "../config/axios";

export const getSelects = async ({ language }) => {
  const config = {
    headers: {
      "Accept-Language": language,
    },
  };

  try {
    const response = await clienteAxiosForm.get("/common-data", config);

    /** @type {import("src/utils/types").SelectsFromApi} */
    const selects = response.data;

    return selects;
  } catch (err) {
    console.error(err);
  }
};
