import { ADD_ITEM_TABLE, CLEAR_ITEM_TABLE, DEL_ITEM_TABLE } from "../types";
import {
  coProductEntity,
  machinaryEntity,
  subProcessEntity,
  transportsEntity,
  treeBiomassEntity,
  energyEntity,
  cropEntity,
  establishmentEntity,
  storageEntity,
  landManagementsEntity,
  residueManagementEntity,
  landEntity,
} from "../../commons/constants";

const initialState = {
  name: "",
  ucropitEstablishmentId: "",
  ucropitCropId: "",
  identifier: "",
  products: [],
  machinaries: [],
  processes: [],
  transports: [],
  treeBiomass: [],
  energies: [],
  crops: [],
  storages: [],
  landManagements: [],
  residueManagements: [],
  land: [],
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_ITEM_TABLE:
      switch (payload.entity) {
        case energyEntity:
          return {
            ...state,
            energies: [...state.energies, action.payload],
          };
        case coProductEntity:
          return {
            ...state,
            products: [...state.products, action.payload],
          };
        case machinaryEntity:
          return {
            ...state,
            machinaries: [...state.machinaries, action.payload],
          };
        case subProcessEntity:
          return {
            ...state,
            processes: [...state.processes, action.payload],
          };
        case transportsEntity:
          return {
            ...state,
            transports: [...state.transports, action.payload],
          };
        case treeBiomassEntity:
          return {
            ...state,
            treeBiomass: [...state.treeBiomass, action.payload],
          };
        case cropEntity:
          return {
            ...state,
            crops: [...state.crops, action.payload],
          };
        case establishmentEntity:
          return {
            ...state,
            name: action.payload.name,
            ucropitEstablishmentId: action.payload.ucropitEstablishmentId,
            ucropitCropId: action.payload.ucropitCropId,
            identifier: action.payload.identifier,
          };
        case storageEntity:
          return {
            ...state,
            storages: [...state.storages, action.payload],
          };
        case landManagementsEntity:
          return {
            ...state,
            landManagements: [...state.landManagements, action.payload],
          };
        case residueManagementEntity:
          return {
            ...state,
            residueManagements: [...state.residueManagements, action.payload],
          };
        case landEntity:
          return {
            ...state,
            land: [...state.land, action.payload],
          };
        default:
          return state;
      }

    case DEL_ITEM_TABLE:
      switch (payload.entity) {
        case coProductEntity:
          return {
            ...state,
            products: state.products.filter(
              (entity) => entity.id !== action.payload.id
            ),
          };
        case machinaryEntity:
          return {
            ...state,
            machinaries: state.machinaries.filter(
              (entity) => entity.id !== action.payload.id
            ),
          };
        case subProcessEntity:
          return {
            ...state,
            processes: state.processes.filter(
              (entity) => entity.id !== action.payload.id
            ),
          };
        case transportsEntity:
          return {
            ...state,
            transports: state.transports.filter(
              (entity) => entity.id !== action.payload.id
            ),
          };
        case treeBiomassEntity:
          return {
            ...state,
            treeBiomass: state.treeBiomass.filter(
              (entity) => entity.id !== action.payload.id
            ),
          };
        case energyEntity:
          return {
            ...state,
            energies: state.energies.filter(
              (entity) => entity.id !== action.payload.id
            ),
          };
        case cropEntity:
          return {
            ...state,
            crops: state.crops.filter(
              (entity) => entity.id !== action.payload.id
            ),
          };
        case storageEntity:
          return {
            ...state,
            storages: state.storages.filter(
              (entity) => entity.id !== action.payload.id
            ),
          };
        case landManagementsEntity:
          return {
            ...state,
            landManagements: state.landManagements.filter(
              (entity) => entity.id !== action.payload.id
            ),
          };
        case residueManagementEntity:
          return {
            ...state,
            residueManagements: state.residueManagements.filter(
              (entity) => entity.id !== action.payload.id
            ),
          };
        case landEntity:
          return {
            ...state,
            land: state.land.filter(
              (entity) => entity.id !== action.payload.id
            ),
          };
        default:
          return state;
      }

    case CLEAR_ITEM_TABLE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
