import {
    NEXT_STEP,
    BACK_STEP
} from '../types';

export function sumStep(step) {
    return (dispatch) => {
        dispatch( nextStep(step) )
    }
}

const nextStep = step => ({
    type: NEXT_STEP,
    payload: step
})

export function restStep() {
    return (dispatch) => {
        dispatch(prevStep() )
    }
}

const prevStep = () => ({
    type: BACK_STEP
})