import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  loginAction,
  isLoginAction,
  showAlert,
  hiddenAlert,
} from "../store/actions";
import { regexEmail } from "../commons/constants";
import { useTranslate } from "src/hooks/useTranslate/index";

const loginValidation = ({ data, t }) => {
  const { email, password } = data;

  if (!email)
    return {
      msg: t("LOGIN.ALERTS.EMAIL_IS_REQUIRED"),
    };

  if (!regexEmail.test(email))
    return {
      msg: t("LOGIN.ALERTS.EMAIL_IS_INVALID"),
    };

  if (password.length <= 4 || password.length >= 9)
    return {
      msg: t("LOGIN.ALERTS.PASSWORD_INVALID_LENGTH"),
    };

  return null;
};

const LoginPage = () => {
  const { t } = useTranslate();

  const alerta = useSelector((state) => state.alerta.alerta);
  const login = useSelector((state) => {
    const { loading, token } = state.user;
    return {
      loading,
      token,
    };
  });
  const history = useHistory();
  const dispatch = useDispatch();
  let [email, saveEmail] = useState("");
  let [password, savePassword] = useState("");

  React.useEffect(() => {
    const { token } = login;
    if (token) {
      history.push("/");
    }
    // eslint-disable-next-line
  }, [login]);

  const submitNewData = (e) => {
    e.preventDefault();

    const dataToSend = {
      email,
      password,
    };

    const validation = loginValidation({ data: dataToSend, t });

    if (validation) {
      const alerta = {
        msg: validation.msg,
        classes: "alert alert-danger text-center text-uppercase p3",
      };
      dispatch(showAlert(alerta));
      return;
    }

    dispatch(loginAction({ data: dataToSend, t }));
    dispatch(isLoginAction());
    dispatch(hiddenAlert());
  };

  return (
    <div>
      {alerta ? <p className={alerta.classes}> {alerta.msg} </p> : null}
      <div className="col-md-6 col-md-offset-3">
        <h2>{t("LOGIN.TITLE")}</h2>
        <form name="form">
          <div className={"form-group"}>
            <label htmlFor="username">{t("LOGIN.FIELDS.EMAIL.LABEL")}</label>
            <input
              type="email"
              className="form-control"
              name="username"
              value={email}
              onChange={(e) => saveEmail(e.target.value)}
            />

            {
              <div className="help-block">
                {t("LOGIN.FIELDS.EMAIL.HELP_TEXT")}
              </div>
            }
          </div>
          <div className={"form-group"}>
            <label htmlFor="password">{t("LOGIN.FIELDS.PASSWORD.LABEL")}</label>
            <input
              type="password"
              className="form-control"
              minLength="6"
              maxLength="8"
              name="password"
              onChange={(e) => savePassword(e.target.value)}
            />

            {
              <div className="help-block">
                {t("LOGIN.FIELDS.PASSWORD.HELP_TEXT")}
              </div>
            }
          </div>
          <div className="form-group">
            <button className="btn btn-primary" onClick={submitNewData}>
              {t("LOGIN.BUTTON")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
