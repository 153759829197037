import { cropEntity, transportsEntity } from "src/commons/constants";

/**
 *
 * @param {import("./types").PreloadDataResponse} response
 * @param {string} ucropitCropId
 * @param {number} usageValue
 * @param {number} seedAmountValue
 * @returns {import("./types").preloadDataToFormMapperReturn}
 */
export const preloadDataToFormMapper = (
  response,
  ucropitCropId,
  usageValue = 0,
  seedAmountValue = 0
) => {
  const { soil, transport } = response;
  const dataToSendSoil = {
    soilMoistureMatterId: parseInt(soil.soilMoistureMatterId),
    soilCharacteristic: soil?.soilCharacteristicId
      ? parseInt(soil.soilCharacteristicId)
      : "",
    soilPhId: parseInt(soil.soilPhId),
    weatherId: parseInt(soil.weatherId),
    soilDrainage: parseInt(soil.soilDrainageId),
    soilTexture: parseInt(soil.soilTextureId),
    // TODO: RETURN THIS PROPERTY IN PRELOAD DATA ENDPOINT
    entity: cropEntity,
    ucropitCropId,
    usageValue: parseInt(usageValue),
    seedAmountValue: parseInt(seedAmountValue),
  };
  const dataToSendTransport = transport
    ? {
        distance: Number(transport.distance),
        modeDescription: transport.modeDescription,
        modeId: Number(transport.modeId),
        entity: transportsEntity,
      }
    : null;

  return {
    dataToSendSoil,
    dataToSendTransport,
  };
};
