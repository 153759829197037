export const en = {
  translation: {
    COMMON: {
      FIELDS: {
        DEFAULT_SELECT_LABEL: "Not selected",
      },
      ACTIONS: "Actions",
      UNSELECTED: "Unselected",
      GENERAL_ERROR: "There was an error",
      GENERAL_ERROR_TRY_AGAIN: "There was an error, try again",
      BACK: "Back",
      CORRECT: "Correct",
      ADVISE: "Advise",
      LOADING: "Loading...",
      ADD: "Add",
      NEXT: "Next",
      LOG_OUT: "Log out",
      NO_RECORDS: "No records",
      GENERATE_NEW_REPORT: "Generate new report",
    },
    MESSAGES: {
      STABLISHMENT_CREATED:
        "The establishment was added successfully, you want to download evidence",
    },
    FORM_STEPS: {
      SOIL: "Soil",
      ENERGY: "Energy",
      LAND: "Land",
      TRANSPORT: "Transport",
      TREES: "Trees",
      WASTE: "Waste",
      START: "Start",
      SUMMARY: "Summary",
      STORAGE: "Almacenamiento",
      MACHINERY: "Maquinarias",
      SUB_PROCESS: "Sub procesos",
      SUB_PRODUCTS: "Sub productos",
    },
    LOGIN: {
      TITLE: "Login",
      FIELDS: {
        EMAIL: {
          LABEL: "Email address",
          HELP_TEXT: "Email required",
        },
        PASSWORD: {
          LABEL: "Password",
          HELP_TEXT: "Password required",
        },
      },
      BUTTON: "Login",
      ALERTS: {
        EMAIL_IS_REQUIRED: "Please enter your email",
        EMAIL_IS_INVALID: "Enter a valid email",
        PASSWORD_INVALID_LENGTH:
          "Your password must contain between 6 and 8 characters",
        USER_IS_INVALID: "Invalid user",
        USER_OR_PASSWORD_IS_INVALID: "Invalid user or password",
      },
      MODALS: {
        WELCOME: {
          TITLE: "Welcome",
          SUCCESSFULLY_AUTH: "You are successfully logged in",
          BUTTON: "OK",
        },
      },
    },
    SELECT_FARM: {
      TITLE: "Generate new evidence",
      FIELDS: {
        IDENTIFIER: {
          LABEL: "TAX ID or farmer ID",
        },
        CROP_TYPE: {
          LABEL: "Specify crop type",
        },
        CROP: {
          LABEL: "Crop from the producer",
        },
        FARM: {
          LABEL: "Crop farm",
        },
      },
      ERRORS: {
        ACTIVITIES_REQUIRED:
          "The crop must have a validated harvest to calculate greenhouse gases (GHG) with the CFT methodology.",
        IDENTIFIER_REQUIRED:
          "You must enter a cuit, nit or producer identifier",
        NOT_FOUND: "No search results were found",
      },
      SEARCH_BUTTON: "Search for",
      NEXT_BUTTON: "Next",
      LOADING: "Loading...",
      TAX_ID: "TAX ID",
    },
    CROP_FORM: {
      SOIL: "Soil",
      ADD_SOIL_AND_WEATHER_DATA: "Soil and climate data",
      SOIL_ORGANIC_MATTER: "Soil organic matter",
      SOIL_WATER_CHARACTERIZATION: "Soil water characterization",
      SOIL_WATER_CHARACTERIZATION_TOOLTIP:
        '"Humid soils, whether due to rainfall or irrigation, are those that do not have water restrictions. Dry soils are those with water restrictions."',
      SOIL_PH: "Soil pH",
      CLIMATE: "Climate",
      SOIL_DRAINAGE: "Soil drainage",
      SOIL_TEXTURE: "Soil texture",
      SOIL_MOISTURE: "Soil moisture",
      SOIL_CHARACTERISTIC: "Soil feature type",
      DRAINAGE: "Drainage",
      TEXTURE: "Texture",
      SELECT_TYPE_ORGANIC_MATTER: "Select a type of organic matter",
      SELECT_TYPE_SOIL_WATTER_CHARACTERIZATION:
        "Select a type of soil water characterization",
      SELECT_CLIMATE_TYPE: "Select a climate type",
      SELECT_SOIL_DRAINAGE_TYPE: "Select a soil drainge type",
      SELECT_SOIL_TEXTURE_TYPE: "Select a soil texture type",
      SELECT_SOIL_PH_TYPE: "Select a type of soil ph",
      CALCULATED_DATA: "Calculated data",
      ALERTS: {
        SENDING_MORE_THAN_ONE_CROP:
          "You cannot submit more than one crop in the process.",
        DATA_REQUIRED: "You must enter crop data before continuing.",
        SELECT_A_SOIL_FEATURE_TYPE: "Select a soil feature type",
      },
    },
    ENERGIES_FORM: {
      ENERGY: "Energy",
      ENERGY_DATA: "Energy data",
      ENERGY_USE_CATEGORY: "Energy use category",
      ENERGY_SOURCE_CATEGORY: "Energy source category",
      FUEL_USED: "Fuel used",
      FUEL_UNIT: "Fuel unit",
      TOOLTIPS: {
        ENERGY_DATA: `
          Indicate the amount of energy used per hectare/acre per year. In case the farm covers the entire area, enter the total energy used on the farm for one year.

          Possible energy sources include electricity, gas, and fuels. This covers energy used on the site for activities such as irrigation, lighting, ventilation and the like, and should also include energy used by contractors.
          
          It is important to note that energy used for off-farm transport should be excluded as this will be covered in the next section.

          If it is difficult to determine the amount of energy used by the individual product under evaluation (relative to other crops grown), you can use the machinery emissions calculator, consult a specialist adviser or estimate the energy use of this product relative to others, taking into account land area, volume or economic value.
        `,
      },
      ALERTS: {
        SELECT_ENERGY_USE_CATEGORY: "Select an energy use category",
        SELECT_ENERGY_SOURCE_CATEGORY: "Select an energy source category",
        ENTER_ENERGY_USE_AMOUNT: "Indicate the number of fuel used",
        SELECT_FUEL_UNIT: "Select fuel unit",
      },
    },
    LAND_MANAGEMENT_FORM: {
      LAND: "Land",
      LAND_MANAGEMENT_DATA: "Land management data",
      CHANGE_IN_LAND_USE: "Change in land use",
      LAND_USE_CHANGE_RATE: "Land use change rate",
      NUMBER_OF_YEARS_ELAPSED: "Number of years elapsed",
      PERCENTAGE_OF_AFFECTED_AREA: "Percentage of affected area",
      FOREST_TYPE: "Forest type",
      CHANGE_OF_TILLAGE_SYSTEM: "Tillage system exchange rate",
      TILLAGE_SYSTEM_EXCHANGE_RATE: "Tillage system exchange",
      USE_OF_COVER_CROPS: "Use of cover crops",
      CHANGE_RATE_IN_COVER_CROPS: "Change rate in the use of cover crops",
      WASTE_DATA: "Waste data",
      CROP_RESIDUE_MANAGEMENT: "Crop residue management",
      AMOUNT_OF_WASTE: "Amount of waste",
      UNIT: "Unit",
      CATEGORY_OF_LAND_MANAGEMENT_CHANGE: "Category of land management change",
      YEARS_SINCE_CHANGE: "Years since change",
      PERCENTAGE_LAND_AFFECTED_BY_CHANGE:
        "Percentage of land affected by change",
      TOOLTIPS: {
        LAND_MANAGEMENT_DATA: `
        Indicate if there have been any changes in tillage, cover crop or land use practices in the last 20 years, excluding annual fluctuations.
          Skip this section if no changes have been made to your management practices in that period.
        `,
      },
      ALERTS: {
        SELECT_WASTE_MANAGEMENT_TYPE: "Select a type of waste management",
        ENTER_WASTE_PERCENTAGE: "Enter in number the amount of waste generated",
        SELECT_LAND_USE_CHANGE: "Select a change in land use",
        INDICATE_YEARS_METHODOLOGY_IMPLEMENTED:
          "Indicate in number the number of years that the methodology has been implemented",
        INDICATE_PERCENTAGE_AREA_AFFECTED:
          "Indicate the percentage of area affected",
        SELECT_FOREST_TYPE: "Select a forest type",
        SELECT_TILLAGE_CHANGE: "Select a tillage change",
        SELECT_CHANGE_IN_COVER_CROPS:
          "Select a change in the use of cover crops",
        ENTER_WASTE_DATA: "YOU MUST ENTER WASTE DATA BEFORE PROCEEDING",
        UNIT_REQUIRED: "UNIT IS REQUIRED",
      },
    },
    LAND_FORM: {
      TITLE: "Changes in current management practice",
      TOOLTIP_MESSAGE: `Indicate if you made any changes in tillage, cover crops or organic input management in the last 20 years.
        Do not indicate annual fluctuations. If you have not changed your management practices in the last 20 years, you may skip this section.`,
      YEAR_OF_CHANGE: "Year of change",
      PERCENTAGE_AFFECTED_AREA: "Percentage of affected area",
      LAND_USE: "Land use",
      TILLAGE: "Tillage",
      CARBON_INPUTS: "Carbon input",
      FOREST_TYPE: "Forest type",
      ERRORS: {
        SELECT_LAND_USE: "Select a land use",
        SELECT_TILLAGE: "Select a type of tillage",
        SELECT_FOREST_TYPE: "Select a forest type",
        SELECT_CARBON_INPUTS: "Select carbon input",
        AGE_OF_CHANGE: "Enter in number the year of the change",
        MIN_AGE:
          "The year value of the change cannot be less than 20 years from the current year",
        PERCENTAGE_AFFECTED_AREA: "Enter the percentage of area affected",
        MAX_AGE:
          "The year value of the change cannot be greater than the previous change",
        ERROR_PERCENTAGE: "The percentage value cannot be greater than 100",
        ONLY_TWO_DECIMALS: "Value allows entry up to 2 decimal places",
      },
      TOOLTIP: `This is a simplified summary of the carbon input categories, for get more precise description.
      
      Low Carbon Inputs: Residue removed or burnt, bare ground for > 6 months in last 3 years, low-yield crops, no fertilisation.
      
      Medium Carbon Inputs: Cereals with residue returned or supplemental organic matter, cover crops for > 6 months in last 3 years or residue left on field (but not both).
      
      High Carbon Inputs (without manure): High residue inputs with cover crops for > 6 months in last 3 years, ley system within three years, etc., but no manure.
      
      High Carbon Inputs (with manure): Regular addition of animal manure for increased carbon input at least once every three years, plus cover crops for > 6 months in last 3 years, and residue left on field
      
      Note: Slurry is ‘high without manure’, but if the user is applying enough slurry and there’s a significant Carbon input, then they can change it to ‘high with manure’.`,
    },
    TREE_FORM: {
      TITLE: "Tree mass data",
      TOOLTIP_MESSAGE:
        "Indicate the density of trees per hectare/acre in the crop area, with diameter measured at breast height.",
      PREDOMINANT_TREE_CLASSIFICATION: "Predominant Tree Classification",
      DIAMETER_PREVIOUS_YEAR: "Diameter at breast height from previous year",
      DIAMETER_CURRENT_YEAR: "Diameter at breast height for current year",
      UNIT_DENSITY: "Unit density",
      UNIT_DIAMETER: "Unit diameter",
      TREES_PLANTED: "Trees planted",
      TREES_LOST: "Trees lost",
      DENSITY: "Density",
      QTY_PLANTED_LOST_TREES: "Number of trees planted (+) / lost (-)",
      ERRORS: {
        TREE_TYPE: "Select the type of trees in the tree mass",
        DENSITY_PREVIOUS_YEAR:
          "Indicate in number the diameter of the trees of the previous year",
        DENSITY_CURRENT_YEAR:
          "Indicate in number the diameter of the trees of the current year",
        TREES_PLANTED: "Enter the number of trees planted",
        TREES_LOST: "Enter in number of trees lost",
        DENSITY: "Enter the density in number",
        UNIT_DENSITY: "Select the unit of density",
        UNIT_DIAMETER: "Select the unit of diameter",
      },
    },
    TRANSPORT_FORM: {
      TRANSPORT: "Transport",
      TRANSPORT_DATA: "Transportation data",
      TYPE_VEHICLE_USED: "Type of vehicle used",
      WEIGHT_TRANSPORT_LOAD: "Weight of the transported load",
      DISTANCE_TRAVELED_IN_TRANSPORT: "Distance traveled in transport",
      DISTANCE_UNIT: "Distance unit",
      WEIGHT_UNIT: "Weight unit",
      UNIT: "Unit",
      TOOLTIPS: {
        TRANSPORT_DATA: `
          Indicate the amount of energy used by the transport from the farm or the transfer of finished products to a point of sale. Transportation includes the delivery or disposal of materials, even if own vehicles are not used to transport the products.
        `,
      },
      ALERTS: {
        SELECT_TYPE_VEHICLE_USED:
          "SELECT THE TYPE OF VEHICLE USED IN THE TRANSPORTATION",
        ENTER_WEIGHT_IN_TONS: "Enter in number the weight transported",
        ENTER_DISTANCE_IN_KILOMETERS: "Enter in number the distance traveled",
        ENTER_WEIGHT_UNIT: "INDICATE THE UNIT OF WEIGHT TO BE TRANSPORTED",
        ENTER_DISTANCE_UNIT: "INDICATE THE UNIT OF THE DISTANCE TRAVELED",
      },
    },
    RESIDUE_FORM: {
      ALERTS: {
        ONLY_TWO_DECIMALS: "Value allows entry up to 2 decimal places",
        NUMBER_WASTE_GENERATED: "Enter in number the amount of waste generated",
      },
    },
    SUMMARY: {
      GENERATE_REPORT: "Generate report",
      GENERATING_REPORT: "Generating report...",
      SUMMARY: "Summary",
      SOIL: "Soil",
      ENERGY: "Energy ",
      WASTE_MANAGEMENT: "Waste management ",
      LAND_MANAGEMENT: "Land management",
      TRANSPORTATION: "Transportation",
      TREE_STAN: "Tree stand",
      MACHINERY: "Machinery",
      SUB_PROCESS: "Sub process",
      SUB_PRODUCTS: "Sub products",
      STORAGE: "Storage",
    },
    VALIDATIONS: {
      ARE_YOU_SURE: "Are you sure?",
      A_RECORD_THAT_IS_DELETED_CANNOT_BE_RECOVERED:
        "A record that is deleted cannot be recovered",
      YES_DELETE: "Yes, delete",
      CANCEL: "Cancel",
    },
  },
};
