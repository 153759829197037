import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import Item from "./item";
import { useTranslate } from "src/hooks/useTranslate/index";
import { landEntity } from "src/commons/constants";

const List = ({ title, items, heads, entity, action, callbackAfterDelete }) => {
  const { t } = useTranslate();
  const cargando = useSelector((state) => state.establishment.loading);

  const showOrRemoveButton = (item, index) => {
    if (item.entity === landEntity) {
      return items.length === index + 1;
    }
    return true;
  };

  return (
    <Fragment>
      <h4 className="mb-4 font-weight-bold">{title}</h4>

      {cargando ? (
        <p className="text-center">{t("COMMON.LOADING")}....</p>
      ) : null}

      <table className="table table-striped">
        <thead className="">
          <tr>
            {heads.map((head, index) => {
              return (
                <th
                  scope="col"
                  style={{ textAlign: "center" }}
                  key={`head_${index}`}
                >
                  {t(head)}
                </th>
              );
            })}

            {action ? (
              <th className="acciones" scope="col">
                {" "}
                {t("COMMON.ACTIONS")}
              </th>
            ) : null}
          </tr>
        </thead>
        <tbody>
          {items?.length ? (
            items.map((item, index) => {
              const showButtonDelete = showOrRemoveButton(item, index);
              return (
                <Item
                  data={item}
                  entity={entity}
                  action={action}
                  key={`item_body_${index}`}
                  showButtonDelete={showButtonDelete}
                  callbackAfterDelete={callbackAfterDelete}
                ></Item>
              );
            })
          ) : (
            <tr>
              <td colSpan={heads.length + 1} className="text-center">
                {t("COMMON.NO_RECORDS")}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Fragment>
  );
};

export default List;
