import { cropEntity } from "../commons/constants";
import { useParseData } from "./useParseData";

export const useParseSumaryData = () => {
  const { parseDataToShowInCell } = useParseData();

  const parserDataToSummary = (data, entity) => {
    const dataToShow = [];

    for (const [key, value] of Object.entries(data)) {
      const dataToCell = parseDataToShowInCell(key, value, entity);

      if (selectToShow(key, entity)) {
        dataToShow.push({
          key,
          value: dataToCell ? dataToCell.value : value,
        });
      }
    }

    return dataToShow;
  };

  const selectToShow = (key, entity) => {
    if (key === "id") return false;
    if (key === "createdAt") return false;
    if (key === "updatedAt") return false;
    if (key === "ucropitCropId") return false;
    if (key === "soilDrainage") return false;
    if (key === "soilTexture") return false;
    if (key === "seedAmountValue") return false;
    if (key === "residueValue" && entity === cropEntity) return false;
    if (key === "residueManagementId" && entity === cropEntity) return false;
    return true;
  };

  return {
    parserDataToSummary,
  };
};
