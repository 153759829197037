import { createSlice } from "@reduxjs/toolkit";

/**
 * @type {import('utils/types.js').SelectSteps}
 * @const
 */
const initialState = {
  floor: {
    soilOrganicMatters: [],
    soilMoistures: [],
    phs: [],
    climates: [],
    weathertypes: [],
    soilDrainages: [],
    soilTextures: [],
  },
  energy: {
    energyCategories: [],
    energySources: [],
  },
  land: {
    residueManagements: [],
    landManagements: [],
  },
  irrigation: {
    irrigationMethods: [],
    irrigationPowerSources: [],
    irrigationWaterSources: [],
  },
  transport: {
    transportTypes: [],
  },
  trees: {
    treeTypes: [],
  },
};

export const selectStepsSlice = createSlice({
  name: "selectSteps",
  initialState,
  reducers: {
    /**
     *
     * @param {import('utils/types.js').SelectSteps} state
     * @param {object} action
     * @param {import('utils/types.js').SelectSteps} action.payload
     */
    setLoadValues: (state, action) => {
      state = {
        ...action.payload,
      };

      return state;
    },
  },
});

export const { setLoadValues } = selectStepsSlice.actions;

export const { reducer: selectStepsReducer } = selectStepsSlice;
