import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { landEntity } from "src/commons/constants";
import FootButtonForm from "src/components/form/footButtonForm";
import FormBody from "src/components/form/formBody";
import { useTranslate } from "src/hooks/useTranslate/index";
import { landFormHead } from "../../constants";
import { useEffect } from "react";
import validation from "src/commons/validation";
import { hiddenAlert, showAlert } from "src/store/actions/alertaActions";
import { addNewEntity } from "src/store/actions/tableActions";
import { formOptions } from "src/modules/common/constants/formOptions";
import { filterOptionsSelect } from "../../utils/filterOptionsSelect";
import { useQuery } from "src/hooks/useQuery";
import { CROP_TYPES } from "src/modules/common/constants/cropTypes";
import { INPUT_NAMES } from "../../utils/inputNames";

const FOREST_NATIVE_OPTION = 6;
const MAX_LANDS = 4;
const propRenderSelect = "description";

const initialState = {
  yearOfChange: "",
  percentageAffectedArea: "",
  landUse: "",
  tillage: "",
  carbonInput: "",
  forestType: "",
};

const LandForm = () => {
  const query = useQuery();
  const cropType = query.get("cropType");
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const entity = landEntity;
  const screen = window.screen;
  const selectsLandState = useSelector((state) => {
    return state.selectSteps.land;
  });
  const establishments = useSelector((state) => {
    return state.establishment;
  });
  const lands = useSelector((state) => {
    return state.table.land;
  });
  const { forestTypes, carbonInputs, tillages, landUses } = selectsLandState;
  const [values, setValues] = useState(initialState);
  const [disableForestType, setDisableForestType] = useState(true);

  const landUseRef = React.createRef();
  const tillageRef = React.createRef();
  const carbonInputRef = React.createRef();

  const selectOptions = {
    landUse: filterOptionsSelect(landUses, lands, "landUse"),
    tillage: filterOptionsSelect(tillages, lands, "tillage"),
    carbonInput: filterOptionsSelect(carbonInputs, lands, "carbonInput"),
  };

  useEffect(() => {
    if (values.landUse) {
      const landUse = values.landUse;
      const forestType = lands.find((land) => land.forestType);
      const enableSelectForestType =
        (landUse.cftIdFrom === FOREST_NATIVE_OPTION ||
          landUse.cftIdTo === FOREST_NATIVE_OPTION) &&
        !forestType;
      if (!enableSelectForestType && values.forestType) {
        setValues({ ...values, forestType: "" });
      }
      setDisableForestType(!enableSelectForestType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lands, values.landUse]);

  useEffect(() => {
    if (landUseRef.current && !values.landUse) {
      landUseRef.current.value = "";
    }
    if (tillageRef.current && !values.tillage) {
      tillageRef.current.value = "";
    }
    if (carbonInputRef.current && !values.carbonInput) {
      carbonInputRef.current.value = "";
    }
  }, [
    carbonInputRef,
    landUseRef,
    tillageRef,
    values.landUse,
    values.tillage,
    values.carbonInput,
  ]);

  const searchData = (index, name) => {
    const data = selectOptions[name];
    const value = data[index];
    return value;
  };

  const valueChanged = (value, name, option) => {
    const inputValue =
      option === formOptions.SELECT_WITH_FULL_DATA
        ? searchData(value, name)
        : value;

    setValues({
      ...values,
      [name]: inputValue,
    });
  };

  const clearData = (year) => {
    const yearOfChange = year ?? String(values.yearOfChange - 1);
    setValues({
      ...initialState,
      yearOfChange,
    });

    setDisableForestType(true);
  };

  const submitData = (e) => {
    e.preventDefault();

    const lastLand = lands[lands.length - 1];
    const dataToSend = {
      ...values,
      lastLand,
      entity,
    };
    const validationResponse = validation(dataToSend, t);
    if (validationResponse) {
      const alert = {
        msg: validationResponse.msg,
        classes: "alert alert-danger text-center text-uppercase p3",
      };

      dispatch(showAlert(alert));

      return;
    }
    delete dataToSend.lastLand;
    dispatch(addNewEntity(dataToSend));
    dispatch(hiddenAlert());
    clearData();
  };

  const callbackAfterDelete = () => {
    const penultimateLand = lands[lands.length - 2];
    const yearSend = penultimateLand?.yearOfChange
      ? penultimateLand?.yearOfChange - 1
      : "";
    clearData(yearSend);
  };

  const landFormOptions = {
    titleForm: t("LAND_FORM.TITLE"),
    toolTipOption: {
      msg: t("LAND_FORM.TOOLTIP_MESSAGE"),
      height: screen.width > 1360 && screen.width < 1900 ? "32%" : "40%",
      width: screen.width < 1280 ? "30%" : "25%",
    },
    titleTable: t("LAND_MANAGEMENT_FORM.LAND_MANAGEMENT_DATA"),
    functionSubmit: submitData,
    entity,
    InputOption: [
      {
        style: "col-md-6",
        titleForm: `${t("LAND_FORM.YEAR_OF_CHANGE")} *`,
        inputType: formOptions.NUMBER,
        valueChanged,
        name: "yearOfChange",
        value: values.yearOfChange,
      },
      {
        style: "col-md-6",
        titleForm: `${t("LAND_FORM.PERCENTAGE_AFFECTED_AREA")} *`,
        inputType: formOptions.NUMBER,
        valueChanged,
        name: "percentageAffectedArea",
        value: values.percentageAffectedArea,
      },
      {
        style: "col-md-4 col-sm-12",
        titleForm: `${t("LAND_FORM.LAND_USE")} *`,
        inputType: formOptions.SELECT_WITH_FULL_DATA,
        name: "landUse",
        valueChanged,
        dataToSelect: selectOptions.landUse,
        returnIndex: true,
        value: values.landUse,
        propRenderSelect,
        ref: landUseRef,
      },
      {
        style: "col-md-4 col-sm-12",
        titleForm: `${t("LAND_FORM.TILLAGE")} *`,
        inputType: formOptions.SELECT_WITH_FULL_DATA,
        name: INPUT_NAMES.TILLAGE,
        valueChanged,
        dataToSelect: selectOptions.tillage,
        value: values.tillage,
        propRenderSelect,
        ref: tillageRef,
      },
      {
        style: "col-md-4 col-sm-12",
        titleForm: `${t("LAND_FORM.CARBON_INPUTS")} *`,
        inputType: formOptions.SELECT_WITH_FULL_DATA,
        name: INPUT_NAMES.CARBON_INPUT,
        valueChanged,
        dataToSelect: selectOptions.carbonInput,
        value: values.carbonInput,
        propRenderSelect,
        ref: carbonInputRef,
        toolTipOption: {
          msg: t("LAND_FORM.TOOLTIP"),
          height: screen.width > 1360 && screen.width < 1900 ? "75%" : "8%",
          width: screen.width < 1280 ? "15%" : "50%",
          sizeIcon: 20,
          bsPrefix: "tooltipArrowRight",
          placement: "auto",
        },
      },
      {
        style: "col-md-12",
        titleForm: `${t("LAND_FORM.FOREST_TYPE")} *`,
        inputType: formOptions.SELECT,
        name: "forestType",
        valueChanged,
        disabled: disableForestType,
        dataToSelect: forestTypes,
        value: values.forestType,
      },
    ],
    dataToList: {
      items: lands,
      heads: landFormHead,
      callbackAfterDelete,
    },
    disabledAdd: lands.length >= MAX_LANDS,
  };

  const defaultValueByCrop = () => {
    const cropTypeKey = getCropTypeKey();

    if (
      cropTypeKey.toLocaleLowerCase() === CROP_TYPES.RICE.toLocaleLowerCase()
    ) {
      const tillageInputValue = selectOptions.tillage.findIndex(
        (tillage) => tillage.cftIdFrom === 1 && tillage.cftIdTo === 1
      );
      const carbonInputValue = selectOptions.carbonInput.findIndex(
        (carbonInput) =>
          carbonInput.cftIdFrom === 4 && carbonInput.cftIdTo === 4
      );

      tillageRef.current.value = tillageInputValue;
      tillageRef.current.disabled = true;

      carbonInputRef.current.value = carbonInputValue;
      carbonInputRef.current.disabled = true;

      const inputValueTillage = searchData(
        tillageInputValue,
        INPUT_NAMES.TILLAGE
      );
      const inputValueCarbonInput = searchData(
        carbonInputValue,
        INPUT_NAMES.CARBON_INPUT
      );

      setValues((oldValues) => ({
        ...oldValues,
        [INPUT_NAMES.TILLAGE]: inputValueTillage,
        [INPUT_NAMES.CARBON_INPUT]: inputValueCarbonInput,
      }));
    }
  };

  const getCropTypeKey = useCallback(() => {
    const cropTypeEstablishment = establishments?.crops[0]?.cropType?.key;

    const cropTypeSelected = establishments?.cropTypes?.find(
      (cropTypeOriginal) => cropTypeOriginal._id === cropType
    );

    return cropTypeEstablishment ?? cropTypeSelected?.key;
  }, [cropType, establishments?.cropTypes, establishments?.crops]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(defaultValueByCrop, [lands]);

  return (
    <div className="row justify-content-center">
      <div className="col-md-12">
        <div className="stepwizard">
          <div className="stepwizard-row">
            <div className="stepwizard-step">
              <button
                type="button"
                className="btn btn-default btn-circle"
                disabled="disabled"
              >
                3
              </button>
              <p>{t("FORM_STEPS.WASTE")}</p>
            </div>

            <div className="stepwizard-step">
              <button
                type="button"
                className="btn btn-default btn-circle"
                disabled="disabled"
              >
                4
              </button>
              <p>{t("FORM_STEPS.ENERGY")}</p>
            </div>

            <div className="stepwizard-step">
              <button
                type="button"
                className="btn btn-primary1 btn-circle"
                disabled="disabled"
              >
                5
              </button>
              <p>{t("FORM_STEPS.LAND")}</p>
            </div>
            <div className="stepwizard-step">
              <button
                type="button"
                className="btn btn-default btn-circle"
                disabled="disabled"
              >
                6
              </button>
              <p>{t("FORM_STEPS.TRANSPORT")}</p>
            </div>
            <div className="stepwizard-step">
              <button
                type="button"
                className="btn btn-default btn-circle"
                disabled="disabled"
              >
                7
              </button>
              <p>{t("FORM_STEPS.TREES")}</p>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
        <FormBody option={landFormOptions} />
        <br></br>
      </div>
      <br></br>
      <div className="container">
        <br></br>
        <br></br>
        <br></br>
        <FootButtonForm prev={true} next={true} />
        <br></br>
      </div>
      <br></br>
      <br></br>
      <div className="container">
        <br></br>
        <br></br>
      </div>
      <br></br>
    </div>
  );
};

export default LandForm;
