/**
 *
 * @param {string} key
 *
 * @return {string|null}
 */
export const getSessionStorage = (key) => {
  return sessionStorage.getItem(key);
};

/**
 *
 * @param {string} key
 * @param {string} value
 *
 * @return {void}
 */
export const setSessionStorage = (key, value) => {
  sessionStorage.setItem(key, value);
};

/**
 *
 * @param {string} key
 *
 * @return {void}
 */
export const removeItemSessionStorage = (key) => {
  sessionStorage.removeItem(key);
};
