import { numberHasTwoDecimals } from "src/utils/numberHasTwoDecimals";
import {
  cropEntity,
  machinaryEntity,
  energyEntity,
  landManagementsEntity,
  coProductEntity,
  irrigationEntity,
  storageEntity,
  treeBiomassEntity,
  transportsEntity,
  subProcessEntity,
  residueManagementEntity,
  LandManagementIdTypes,
  landEntity,
} from "../commons/constants";
import { validateLandForm } from "src/modules/land/utils/validateLandFom";

export default function validation(data, t) {
  const { entity, lastLand } = data;

  switch (entity) {
    case cropEntity:
      return validationCrop(data, t);
    case residueManagementEntity:
      return validationResidueManagement(data, t);
    case energyEntity:
      return validationEnergy(data, t);
    case landManagementsEntity:
      return validationLandManagement(data, t);
    case landEntity:
      return validateLandForm(data, t, lastLand);
    case irrigationEntity:
      return validationIrrigation(data);
    case machinaryEntity:
      return validationMachinary(data);
    case storageEntity:
      return validationStorage(data);
    case transportsEntity:
      return validationTransport(data, t);
    case treeBiomassEntity:
      return validationTreeBiomass(data, t);
    case subProcessEntity:
      return validationSubProcess(data);
    case coProductEntity:
      return validationCoProcess(data);
    default:
      break;
  }
}

function validationCrop(data, t) {
  const {
    soilMoistureMatterId,
    soilPhId,
    soilDrainage,
    soilTexture,
    weatherId,
    soilCharacteristic,
  } = data;

  if (!soilMoistureMatterId)
    return { error: true, msg: t("CROP_FORM.SELECT_TYPE_ORGANIC_MATTER") };
  if (!soilCharacteristic)
    return {
      error: true,
      msg: t("CROP_FORM.ALERTS.SELECT_A_SOIL_FEATURE_TYPE"),
    };
  if (!soilPhId)
    return { error: true, msg: t("CROP_FORM.SELECT_SOIL_PH_TYPE") };
  if (!weatherId)
    return { error: true, msg: t("CROP_FORM.SELECT_CLIMATE_TYPE") };
  if (!soilDrainage)
    return { error: true, msg: t("CROP_FORM.SELECT_SOIL_DRAINAGE_TYPE") };
  if (!soilTexture)
    return { error: true, msg: t("CROP_FORM.SELECT_SOIL_TEXTURE_TYPE") };

  return null;
}
function validationResidueManagement(data, t) {
  const { residueManagementId, residueValue, residueUnit } = data;

  if (!residueManagementId)
    return {
      error: true,
      msg: t("LAND_MANAGEMENT_FORM.ALERTS.SELECT_WASTE_MANAGEMENT_TYPE"),
    };

  if (
    residueUnit &&
    (isNaN(residueValue) || (residueValue !== 0 && residueValue < 0))
  ) {
    return {
      error: true,
      msg: t("LAND_MANAGEMENT_FORM.ALERTS.ENTER_WASTE_PERCENTAGE"),
    };
  }

  if (!isNaN(residueValue)) {
    const isValidResidueValue = numberHasTwoDecimals(residueValue);

    if (!isValidResidueValue) {
      return {
        error: true,
        msg: t("RESIDUE_FORM.ALERTS.ONLY_TWO_DECIMALS"),
      };
    }
  }

  if (!isNaN(residueValue) && !residueUnit)
    return {
      error: true,
      msg: t("LAND_MANAGEMENT_FORM.ALERTS.UNIT_REQUIRED"),
    };

  return null;
}

function validationEnergy(data, t) {
  const { category, usageValue, usageValueUnit, source } = data;

  if (!category)
    return {
      error: true,
      msg: t("ENERGIES_FORM.ALERTS.SELECT_ENERGY_USE_CATEGORY"),
    };

  if (!source)
    return {
      error: true,
      msg: t("ENERGIES_FORM.ALERTS.SELECT_ENERGY_SOURCE_CATEGORY"),
    };

  if (!usageValueUnit)
    return {
      error: true,
      msg: t("ENERGIES_FORM.ALERTS.SELECT_FUEL_UNIT"),
    };

  if (!usageValue)
    return {
      error: true,
      msg: t("ENERGIES_FORM.ALERTS.ENTER_ENERGY_USE_AMOUNT"),
    };

  return null;
}

function validationLandManagement(data, t) {
  const { changed, yearsAgo, allocation, forestTypes } = data;
  if (!changed)
    return {
      error: true,
      msg: {
        land_use: t("LAND_MANAGEMENT_FORM.ALERTS.SELECT_LAND_USE_CHANGE"),
        tillage: t("LAND_MANAGEMENT_FORM.ALERTS.SELECT_TILLAGE_CHANGE"),
        cover: t("LAND_MANAGEMENT_FORM.ALERTS.SELECT_CHANGE_IN_COVER_CROPS"),
      },
    };
  if (!yearsAgo)
    return {
      error: true,
      msg: t(
        "LAND_MANAGEMENT_FORM.ALERTS.INDICATE_YEARS_METHODOLOGY_IMPLEMENTED"
      ),
    };
  if (!allocation)
    return {
      error: true,
      msg: t("LAND_MANAGEMENT_FORM.ALERTS.INDICATE_PERCENTAGE_AREA_AFFECTED"),
    };
  if (!forestTypes && LandManagementIdTypes.includes(changed))
    return {
      error: true,
      msg: t("LAND_MANAGEMENT_FORM.ALERTS.SELECT_FOREST_TYPE"),
    };
  return null;
}
function validationIrrigation(data) {
  const {
    irrigationMethod,
    waterSource,
    allocation,
    pumpingDepth,
    horizontalDistance,
    waterAdded,
    powerSourceUsed,
  } = data;
  if (!irrigationMethod)
    return { error: true, msg: "Seleccione el metodo de irrigacion utilizado" };
  if (!waterSource)
    return {
      error: true,
      msg: " Seleccione la fuente de agua del metodo de irrigacion",
    };
  if (!allocation)
    return { error: true, msg: "Indique el porcentaje del cultivo irrigado" };
  if (!pumpingDepth)
    return {
      error: true,
      msg: "Indique en numeros la cantidad de metros de profundidad del riego",
    };
  if (!horizontalDistance)
    return {
      error: true,
      msg: "Indique en numeros la cantidad de metros de distancia horizontal del riego",
    };
  if (!waterAdded)
    return {
      error: true,
      msg: "Indique en numeros la cantidad de agua en litros utilizada en el riego",
    };
  if (!powerSourceUsed)
    return { error: true, msg: "Seleccione un tipo de fuente de poder" };

  return null;
}
function validationMachinary(data) {
  const { machinaryTypeId, fuelTypeId, operationNumber } = data;
  if (!machinaryTypeId)
    return {
      erro: true,
      msg: "Seleccione el tipo de maquinaria utilizada en la operacion",
    };
  if (!fuelTypeId)
    return {
      erro: true,
      msg: "Seleccione el tipo de fuente de energia de la maquinaria",
    };
  if (!operationNumber)
    return {
      erro: true,
      msg: "Indique el numero de operaciones realizada por la maquinaria",
    };
  return null;
}
function validationStorage(data) {
  const {
    storagePowerSource,
    unloadingPowerSource,
    storedAllocation,
    temperature,
    time,
    storeLoadingPowerSource,
  } = data;

  if (!storagePowerSource)
    return {
      error: true,
      msg: "Seleccione la fuente de energia del almacenamiento",
    };
  if (!unloadingPowerSource)
    return {
      error: true,
      msg: "Seleccione la fuente de energia utilizada en la descarga ",
    };
  if (!storedAllocation)
    return {
      error: true,
      msg: "Indique en numero el porcentaje de producto del cultivo asignado a almacenamiento",
    };
  if (!temperature)
    return {
      error: true,
      msg: "Indique en numero la temperatura promedio en °C del almacenamiento",
    };
  if (!time)
    return {
      error: true,
      msg: "Indique en numero los dia que dura el almacenamiento de productos",
    };
  if (!storeLoadingPowerSource)
    return {
      error: true,
      msg: "Seleccione la fuente de energia utilizada en la carga ",
    };
  return null;
}
function validationTransport(data, t) {
  const { mode, distance, distanceUnit, weight, weightUnit } = data;

  if (!mode)
    return {
      error: true,
      msg: t("TRANSPORT_FORM.ALERTS.SELECT_TYPE_VEHICLE_USED"),
    };
  if (!weight)
    return {
      error: true,
      msg: t("TRANSPORT_FORM.ALERTS.ENTER_WEIGHT_IN_TONS"),
    };
  if (!weightUnit)
    return {
      error: true,
      msg: t("TRANSPORT_FORM.ALERTS.ENTER_WEIGHT_UNIT"),
    };
  if (!distance)
    return {
      error: true,
      msg: t("TRANSPORT_FORM.ALERTS.ENTER_DISTANCE_IN_KILOMETERS"),
    };
  if (!distanceUnit)
    return {
      error: true,
      msg: t("TRANSPORT_FORM.ALERTS.ENTER_DISTANCE_UNIT"),
    };

  return null;
}
function validationTreeBiomass(data, t) {
  const {
    treeType,
    densityLastYear,
    sizeLastYear,
    sizeThisYear,
    diameterUnit,
    densityUnit,
    numTreesDeltaLost,
    numTreesDeltaPlanted,
  } = data;
  if (!treeType)
    return {
      error: true,
      msg: t("TREE_FORM.ERRORS.TREE_TYPE"),
    };
  if (!sizeLastYear)
    return {
      error: true,
      msg: t("TREE_FORM.ERRORS.DENSITY_PREVIOUS_YEAR"),
    };
  if (!sizeThisYear)
    return {
      error: true,
      msg: t("TREE_FORM.ERRORS.DENSITY_CURRENT_YEAR"),
    };
  if (!diameterUnit)
    return {
      error: true,
      msg: t("TREE_FORM.ERRORS.UNIT_DIAMETER"),
    };
  if (numTreesDeltaPlanted === "")
    return {
      error: true,
      msg: t("TREE_FORM.ERRORS.TREES_PLANTED"),
    };
  if (numTreesDeltaLost === "")
    return {
      error: true,
      msg: t("TREE_FORM.ERRORS.TREES_LOST"),
    };
  if (!densityLastYear)
    return {
      error: true,
      msg: t("TREE_FORM.ERRORS.DENSITY"),
    };
  if (!densityUnit)
    return {
      error: true,
      msg: t("TREE_FORM.ERRORS.UNIT_DENSITY"),
    };
  return null;
}
function validationSubProcess(data) {
  const { powerSource, allocation } = data;

  if (!powerSource)
    return {
      error: true,
      msg: "Seleccione la fuente de poder del sub proceso",
    };
  if (!allocation)
    return {
      error: true,
      msg: "Indique en numeros el porcentaje de productos del cultivo asignado al sub proceso",
    };
  return null;
}
function validationCoProcess(data) {
  const { name, percentageMainValue } = data;
  if (!name) return { error: true, msg: "Ingrese el nombre del producto" };
  if (!percentageMainValue)
    return {
      error: true,
      msg: "Ingrese el porcentaje de sub producto total obtenido en el cultivo",
    };
  return null;
}
