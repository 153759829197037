export const ADD_ESTABLISHMENT = "ADD_ESTABLISHMENT";
export const ADD_ESTABLISHMENT_SUCESSFULL = "ADD_ESTABLISHMENT_SUCESSFULL";
export const ADD_ESTABLISHMENT_ERROR = "ADD_ESTABLISHMENT_ERROR";

export const ADD_CROP = "ADD_CROP";
export const ADD_CROP_SUCESSFULL = "ADD_CROP_SUCESSFULL";
export const ADD_CROP_ERROR = "ADD_CROP_ERROR";

export const START_DOWNLOAD_ESTABLISHMENT = "START_DOWNLOAD_ESTABLISHMENT";
export const DOWNLOAD_ESTABLISHMENT_SUCESSFULL =
  "DOWNLOAD_ESTABLISHMENT_SUCESSFULL";
export const DOWNLOAD_ESTABLISHMENT_ERROR = "DOWNLOAD_ESTABLISHMENT_ERROR";

export const START_DOWNLOAD_CROP = "START_DOWNLOAD_CROP";
export const DOWNLOAD_CROP_SUCESSFULL = "DOWNLOAD_CROP_SUCESSFULL";
export const DOWNLOAD_CROP_ERROR = "DOWNLOAD_CROP_ERROR";

export const GET_ESTABLISHMENT_DELETE = "GET_ESTABLISHMENT_DELETE";
export const ESTABLISHMENT_DELETE_SUCESSFULL =
  "ESTABLISHMENT_DELETE_SUCESSFULL";
export const ESTABLISHMENT_DELETE_ERROR = "ESTABLISHMENT_DELETE_ERROR";

export const SHOW_ALERT = "SHOW_ALERT";
export const HIDDEN_ALERT = "HIDDEN_ALERT";

export const NEXT_STEP = "NEXT_STEP";
export const BACK_STEP = "BACK_STEP";

export const ADD_ENERGY = "ADD_ENERGIES";
export const DEL_ENERGY = "DEL_ENERGY";

export const ADD_ITEM_TABLE = "ADD_ITEM_TABLE";
export const DEL_ITEM_TABLE = "DEL_ITEM_TABLE";
export const CLEAR_ITEM_TABLE = "CLEAR_ITEM_TABLE";

export const ADD_LANDMANAGEMENT = "ADD_LANDMANAGEMENT";
export const DEL_LANDMANAGEMENT = "DEL_LANDMANAGEMENT";

export const GET_ESTABLISHMENT_DETAIL = "GET_ESTABLISHMENT_DETAIL";
export const ESTABLISHMENT_DETAIL_SUCESSFULL =
  "ESTABLISHMENT_DETAIL_SUCESSFULL";
export const ESTABLISHMENT_DETAIL_ERROR = "ESTABLISHMENT_DETAIL_ERROR";

export const START_DOWNLOAD_DETAIL_ESTABLISHMENT =
  "START_DOWNLOAD_DETAIL_ESTABLISHMENT";
export const DOWNLOAD_DETAIL_ESTABLISHMENT_SUCESSFULL =
  "DOWNLOAD_DETAIL_ESTABLISHMENT_SUCESSFULL";
export const DOWNLOAD_DETAIL_ESTABLISHMENT_ERROR =
  "DOWNLOAD_DETAIL_ESTABLISHMENT_ERROR";

export const START_DOWNLOAD_CROP_TYPES = "START_DOWNLOAD_CROP_TYPES";
export const DOWNLOAD_CROP_TYPES_SUCESSFULL = "DOWNLOAD_CROP_TYPES_SUCESSFULL";
export const DOWNLOAD_CROP_TYPES_ERROR = "DOWNLOAD_CROP_TYPES_ERROR";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

export const SAVE_TOKEN = "SAVE_TOKEN";
export const DEL_TOKEN = "DEL_TOKEN";

export const ADD_UNIT_MEASURE = "ADD_UNIT_MEASURE";
