export const pt = {
  translation: {
    COMMON: {
      FIELDS: {
        DEFAULT_SELECT_LABEL: "Não selecionado",
      },
      ACTIONS: "Ações",
      UNSELECTED: "Sem selecionar",
      GENERAL_ERROR: "Ocorreu um erro",
      GENERAL_ERROR_TRY_AGAIN: "Ocorreu um erro, tente novamente",
      BACK: "Voltar",
      CORRECT: "Correto",
      ADVISE: "Aviso",
      LOADING: "Carregando...",
      ADD: "Adicionar",
      NEXT: "Próximo",
      LOG_OUT: "Fazer logout",
      NO_RECORDS: "Não há registros",
      GENERATE_NEW_REPORT: "Gerar novo relatório",
    },
    MESSAGES: {
      STABLISHMENT_CREATED:
        "O estabelecimento foi adicionado com sucesso, você deseja baixar as provas",
    },
    FORM_STEPS: {
      SOIL: "Solo",
      ENERGY: "Energia",
      LAND: "Terra",
      TRANSPORT: "Transporte",
      TREES: "Árvores",
      WASTE: "Lixo",
      START: "Começar",
      SUMMARY: "Resumo",
      STORAGE: "Almacenamiento",
      MACHINERY: "Maquinarias",
      SUB_PROCESS: "Sub procesos",
      SUB_PRODUCTS: "Sub productos",
    },
    LOGIN: {
      TITLE: "Login",
      FIELDS: {
        EMAIL: {
          LABEL: "Email",
          HELP_TEXT: "Email obrigatório",
        },
        PASSWORD: {
          LABEL: "Senha",
          HELP_TEXT: "Senha necessária",
        },
      },
      BUTTON: "Login",
      ALERTS: {
        EMAIL_IS_REQUIRED: "Por favor introduza o seu e-mail",
        EMAIL_IS_INVALID: "Entre com um email válido",
        PASSWORD_INVALID_LENGTH: "Sua senha deve conter entre 6 e 8 caracteres",
        USER_IS_INVALID: "Usuário inválido",
        USER_OR_PASSWORD_IS_INVALID: "Login ou senha inválidos",
      },
      MODALS: {
        WELCOME: {
          TITLE: "Bem-vindo",
          SUCCESSFULLY_AUTH: "Seu login foi efetuado com sucesso",
          BUTTON: "OK",
        },
      },
    },
    SELECT_FARM: {
      TITLE: "Gerar novas evidências",
      FIELDS: {
        IDENTIFIER: {
          LABEL: "TAX ID ou ID do produtor",
        },
        CROP_TYPE: {
          LABEL: "Especificar o tipo de cultura",
        },
        CROP: {
          LABEL: "Cultura do produtor",
        },
        FARM: {
          LABEL: "Campo de cultura",
        },
      },
      ERRORS: {
        ACTIVITIES_REQUIRED:
          "A cultura deve ter uma colheita validada para calcular os gases de efeito estufa (GHG) usando a metodologia CFT.",
        IDENTIFIER_REQUIRED:
          "Você deve inserir um identificador cuit, nit ou produtor",
        NOT_FOUND: "Nenhum resultado de pesquisa foi encontrado",
      },
      SEARCH_BUTTON: "Pesquisar por",
      NEXT_BUTTON: "Próximo",
      LOADING: "Carregando...",
      TAX_ID: "TAX ID",
    },
    CROP_FORM: {
      SOIL: "Solo",
      ADD_SOIL_AND_WEATHER_DATA: "Dados de solo e clima",
      SOIL_ORGANIC_MATTER: "Matéria orgânica do solo",
      SOIL_WATER_CHARACTERIZATION: "Caracterização hídrica do solo",
      SOIL_WATER_CHARACTERIZATION_TOOLTIP:
        "Os solos úmidos, seja por chuva ou irrigação, são aqueles que não possuem restrição hídrica. Solos secos são aqueles com restrições de água.",
      SOIL_PH: "pH do solo",
      CLIMATE: "Clima",
      SOIL_DRAINAGE: "Drenagem do solo",
      SOIL_TEXTURE: "Textura do solo",
      SOIL_MOISTURE: "Umidade do solo",
      SOIL_CHARACTERISTIC: "Recurso de solo",
      DRAINAGE: "Drenagem",
      TEXTURE: "Textura",
      SELECT_TYPE_ORGANIC_MATTER: "Selecione um tipo de matéria orgânica",
      SELECT_TYPE_SOIL_WATTER_CHARACTERIZATION:
        "Selecione um tipo de caracterização da água do solo",
      SELECT_CLIMATE_TYPE: "Selecione o tipo de clima",
      SELECT_SOIL_DRAINAGE_TYPE: "Selecione um tipo de drenagem do piso",
      SELECT_SOIL_TEXTURE_TYPE: "Selecione um tipo de textura do solo",
      SELECT_SOIL_PH_TYPE: "Selecione um tipo de ph do solo",
      CALCULATED_DATA: "Dados calculados",
      ALERTS: {
        SENDING_MORE_THAN_ONE_CROP:
          "Não é possível enviar mais de uma safra no processo.",
        DATA_REQUIRED:
          "Você deve inserir os dados da cultura antes de continuar.",
        SELECT_A_SOIL_FEATURE_TYPE: "Selecione um tipo de recurso de solo",
      },
    },
    ENERGIES_FORM: {
      ENERGY: "Energia",
      ENERGY_DATA: "Dados de energia",
      ENERGY_USE_CATEGORY: "Categoria de uso de energia",
      ENERGY_SOURCE_CATEGORY: "Categoria de fonte de energia",
      FUEL_USED: "Combustível usado",
      FUEL_UNIT: "unidade de combustível",
      TOOLTIPS: {
        ENERGY_DATA: `
          Indique a quantidade de energia usada por hectare/acre por ano. Caso a fazenda cubra toda a área, insira a energia total usada na fazenda por um ano. 

          Possíveis fontes de energia incluem eletricidade, gás e combustíveis. Isso cobre a energia usada no local para atividades como irrigação, iluminação, ventilação e similares, e também deve incluir a energia usada pelos contratados.
          
          É importante observar que a energia usada para transporte fora da fazenda deve ser excluída, pois isso será abordado na próxima seção.

          Se for difícil determinar a quantidade de energia usada pelo produto individual sob avaliação (em relação a outras culturas cultivadas), você pode usar a calculadora de emissões de máquinas, consultar um consultor especializado ou estimar o uso de energia deste produto em relação a outros, levando em conta a área da terra, o volume ou o valor económico.
        `,
      },
      ALERTS: {
        SELECT_ENERGY_USE_CATEGORY: "Selecione uma categoria de uso de energia",
        SELECT_ENERGY_SOURCE_CATEGORY:
          "Selecione uma categoria de fonte de energia",
        ENTER_ENERGY_USE_AMOUNT:
          "Indique a quantidade de combustível utilizado",
        SELECT_FUEL_UNIT: "Selecione uma unidade de combustível",
      },
    },
    LAND_MANAGEMENT_FORM: {
      LAND: "Terra",
      LAND_MANAGEMENT_DATA: "Dados de manejo de terra",
      CHANGE_IN_LAND_USE: "Mudança no uso da terra",
      LAND_USE_CHANGE_RATE: "Taxa de mudança de uso da terra",
      NUMBER_OF_YEARS_ELAPSED: "Número de anos decorridos",
      PERCENTAGE_OF_AFFECTED_AREA: "Porcentagem da área afetada",
      FOREST_TYPE: "Tipo de floresta",
      CHANGE_OF_TILLAGE_SYSTEM: "Câmbio do sistema de lavoura",
      TILLAGE_SYSTEM_EXCHANGE_RATE: "Taxa de câmbio do sistema de lavoura",
      USE_OF_COVER_CROPS: "Uso de culturas de cobertura",
      CHANGE_RATE_IN_COVER_CROPS:
        "Taxa de mudança no uso de plantas de cobertura",
      WASTE_DATA: "Dados de resíduos",
      CROP_RESIDUE_MANAGEMENT: "Gestão de resíduos de culturas",
      AMOUNT_OF_WASTE: "Quantidade de lixo",
      UNIT: "Unidade",
      CATEGORY_OF_LAND_MANAGEMENT_CHANGE:
        "Categoria da mudança de gerenciamento da terra",
      YEARS_SINCE_CHANGE: "Anos desde a mudança",
      PERCENTAGE_LAND_AFFECTED_BY_CHANGE:
        "Porcentagem de terra afetada pela mudança",
      TOOLTIPS: {
        LAND_MANAGEMENT_DATA: `
          Indique se houve alguma mudança na lavoura, cultura de cobertura ou práticas de uso da terra nos últimos 20 anos, excluindo flutuações anuais.
          Ignore esta seção se nenhuma mudança tiver sido feita em suas práticas de gerenciamento nesse período.
        `,
      },
      ALERTS: {
        SELECT_WASTE_MANAGEMENT_TYPE: "Selecione um tipo de gestão de resíduos",
        ENTER_WASTE_PERCENTAGE:
          "Digite em número a quantidade de resíduos gerados",
        SELECT_LAND_USE_CHANGE: "Selecione uma mudança no uso da terra",
        INDICATE_YEARS_METHODOLOGY_IMPLEMENTED:
          "Indique em número o número de anos que a metodologia foi implementada",
        INDICATE_PERCENTAGE_AREA_AFFECTED:
          "Indique a porcentagem da área afetada",
        SELECT_FOREST_TYPE: "Selecione um tipo de floresta",
        SELECT_TILLAGE_CHANGE: "Selecione uma mudança de lavoura",
        SELECT_CHANGE_IN_COVER_CROPS:
          "Selecione uma mudança no uso de culturas de cobertura",
        ENTER_WASTE_DATA:
          "VOCÊ DEVE INSERIR OS DADOS DOS RESÍDUOS ANTES DE PROSSEGUIR",
        UNIT_REQUIRED: "A unidade deve ser selecionada",
      },
    },
    LAND_FORM: {
      TITLE: "Mudanças nas práticas de gestão atuais",
      TOOLTIP_MESSAGE: `Indique se você fez alguma alteração no preparo do solo, culturas de cobertura ou manejo de insumos orgânicos nos últimos 20 anos. 
        Não indique flutuações anuais. Se você não mudou suas práticas de gestão nos últimos 20 anos, você pode pular esta seção.`,
      YEAR_OF_CHANGE: "Ano de mudança",
      PERCENTAGE_AFFECTED_AREA: "Porcentagem de superfície afetada",
      LAND_USE: "Uso do solo",
      TILLAGE: "Lavoura",
      CARBON_INPUTS: "Insumo de carbono",
      FOREST_TYPE: "Tipo de floresta",
      ERRORS: {
        SELECT_LAND_USE: "Selecione um uso do solo",
        SELECT_TILLAGE: "Selecione um tipo de lavoura",
        SELECT_FOREST_TYPE: "Selecione um tipo de floresta",
        SELECT_CARBON_INPUTS: "Selecione a entrada de carbono",
        AGE_OF_CHANGE: "Digite em número o ano da mudança",
        MIN_AGE:
          "O valor do ano da alteração não pode ser inferior a 20 anos a partir do ano atual",
        PERCENTAGE_AFFECTED_AREA: "Insira a porcentagem da área afetada",
        MAX_AGE:
          "O valor do ano da alteração não pode ser maior que a alteração anterior",
        ERROR_PERCENTAGE: "O valor percentual não pode ser superior a 100",
        ONLY_TWO_DECIMALS: "Valor permite entrada com até 2 casas decimais",
      },
      TOOLTIP: `Este é um resumo simplificado das categorias de insumos de carbono, para uma descrição mais precisa.
      
      Insumos de baixo carbono: resíduos removidos ou queimados, solo descoberto por > 6 meses nos últimos 3 anos, culturas de baixo rendimento, sem fertilização.
      
      Insumos médios de carbono: cereais com resíduos devolvidos ou matéria orgânica suplementar, culturas de cobertura durante > 6 meses nos últimos 3 anos, ou resíduos deixados no campo (mas não ambos).
      
      Insumos elevados de carbono (sem fertilizantes): Insumos elevados de resíduos com culturas de cobertura durante > 6 meses nos últimos 3 anos, sistema legal dentro de três anos, etc., mas sem fertilizantes.
      
      Insumos elevados de carbono (com esterco): adição regular de esterco animal para aumentar o aporte de carbono pelo menos uma vez a cada três anos, além de culturas de cobertura por > 6 meses nos últimos 3 anos e resíduos deixados no campo.
      
      Nota: O chorume é "alto sem esterco", mas se o usuário aplicar chorume suficiente e houver uma entrada significativa de carbono, ele poderá alterá-lo para "alto com esterco".`,
    },
    TREE_FORM: {
      TITLE: "Dados de massa da árvore",
      TOOLTIP_MESSAGE:
        "Indique a densidade de árvores por hectare/acre na área de cultivo, com diâmetro medido na altura do peito.",
      PREDOMINANT_TREE_CLASSIFICATION: "Classificação Arbórea Predominante",
      DIAMETER_PREVIOUS_YEAR: "Diâmetro à altura do peito do ano anterior",
      DIAMETER_CURRENT_YEAR: "Diâmetro à altura do peito para o ano atual",
      UNIT_DENSITY: "Unidade de densidade",
      UNIT_DIAMETER: "Unidade de diâmetro",
      TREES_PLANTED: "Árvores plantadas",
      TREES_LOST: "Árvores perdidas",
      DENSITY: "Densidade",
      QTY_PLANTED_LOST_TREES:
        "Número de árvores plantadas (+) / árvores perdidas (-)",
      ERRORS: {
        TREE_TYPE: "Selecione o tipo de árvores na massa da árvore",
        DENSITY_PREVIOUS_YEAR:
          "Indique em número o diâmetro das árvores do ano anterior",
        DENSITY_CURRENT_YEAR:
          "Indique em número o diâmetro das árvores do ano corrente",
        TREES_PLANTED: "Indique o número de árvores plantadas",
        TREES_LOST: "Indique o número de árvores perdidas",
        DENSITY: "Seleccione a densidade em número",
        UNIT_DENSITY: "Indique a unidade de densidade",
        UNIT_DIAMETER: "Seleccione a unidade de diâmetro",
      },
    },
    TRANSPORT_FORM: {
      TRANSPORT: "Transporte",
      TRANSPORT_DATA: "Dados de transporte",
      TYPE_VEHICLE_USED: "Tipo de veículo utilizado",
      WEIGHT_TRANSPORT_LOAD: "Peso da carga transportada",
      DISTANCE_TRAVELED_IN_TRANSPORT: "Distância percorrida no transporte",
      UNIT: "Unidade",
      DISTANCE_UNIT: "Unidade de distância",
      WEIGHT_UNIT: "Unidade de peso",
      TOOLTIPS: {
        TRANSPORT_DATA: `
        Indique a quantidade de energia utilizada no transporte desde a exploração agrícola ou na transferência de produtos acabados para um ponto de venda. O transporte inclui a entrega ou descarte de materiais, mesmo que veículos próprios não sejam utilizados para transportar os produtos.
        `,
      },
      ALERTS: {
        SELECT_TYPE_VEHICLE_USED:
          "SELECIONE O TIPO DE VEÍCULO USADO NO TRANSPORTE",
        ENTER_WEIGHT_IN_TONS: "Indicar em número o peso transportado",
        ENTER_DISTANCE_IN_KILOMETERS:
          "Indique em número a distância percorrida",
        ENTER_WEIGHT_UNIT: "INDIQUE A UNIDADE DE PESO A SER TRANSPORTADA",
        ENTER_DISTANCE_UNIT: "INDICAR A UNIDADE DA DISTÂNCIA VIAJADA",
      },
    },
    RESIDUE_FORM: {
      ALERTS: {
        ONLY_TWO_DECIMALS: "Valor permite entrada com até 2 casas decimais",
        NUMBER_WASTE_GENERATED:
          "Digite em número a quantidade de resíduos gerados",
      },
    },
    SUMMARY: {
      GENERATE_REPORT: "Gerar relatório",
      GENERATING_REPORT: "Gerando relatório...",
      SUMMARY: "Resumo",
      SOIL: "Solo",
      ENERGY: "Energia",
      WASTE_MANAGEMENT: "Gerenciamento de resíduos",
      LAND_MANAGEMENT: "Gerenciamento de terras",
      TRANSPORTATION: "Transporte",
      TREE_STAN: "Árvores",
      MACHINERY: "Maquinaria",
      SUB_PROCESS: "Subprocessos",
      SUB_PRODUCTS: "Subprodutos",
      STORAGE: "Armazenar",
    },
    VALIDATIONS: {
      ARE_YOU_SURE: "Tem certeza?",
      A_RECORD_THAT_IS_DELETED_CANNOT_BE_RECOVERED:
        "Um registro excluído não pode ser recuperado",
      YES_DELETE: "Sim, excluir",
      CANCEL: "Cancelar",
    },
  },
};
