import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { hiddenAlert, sumStep, clearEntityAction } from "../../store/actions";

import List from "../list/list";
import {
  coProductEntity,
  coProductListHead,
  treeBiomasListHead,
  treeBiomassEntity,
  energyEntity,
  energyListHead,
  machinaryEntity,
  machinaryListHead,
  subProcessEntity,
  subProcessListHead,
  transportsEntity,
  transportsListHead,
  storageEntity,
  storageListHead,
  cropListHead,
  cropEntity,
  residueManagementListHead,
  residueManagementEntity,
  landEntity,
} from "../../commons/constants";
import { CTF_CARBON_BALANCE_URL } from "src/config/urls";
import { createEstablishment } from "src/services/createEstablishment";
import Swal from "sweetalert2";
import { useQuery } from "src/hooks/useQuery";
import { useTranslate } from "src/hooks/useTranslate/index";
import { landFormHead } from "src/modules/land/constants/landForm";

const FinishForm = () => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const alerta = useSelector((state) => state.alerta.alerta);
  const cargando = useSelector((state) => state.establishment.loading);
  const error = useSelector((state) => state.establishment.error);

  const cftUnits = useSelector((state) => {
    return state.selectSteps.cftUnits;
  });

  const step = useSelector((state) => {
    return state.step.position;
  });

  const table = useSelector((state) => {
    return state.table;
  });

  const history = useHistory();
  const query = useQuery();

  const fromWebView = query.get("fromWebView");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const titleComponent = t("SUMMARY.SUMMARY");

  const sendDataToApp = (data) => {
    const dataToSend = JSON.stringify({
      message: "SEND_CFT_PAYLOAD",
      data,
    });

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(dataToSend);

      return;
    }

    if (window.parent) {
      window.parent.postMessage(dataToSend, "*");

      return;
    }
  };

  const {
    energies,
    machinaries,
    processes,
    products,
    transports,
    treeBiomass,
    crops,
    name,
    residueManagements,
    land,
    storages,
    ucropitEstablishmentId,
    identifier,
  } = table;

  const dataToList = [
    {
      title: t("SUMMARY.SOIL"),
      items: crops,
      heads: cropListHead,
      entity: cropEntity,
      action: false,
    },
    {
      title: t("SUMMARY.ENERGY"),
      items: energies,
      heads: energyListHead,
      entity: energyEntity,
      action: false,
    },
    {
      title: t("SUMMARY.WASTE_MANAGEMENT"),
      items: residueManagements,
      heads: residueManagementListHead,
      entity: residueManagementEntity,
      action: false,
    },
    {
      title: t("SUMMARY.LAND_MANAGEMENT"),
      items: land,
      heads: landFormHead,
      entity: landEntity,
      action: false,
    },
    {
      title: t("SUMMARY.MACHINERY"),
      items: machinaries,
      heads: machinaryListHead,
      entity: machinaryEntity,
      action: false,
    },
    {
      title: t("SUMMARY.SUB_PROCESS"),
      items: processes,
      heads: subProcessListHead,
      entity: subProcessEntity,
      action: false,
    },
    {
      title: t("SUMMARY.SUB_PRODUCTS"),
      items: products,
      heads: coProductListHead,
      entity: coProductEntity,
      action: false,
    },
    {
      title: t("SUMMARY.TRANSPORTATION"),
      items: transports,
      heads: transportsListHead,
      entity: transportsEntity,
      action: false,
    },
    {
      title: t("SUMMARY.TREE_STAN"),
      items: treeBiomass,
      heads: treeBiomasListHead,
      entity: treeBiomassEntity,
      action: false,
    },
    {
      title: "Datos de almacenamiento",
      items: storages,
      heads: storageListHead,
      entity: storageEntity,
      action: false,
    },
  ];

  const parseLands = () => {
    return land.map((element) => {
      return {
        yearOfChange: element.yearOfChange,
        percentageAffectedArea: element.percentageAffectedArea,
        landUse: element.landUse._id,
        tillage: element.tillage._id,
        carbonInput: element.carbonInput._id,
        forestType: element.forestType,
        entity: element.entity,
        id: element.id,
      };
    });
  };
  const nextStep = async (e) => {
    e.preventDefault();

    try {
      setIsSubmitting(true);

      const data = {
        name,
        identifier,
        ucropitEstablishmentId,
        crops: getCropForSubmit(),
        energies: parseEnergiesForSubmit(),
        landManagements: parseLands(),
        products,
        machinaries,
        processes,
        storages,
        transports,
        treeBiomass: parseTreeBiomassForSubmit(),
      };

      if (fromWebView) {
        return sendDataToApp(data);
      }

      const responseData = await createEstablishment(data);

      window.open(`${CTF_CARBON_BALANCE_URL}/${responseData?.emission?._id}`);

      window.focus();

      Swal.fire({
        title: t("COMMON.CORRECT"),
        text: t("MESSAGES.STABLISHMENT_CREATED"),
        icon: "success",
      });
    } catch (err) {
      console.log(err);
      let messageError = t("COMMON.GENERAL_ERROR_TRY_AGAIN");
      let titileError = t("COMMON.GENERAL_ERROR");
      let icon = "error";

      if (err?.response?.status === 424) {
        const messages = err?.response?.data?.message;

        titileError = t("COMMON.ADVISE");
        icon = null;

        if (messages && messages?.length) {
          messageError = `${messages[0]}.`;
        }
      }

      Swal.fire({
        icon: icon,
        title: titileError,
        text: messageError,
      });
    } finally {
      dispatch(hiddenAlert());
      dispatch(clearEntityAction());
      dispatch(sumStep(0));
      history.push("/");
      setIsSubmitting(false);
    }
  };

  const prevStep = (e) => {
    e.preventDefault();
    let position = step - 1;
    dispatch(sumStep(position));
  };

  /**
   *
   * @returns {object}
   */
  const getCropForSubmit = () => {
    const [crop] = crops;
    const [residueManagement] = residueManagements;

    const residueUnit =
      cftUnits.find(
        ({ id }) => String(id) === String(residueManagement?.residueUnit)
      )?.id ?? null;

    return [
      {
        ...crop,
        residueManagementId: residueManagement?.residueManagementId,
        residueValue: residueManagement?.residueValue ?? null,
        residueUnit,
      },
    ];
  };

  /**
   * @returns {object}
   */
  const parseEnergiesForSubmit = () => {
    return energies.map((energy) => {
      const usageValueUnit = cftUnits.find(
        ({ id }) => String(id) === String(energy?.usageValueUnit)
      ).key;

      return {
        ...energy,
        usageValueUnit,
      };
    });
  };

  /**
   * @returns {object}
   */
  const parseTreeBiomassForSubmit = () => {
    return treeBiomass.map((element) => {
      const sizeUnit = cftUnits.find(
        ({ id }) => String(id) === String(element?.diameterUnit)
      ).key;

      const numTreesUnit = cftUnits.find(
        ({ id }) => String(id) === String(element?.densityUnit)
      ).key;

      return {
        ...element,
        sizeUnit,
        numTreesUnit,
      };
    });
  };

  return (
    <div className="row justify-content-center">
      <div className="col-md-12">
        <div className="stepwizard">
          <div className="stepwizard-row">
            <div className="stepwizard-step">
              <button
                type="button"
                className="btn btn-default btn-circle"
                disabled="disabled"
              >
                5
              </button>
              <p>{t("FORM_STEPS.LAND")}</p>
            </div>
            <div className="stepwizard-step">
              <button
                type="button"
                className="btn btn-default btn-circle"
                disabled="disabled"
              >
                6
              </button>
              <p>{t("FORM_STEPS.TRANSPORT")}</p>
            </div>
            <div className="stepwizard-step">
              <button
                type="button"
                className="btn btn-default btn-circle"
                disabled="disabled"
              >
                7
              </button>
              <p>{t("FORM_STEPS.TREES")}</p>
            </div>
            <div className="stepwizard-step">
              <button
                type="button"
                className="btn btn-primary1 btn-circle"
                disabled="disabled"
              >
                8
              </button>
              <p>{t("FORM_STEPS.SUMMARY")}</p>
            </div>
          </div>
        </div>

        <br></br>
        <h2 className="text-center mb-4 font-weight-bold">{titleComponent}</h2>
        <br></br>

        {alerta ? <p className={alerta.classes}> {alerta.msg} </p> : null}
        {cargando ? <p>{t("COMMON.LOADING")}</p> : null}

        {error ? (
          <p className="alert alert-danger p2 mt-4 text-center">
            {t("COMMON.GENERAL_ERRROR")}
          </p>
        ) : null}

        {dataToList?.map((data, index) => {
          return data?.items?.length ? (
            <List
              key={`DATA_TO_LIST_${index}`}
              title={data.title}
              items={data.items}
              heads={data.heads}
              entity={data.entity}
              action={data.action}
            ></List>
          ) : null;
        })}

        <br></br>
        <br></br>

        <div className="containter orientacion">
          <div className="row">
            <button
              onClick={prevStep}
              className="btn btn-primary font-weight-bold next"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-chevron-left"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                />
              </svg>{" "}
              {t("COMMON.BACK")}
            </button>

            <button
              onClick={nextStep}
              className="btn btn-primary font-weight-bold next"
              disabled={isSubmitting}
            >
              {isSubmitting
                ? t("SUMMARY.GENERATING_REPORT")
                : t("SUMMARY.GENERATE_REPORT")}{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-chevron-right"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinishForm;
