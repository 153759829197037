/* eslint-disable import/no-anonymous-default-export */
import {
  ADD_ESTABLISHMENT,
  ADD_ESTABLISHMENT_SUCESSFULL,
  ADD_ESTABLISHMENT_ERROR,
  START_DOWNLOAD_ESTABLISHMENT,
  DOWNLOAD_ESTABLISHMENT_SUCESSFULL,
  DOWNLOAD_ESTABLISHMENT_ERROR,
  GET_ESTABLISHMENT_DELETE,
  ESTABLISHMENT_DELETE_SUCESSFULL,
  ESTABLISHMENT_DELETE_ERROR,
  ADD_CROP,
  ADD_CROP_SUCESSFULL,
  ADD_CROP_ERROR,
  START_DOWNLOAD_CROP,
  DOWNLOAD_CROP_SUCESSFULL,
  DOWNLOAD_CROP_ERROR,
  START_DOWNLOAD_DETAIL_ESTABLISHMENT,
  DOWNLOAD_DETAIL_ESTABLISHMENT_SUCESSFULL,
  DOWNLOAD_DETAIL_ESTABLISHMENT_ERROR,
  START_DOWNLOAD_CROP_TYPES,
  DOWNLOAD_CROP_TYPES_SUCESSFULL,
  DOWNLOAD_CROP_TYPES_ERROR,
  ADD_UNIT_MEASURE,
} from "../types";

const initialState = {
  establishment: [],
  crops: [],
  error: null,
  loading: false,
  productoeliminar: null,
  establishmentDetail: null,
  cropTypes: [],
  unitMeasureSystem: null,
  areaUnit: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_ESTABLISHMENT:
    case START_DOWNLOAD_ESTABLISHMENT:
    case START_DOWNLOAD_DETAIL_ESTABLISHMENT:
      return {
        ...state,
        loading: action.payload,
      };
    case ADD_ESTABLISHMENT_SUCESSFULL:
      return {
        ...state,
        loading: false,
        establishment: [...state.establishment, action.payload],
      };

    case ADD_CROP:
    case START_DOWNLOAD_CROP:
      return {
        ...state,
        loading: action.payload,
      };
    case ADD_CROP_SUCESSFULL:
      return {
        ...state,
        loading: false,
        crops: [...state.crop, action.payload],
      };
    case ADD_ESTABLISHMENT_ERROR:
    case ADD_CROP_ERROR:
    case DOWNLOAD_ESTABLISHMENT_ERROR:
    case DOWNLOAD_CROP_ERROR:
    case DOWNLOAD_DETAIL_ESTABLISHMENT_ERROR:
    case DOWNLOAD_CROP_TYPES_ERROR:
    case ESTABLISHMENT_DELETE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DOWNLOAD_ESTABLISHMENT_SUCESSFULL:
      return {
        ...state,
        loading: false,
        error: null,
        establishment: action.payload,
      };
    case DOWNLOAD_DETAIL_ESTABLISHMENT_SUCESSFULL:
      return {
        ...state,
        loading: false,
        error: null,
        establishmentDetail: action.payload,
      };
    case DOWNLOAD_CROP_SUCESSFULL:
      return {
        ...state,
        loading: false,
        error: null,
        crops: action.payload,
      };
    case GET_ESTABLISHMENT_DELETE:
      return {
        ...state,
        productoeliminar: action.payload,
      };
    case ESTABLISHMENT_DELETE_SUCESSFULL:
      return {
        ...state,
        establishment: state.establishment.filter(
          (producto) => producto.id !== state.productoeliminar
        ),
        productoeliminar: null,
      };
    case START_DOWNLOAD_CROP_TYPES:
      return {
        ...state,
        loading: action.payload,
      };
    case DOWNLOAD_CROP_TYPES_SUCESSFULL:
      return {
        ...state,
        loading: false,
        cropTypes: action.payload,
      };
    case ADD_UNIT_MEASURE:
      return {
        ...state,
        unitMeasureSystem: action.payload.unitMeasureSystem,
        areaUnit: action.payload.areaUnit,
      };

    default:
      return state;
  }
}
