import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  donwloadPdfAction,
  getDetailEstablishmentAction,
} from "../store/actions";
import Swal from "sweetalert2";

import {
  coProductEntity,
  coProductListHead,
  treeBiomasListHead,
  treeBiomassEntity,
  energyEntity,
  energyListHead,
  machinaryEntity,
  machinarySummaryListHead,
  subProcessEntity,
  subProcessListHead,
  transportsEntity,
  transportsListHead,
  storageEntity,
  storageSummaryListHead,
  cropListHead,
  cropEntity,
  landManagementsEntity,
  managementListHead,
  residueManagementListHead,
  residueManagementEntity,
} from "../commons/constants";

import { useSelector, useDispatch } from "react-redux";
import ListSummary from "./list/listSummary";
import { useParams } from "react-router";
import { getSelects } from "src/services/getSelects";
import { mapSelectApiToRedux } from "src/utils/mapSelectApiToRedux";
import { setLoadValues } from "src/store/slices/selectStepsSlice";
import { useTranslate } from "src/hooks/useTranslate/index";

const Summary = () => {
  const { i18n } = useTranslate();

  const history = useHistory();
  const titleComponent = "Resumen";
  const params = useParams();

  const { id } = params;
  const dispatch = useDispatch();

  useEffect(() => {
    const getDetailEstablishment = (id) =>
      dispatch(getDetailEstablishmentAction(id));
    getDetailEstablishment(id);

    (async () => {
      const response = await getSelects({ language: i18n?.language });
      const selects = mapSelectApiToRedux(response);

      dispatch(setLoadValues(selects));
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch]);

  const establishmentDetail = useSelector((state) => {
    return state.establishment.establishmentDetail;
  });

  const alerta = useSelector((state) => state.alerta.alerta);
  const cargando = useSelector((state) => state.establishment.loading);
  const error = useSelector((state) => state.establishment.error);

  const backStep = (e) => {
    history.push("/");
  };

  const donwloadPdf = () => {
    Swal.fire({
      title: "Pdf",
      text: "Desea descargar evidencia en pdf",
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, descargar!!",
      cancelButtonText: "no",
    }).then((result) => {
      if (result.value) {
        dispatch(donwloadPdfAction(establishmentDetail));
      }
    });
  };

  let energies = [];
  let machinaries = [];
  let processes = [];
  let products = [];
  let transports = [];
  let treeBiomass = [];
  let crops = [];
  let landManagements = [];
  let storages = [];

  if (establishmentDetail) {
    energies = establishmentDetail.energies;
    machinaries = establishmentDetail.machinaries;
    processes = establishmentDetail.processes;
    products = establishmentDetail.products;
    transports = establishmentDetail.transports;
    treeBiomass = establishmentDetail.treeBiomass;
    crops = establishmentDetail.crops;
    landManagements = establishmentDetail.landManagements;
    storages = establishmentDetail.storages;
  }

  const dataToList = [
    {
      title: "Datos del Cultivo",
      items: crops,
      heads: cropListHead,
      entity: cropEntity,
      action: false,
    },
    {
      title: "Datos de energía",
      items: energies,
      heads: energyListHead,
      entity: energyEntity,
      action: false,
    },
    {
      title: "Datos de manejo de residuos",
      items: crops.map(({ residueManagementId, residueValue }) => ({
        residueManagementId,
        residueValue,
      })),
      heads: residueManagementListHead,
      entity: residueManagementEntity,
      action: false,
    },
    {
      title: "Datos de manejo de tierra",
      items: landManagements,
      heads: managementListHead,
      entity: landManagementsEntity,
      action: false,
    },
    {
      title: "Datos de maquinarias",
      items: machinaries,
      heads: machinarySummaryListHead,
      entity: machinaryEntity,
      action: false,
    },
    {
      title: "Datos de sub procesos",
      items: processes,
      heads: subProcessListHead,
      entity: subProcessEntity,
      action: false,
    },
    {
      title: "Datos de sub productos",
      items: products,
      heads: coProductListHead,
      entity: coProductEntity,
      action: false,
    },
    {
      title: "Datos de transportes",
      items: transports,
      heads: transportsListHead,
      entity: transportsEntity,
      action: false,
    },
    {
      title: "Datos de masa arbórea",
      items: treeBiomass,
      heads: treeBiomasListHead,
      entity: treeBiomassEntity,
      action: false,
    },
    {
      title: "Datos de almacenamiento",
      items: storages,
      heads: storageSummaryListHead,
      entity: storageEntity,
      action: false,
    },
  ];

  return (
    <div className="row justify-content-center">
      <div className="col-md-12">
        <div className="">
          <div className="">
            <br></br>
            <h2 className="text-center mb-4 font-weight-bold">
              {titleComponent}
            </h2>
            <br></br>

            {alerta ? <p className={alerta.classes}> {alerta.msg} </p> : null}
            {cargando ? <p>Cargando...</p> : null}

            {error ? (
              <p className="alert alert-danger p2 mt-4 text-center">
                Hubo un error
              </p>
            ) : null}

            {dataToList.map((data, index) => {
              return data.items.length ? (
                <ListSummary
                  key={`DATA_TO_LIST_SUMARY_${index}`}
                  title={data.title}
                  items={data.items}
                  heads={data.heads}
                  entity={data.entity}
                  action={data.action}
                ></ListSummary>
              ) : null;
            })}
          </div>
          <div className="container">
            <br></br>
            <div className="containter orientacion">
              <div className="row">
                {establishmentDetail ? (
                  <div>
                    {" "}
                    <button
                      onClick={backStep}
                      className="btn btn-primary font-weight-bold next"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-chevron-left"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                        />
                      </svg>{" "}
                      Atras
                    </button>
                    <button
                      className="btn btn-primary font-weight-bold next"
                      onClick={donwloadPdf}
                    >
                      Pdf{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-chevron-right"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                        />
                      </svg>
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
            <br></br>
            <br></br>
            <br></br>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
